import type { PortfolioPageData } from '@/types'
import { syncDbFileToRemote } from '../syncDbFileToRemote'
import { updateLastUsedAt } from '../updateLastUsedAt'
import { syncDatabase } from './syncDatabase'

export const syncAfterSetup = async (
  params: PortfolioPageData & {
    token: string
  },
) => {
  const { token, readonly, readFromDatabase, writeToDatabase } = params

  // writable の場合の処理
  if (writeToDatabase !== undefined && !readonly) {
    await Promise.all([
      // lastUsed 更新
      updateLastUsedAt({ portfolio: params, token }),
      // DB 同期
      (async () => {
        // DB に最新情報を同期
        await syncDatabase({
          readFromDatabase,
          writeToDatabase,
        })
        // local > remote の場合に DB File を remote にアップロード
        await syncDbFileToRemote({ portfolio: params, token, writeToDatabase })
      })(),
    ])
  }
}
