import { fiatCurrencies } from '@/constants/fiatCurrency'
import { solanaRpcEndpoints } from '@/constants/solana'
import {
  assetApiEndpoint,
  datasourceApiEndpoint,
  proxyApiEndpoint,
  splTokenApiEndpoint,
} from '@/environment'
import type { RunToDatabase } from '@/types'
import { atom } from 'jotai'
import { analyzeAccountList } from '../usecases/analyzeAccountList'

export const analyzeAccountListAtom = atom(
  undefined,
  async (
    _,
    set,
    parameters: {
      readFromDatabase: RunToDatabase
      writeToDatabase: RunToDatabase
      getToken: () => Promise<string | null>
      readonly targetAccountIdList: readonly number[] | undefined
    },
  ) => {
    await analyzeAccountList({
      ...parameters,
      fiatCurrencyList: fiatCurrencies,
      assetApiEndpoint,
      datasourceApiEndpoint,
      proxyApiEndpoint,
      splTokenApiEndpoint,
      solanaRpcEndpoints,
      set,
    })
  },
)
