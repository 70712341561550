import { and, between, eq, exists, gte, lte } from 'drizzle-orm'
import type { SQL } from 'drizzle-orm'
import { actionTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type ExistActionParameters = {
  database: DatabaseWithTransaction
  from?: number
  to?: number
  generatedTx?: boolean
  locked?: boolean
}

export const existAction = async ({
  database: { database },
  from,
  to,
  generatedTx,
  locked,
}: ExistActionParameters): Promise<boolean> => {
  const whereConditions: SQL<unknown>[] = []
  if (from !== undefined && to !== undefined) {
    whereConditions.push(
      between(actionTable.timestamp, new Date(from), new Date(to)),
    )
  } else if (from !== undefined) {
    whereConditions.push(gte(actionTable.timestamp, new Date(from)))
  } else if (to !== undefined && to > 0) {
    whereConditions.push(lte(actionTable.timestamp, new Date(to)))
  }
  if (generatedTx !== undefined) {
    whereConditions.push(eq(actionTable.generatedTx, generatedTx))
  }
  if (locked !== undefined) {
    whereConditions.push(eq(actionTable.locked, locked))
  }
  let where: SQL<unknown> | undefined
  if (whereConditions.length === 0) {
    where = undefined
  } else if (whereConditions.length === 1) {
    where = whereConditions[0]
  } else {
    where = and(...whereConditions)
  }

  const result = await database
    .select({
      exist: exists(database.select().from(actionTable).where(where)),
    })
    .from(actionTable)
    .limit(1)
  if (result.length === 0) {
    return false
  }
  return result[0].exist === 1
}
