import { cn } from '@/lib/utils'
import type { Portfolio } from '@/types'
import {
  Link,
  type RegisteredRouter,
  type RoutePaths,
  useRouterState,
} from '@tanstack/react-router'
import type { TFunction } from 'i18next'
import {
  ChartSpline,
  ChevronRight,
  CircleEllipsis,
  History,
  NotebookPen,
  PencilOff,
  Settings,
  Wallet,
} from 'lucide-react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import PortfolioSwitcher from './portfolioSwitcher'
import { Button } from './ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from './ui/collapsible'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarSeparator,
} from './ui/sidebar'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip'

type MenuData = {
  title: string
  icon: ReactNode
  path: RoutePaths<RegisteredRouter['routeTree']>
  sub?: readonly Omit<MenuData, 'icon'>[]
}

const createMenuData = (t: TFunction): readonly MenuData[] => [
  {
    title: t('Portfolio'),
    icon: <ChartSpline />,
    path: '/dashboard',
    sub: [
      {
        title: t('Dashboard'),
        path: '/dashboard',
      },
      {
        title: t('Data Export'),
        path: '/dashboard/export',
      },
    ],
  },
  {
    title: t('Account'),
    icon: <Wallet />,
    path: '/account',
  },
  {
    title: t('History'),
    icon: <History />,
    path: '/history',
  },
  {
    title: t('Setting'),
    icon: <Settings />,
    path: '/setting',
    sub: [
      {
        title: t('Basic Setting'),
        path: '/setting',
      },
      {
        title: t('Manage Member'),
        path: '/setting/share',
      },
      {
        title: t('Plan & Payment'),
        path: '/setting/plan',
      },
      {
        title: t('sidebar/menu/setting-journal'),
        path: '/setting/journal',
      },
    ],
  },
]

type Props = {
  portfolios: readonly Portfolio[]
  portfolio: Portfolio
  readonly: boolean
}

const PortfolioSiderbar = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Sidebar collapsible="icon">
      <Header {...props} />
      <Content {...props} t={t} />
      <SidebarSeparator />
      <Footer {...props} t={t} />
    </Sidebar>
  )
}

const Header = (props: Props) => {
  return (
    <SidebarHeader>
      <SidebarMenu>
        <SidebarMenuItem>
          <SidebarMenuButton size="lg" asChild>
            <PortfolioSwitcher {...props} />
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>
  )
}

const Content = (props: Props & { t: TFunction }) => {
  const { t } = props
  const { location } = useRouterState()
  const data = createMenuData(t)

  return (
    <SidebarContent>
      <SidebarGroup>
        <SidebarMenu>
          {data.map((menu) =>
            menu.sub === undefined ? (
              <SidebarMenuItem key={menu.title}>
                <SidebarMenuButton
                  asChild
                  className="data-[active=false]:text-muted-foreground data-[active=false]:hover:text-sidebar-foreground"
                  isActive={location.pathname === menu.path}
                >
                  <Link to={menu.path} search={(prev) => ({ ...prev })}>
                    {menu.icon}
                    <span>{menu.title}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ) : (
              <Collapsible
                key={menu.title}
                asChild
                defaultOpen={location.pathname.startsWith(menu.path)}
              >
                <SidebarMenuItem>
                  <SidebarMenuButton
                    asChild
                    className="data-[active=false]:text-muted-foreground data-[active=false]:hover:text-sidebar-foreground"
                    isActive={location.pathname.startsWith(menu.path)}
                  >
                    <Link to={menu.path} search={(prev) => ({ ...prev })}>
                      {menu.icon}
                      <span>{menu.title}</span>
                    </Link>
                  </SidebarMenuButton>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuAction className="data-[state=open]:rotate-90">
                      <ChevronRight />
                      <span className="sr-only">Toggle</span>
                    </SidebarMenuAction>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {menu.sub.map((sub) => (
                        <SidebarMenuSubItem key={sub.title}>
                          <SidebarMenuSubButton
                            asChild
                            className="data-[active=false]:text-muted-foreground data-[active=true]:text-primary data-[active=false]:hover:text-sidebar-foreground"
                            isActive={location.pathname === sub.path}
                          >
                            <Link
                              to={sub.path}
                              search={(prev) => ({ ...prev })}
                            >
                              <span>{sub.title}</span>
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </SidebarMenuItem>
              </Collapsible>
            ),
          )}
          <Collapsible asChild>
            <SidebarMenuItem>
              <SidebarMenuButton className="text-muted-foreground hover:text-sidebar-foreground">
                <CircleEllipsis />
                <span>{t('sidebar/menu/other')}</span>
              </SidebarMenuButton>
              <CollapsibleTrigger asChild>
                <SidebarMenuAction className="data-[state=open]:rotate-90">
                  <ChevronRight />
                  <span className="sr-only">Toggle</span>
                </SidebarMenuAction>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <SidebarMenuSub>
                  <SidebarMenuSubItem>
                    <SidebarMenuSubButton
                      asChild
                      className="text-muted-foreground hover:text-sidebar-foreground"
                    >
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfCTj2Biy4m5sJqxVxwpBt7UiHcLECommLPBpCaseHYQLyygQ/viewform?usp=dialog"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('sidebar/menu/contact')}</span>
                      </a>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                  <SidebarMenuSubItem>
                    <SidebarMenuSubButton
                      asChild
                      className="text-muted-foreground hover:text-sidebar-foreground"
                    >
                      <a
                        href="https://docs.google.com/document/d/1j3LfZxDd17U6CmLI34Avvt5qpi-M03NU10EXJANmMCs/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('sidebar/menu/terms-of-service')}</span>
                      </a>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                  <SidebarMenuSubItem>
                    <SidebarMenuSubButton
                      asChild
                      className="text-muted-foreground hover:text-sidebar-foreground"
                    >
                      <a
                        href="https://docs.google.com/document/d/1KspnFP1v9tbOyyYQK-VF4nDnxqQt9MhLwJqmdQO1uDk/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('sidebar/menu/privacy-policy')}</span>
                      </a>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                  <SidebarMenuSubItem>
                    <SidebarMenuSubButton
                      asChild
                      className="text-muted-foreground hover:text-sidebar-foreground"
                    >
                      <a
                        href="https://x.com/cryptorch_jp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('sidebar/menu/x-official-account')}</span>
                      </a>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                  <SidebarMenuSubItem>
                    <SidebarMenuSubButton
                      asChild
                      className="text-muted-foreground hover:text-sidebar-foreground"
                    >
                      <a
                        href="https://docs.google.com/presentation/d/1K7RDM9xn_1PCXuOgllwgooldZhWPAba_FkN-uDBoYmw/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('sidebar/menu/manual-function')}</span>
                      </a>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                  <SidebarMenuSubItem>
                    <SidebarMenuSubButton
                      asChild
                      className="text-muted-foreground hover:text-sidebar-foreground"
                    >
                      <a
                        href="https://docs.google.com/presentation/d/1ZiJtxXFMtKQ1dTwxNVRlJi_DY4U6TI0TRYNoZAYYNJE/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('sidebar/menu/manual-calculate-pl')}</span>
                      </a>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                </SidebarMenuSub>
              </CollapsibleContent>
            </SidebarMenuItem>
          </Collapsible>
        </SidebarMenu>
      </SidebarGroup>
    </SidebarContent>
  )
}

const Footer = (
  props: Props & {
    t: TFunction
  },
) => {
  const { portfolio, t } = props
  return (
    <SidebarFooter>
      <SidebarMenu>
        <SidebarMenuItem>
          <SidebarMenuButton className="h-auto cursor-auto divide-x hover:bg-sidebar active:bg-sidebar data-[active=true]:bg-sidebar data-[state=open]:hover:bg-sidebar">
            <PortfolioModeTooltip {...props} />
            <div className="ml-1 flex flex-1 flex-col pl-3">
              <div className="flex items-center justify-between px-2">
                <span className="text-muted-foreground text-sm">
                  {t('Current Plan')}
                </span>
                <span className="font-bold text-sm">{portfolio.plan}</span>
              </div>
              {portfolio.isOwner && (
                <Button variant="outline" size="sm" className="mt-3" asChild>
                  <Link to="/setting/plan" search={(prev) => ({ ...prev })}>
                    {t('Upgrade Plan')}
                  </Link>
                </Button>
              )}
            </div>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>
  )
}

const PortfolioModeTooltip = (props: Props & { t: TFunction }) => {
  const { readonly, t } = props
  const commonClassName =
    'p-1 w-6 h-6 border rounded-full flex items-center justify-center'
  const variantClassName = readonly
    ? 'text-yellow-500 border-yellow-500'
    : 'text-green-500 border-green-500'
  const icon = readonly ? <PencilOff /> : <NotebookPen />
  const tooltipText = readonly ? t('Readonly Mode') : t('Writable Mode')

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={cn(commonClassName, variantClassName)}>{icon}</div>
        </TooltipTrigger>
        <TooltipContent className="bg-secondary text-secondary-foreground">
          <p>{tooltipText}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default PortfolioSiderbar
