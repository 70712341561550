import { accountTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'
import { conflictUpdateAllExcept } from '../utils'

type InsertAccountEvmData = Omit<
  typeof accountTable.$inferInsert,
  | 'solanaAddress'
  | 'exchangeApiKey'
  | 'exchangeApiSecret'
  | 'exchangeApiPassword'
> & { type: 'evm'; evmChainId: number }

type UpsertAccountsEvmParameters = {
  readonly database: DatabaseWithTransaction
  readonly data: readonly InsertAccountEvmData[]
}

export const upsertAccountsEvm = async ({
  database: { database },
  data,
}: UpsertAccountsEvmParameters) => {
  if (data.length === 0) {
    return
  }

  await database
    .insert(accountTable)
    .values([...data])
    .onConflictDoUpdate({
      target: accountTable.id,
      set: conflictUpdateAllExcept(accountTable, ['id']),
    })
    .execute()
}
