import { divideArrayIntoChunks } from '@pkg/basic'
import { cryptoCurrencyColumnCount } from '../constants'
import { cryptoCurrencyTable } from '../schema'
import type {
  DatabaseWithTransaction,
  InsertCryptoCurrencyData,
} from '../types'
import { conflictUpdateAllExcept, getMaxInsertRowCount } from '../utils'

type UpsertCryptoCurrenciesParameters = {
  readonly database: DatabaseWithTransaction
  readonly data: readonly InsertCryptoCurrencyData[]
}

export const upsertCryptoCurrencies = async ({
  database: { transaction },
  data,
}: UpsertCryptoCurrenciesParameters) => {
  if (data.length === 0) {
    return
  }

  await transaction([
    (tx) => [
      ...divideArrayIntoChunks(
        data,
        getMaxInsertRowCount(cryptoCurrencyColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(cryptoCurrencyTable)
          .values([...chunk])
          .onConflictDoUpdate({
            target: cryptoCurrencyTable.id,
            set: conflictUpdateAllExcept(cryptoCurrencyTable, ['id']),
          }),
      ),
    ],
  ])
}
