import type { PortfolioPageData } from '@/types'
import { useAtom } from 'jotai'
import { progressListAtom } from '../states/progress'
import AnalyzeProgressToast from './analyzeProgressToast/analyzeProgressToast'
import UpdatingTransactionToast from './updatingTransactionToast'

const AnalyzeProgressToastWrapper = (props: PortfolioPageData) => {
  const { readonly } = props

  if (readonly) {
    return <></>
  }

  const [progressList] = useAtom(progressListAtom)

  if (progressList.length > 0) {
    return (
      <div className="fixed bottom-0 left-0 z-50 p-4">
        <AnalyzeProgressToast />
      </div>
    )
  }

  return <UpdatingTransactionToast {...props} />
}

export default AnalyzeProgressToastWrapper
