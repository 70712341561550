import type { InternalTransaction, TransactionIndex } from '@0xtorch/evm'
import { blobToHex } from '@pkg/basic'
import { and, desc, eq, inArray } from 'drizzle-orm'
import {
  accountEvmTxIndexRelationTable,
  accountTable,
  evmInternalTransactionTable,
  evmTxIndexTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'

type GetEvmTxIndexesParameters = {
  readonly database: DatabaseWithTransaction
  readonly chainId: number
  readonly analyzed: boolean
}

type GetEvmTxIndexesReturnTypes = {
  readonly indexes: readonly TransactionIndex[]
  readonly internalTransactions: readonly InternalTransaction[]
}

export const getEvmTxIndexes = async ({
  database: { database },
  chainId,
  analyzed,
}: GetEvmTxIndexesParameters): Promise<GetEvmTxIndexesReturnTypes> => {
  const hashRows = await database
    .selectDistinct({ hash: accountEvmTxIndexRelationTable.hash })
    .from(accountEvmTxIndexRelationTable)
    .leftJoin(
      accountTable,
      eq(accountEvmTxIndexRelationTable.accountId, accountTable.id),
    )
    .where(
      and(
        eq(accountEvmTxIndexRelationTable.analyzed, analyzed),
        eq(accountTable.evmChainId, chainId),
      ),
    )
  if (hashRows.length === 0) {
    return {
      indexes: [],
      internalTransactions: [],
    }
  }

  const hashes = hashRows.map(({ hash }) => hash)

  const [indexRows, internalTxRows] = await Promise.all([
    database
      .select()
      .from(evmTxIndexTable)
      .where(
        and(
          eq(evmTxIndexTable.chainId, chainId),
          inArray(evmTxIndexTable.hash, hashes),
        ),
      )
      .orderBy(desc(evmTxIndexTable.timestamp)),
    database
      .select()
      .from(evmInternalTransactionTable)
      .where(
        and(
          eq(evmInternalTransactionTable.chainId, chainId),
          inArray(evmInternalTransactionTable.hash, hashes),
        ),
      ),
  ])

  return {
    indexes: indexRows.map(
      (row): TransactionIndex => ({
        hash: blobToHex(row.hash),
        blockNumber: row.blockNumber,
        timestamp: row.timestamp.getTime(),
        input: row.input === null ? undefined : blobToHex(row.input),
        value: row.value === null ? undefined : BigInt(blobToHex(row.value)),
      }),
    ),
    internalTransactions: internalTxRows.map(
      (row): InternalTransaction => ({
        contractAddress:
          row.contractAddress.length === 0
            ? undefined
            : blobToHex(row.contractAddress),
        from: blobToHex(row.from),
        value: BigInt(blobToHex(row.value)),
        gas: BigInt(blobToHex(row.gas)),
        isError: row.isError,
        to: row.to.length === 0 ? undefined : blobToHex(row.to),
        txHash: blobToHex(row.hash),
      }),
    ),
  }
}
