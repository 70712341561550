import { toStringBigDecimal } from '@0xtorch/big-decimal'
import type { FiatCurrency } from '@0xtorch/core'
import { cryptoCurrencyConverter } from '@pkg/basic'
import type { actionTable, nftTable } from '../schema'
import type {
  Action,
  ActionWithTransactionList,
  InsertActionData,
  InsertActionTransferData,
  InsertCryptoCurrencyData,
  InsertNftData,
} from '../types'
import type { ActionTransferData } from './actionTransfer'
import { parseToActionTransfer } from './actionTransfer'
import { parseToNft } from './nft'
import type { TransactionData } from './transaction'
import { parseToTransaction } from './transaction'

type ActionData = typeof actionTable.$inferSelect & {
  actionTransferTable: ActionTransferData[]
  nftTable: typeof nftTable.$inferSelect | null
}

type ActionWithTransactionListData = ActionData & {
  transactionList: TransactionData[]
}

export const parseToActionWithTransactionList = (
  data: ActionWithTransactionListData,
  fiatCurrencyList: readonly FiatCurrency[],
  fiatCurrency: FiatCurrency,
): ActionWithTransactionList => ({
  ...parseToAction(data, fiatCurrencyList, fiatCurrency),
  transactionList: data.transactionList.map((item) =>
    parseToTransaction(item, fiatCurrencyList),
  ),
})

export const parseToAction = (
  data: ActionData,
  fiatCurrencyList: readonly FiatCurrency[],
  fiatCurrency: FiatCurrency,
): Action => ({
  comment: data.comment ?? undefined,
  app: data.app ?? undefined,
  timestamp: data.timestamp.getTime(),
  order: data.order,
  type: data.type,
  source: data.sourceId,
  evidence: data.evidence,
  loanId: data.loanId ?? undefined,
  crossId: data.crossId ?? undefined,
  crossType: data.crossType ?? undefined,
  target: data.nftTable === null ? undefined : parseToNft(data.nftTable),
  transfers: data.actionTransferTable.map((item) =>
    parseToActionTransfer(item, fiatCurrencyList, fiatCurrency),
  ),
  locked: data.locked ?? false,
  generatedTx: data.generatedTx ?? false,
})

export const parseActionsToInsertData = (
  actions: readonly Action[],
): {
  readonly action: readonly InsertActionData[]
  readonly actionTransfer: readonly InsertActionTransferData[]
  readonly cryptoCurrency: readonly InsertCryptoCurrencyData[]
  readonly nft: readonly InsertNftData[]
} => {
  const mut_actions: InsertActionData[] = []
  const mut_actionTransfer: InsertActionTransferData[] = []
  const mut_cryptoCurrency: InsertCryptoCurrencyData[] = []
  const mut_nft: InsertNftData[] = []

  for (const action of actions) {
    const relatedAssetIdSet = new Set<string>()
    const fromAddressSet = new Set<string>()
    const toAddressSet = new Set<string>()
    for (const transfer of action.transfers) {
      relatedAssetIdSet.add(transfer.asset.id)
      if (transfer.from !== undefined) {
        fromAddressSet.add(transfer.from)
      }
      if (transfer.to !== undefined) {
        toAddressSet.add(transfer.to)
      }
    }
    const actionId = `${action.source}_${action.order}`
    // action 追加
    mut_actions.push({
      id: actionId,
      type: action.type,
      sourceId: action.source,
      timestamp: new Date(action.timestamp),
      order: action.order,
      evidence: action.evidence,
      comment: action.comment,
      app: action.app,
      loanId: action.loanId,
      crossId: action.crossId,
      crossType: action.crossType,
      targetId: action.target?.id,
      locked: action.locked,
      generatedTx: false,
      relatedAssetIds: [...relatedAssetIdSet].join(','),
      fromAddresses: [...fromAddressSet].join(','),
      toAddresses: [...toAddressSet].join(','),
    })

    // 重複がない場合 target nft 追加
    if (
      'target' in action &&
      action.target !== undefined &&
      mut_nft.every((item) => item.id !== action.target?.id)
    ) {
      mut_nft.push({
        id: action.target.id,
        name: action.target.name,
        image: action.target.image,
        metadata: action.target.metadata,
        updatedAt: action.target.updatedAt,
      })
    }

    for (const [order, transfer] of action.transfers.entries()) {
      if (
        transfer.asset.type === 'CryptoCurrency' &&
        cryptoCurrencyConverter.has(transfer.asset.id)
      ) {
        transfer.asset =
          cryptoCurrencyConverter.get(transfer.asset.id) ?? transfer.asset
      }
      // actionTransfer 追加
      mut_actionTransfer.push({
        order,
        actionId,
        direction: transfer.direction,
        from: transfer.from,
        to: transfer.to,
        amount: toStringBigDecimal(transfer.amount),
        cryptoId:
          transfer.asset.type === 'CryptoCurrency'
            ? transfer.asset.id
            : undefined,
        fiatId:
          transfer.asset.type === 'FiatCurrency'
            ? transfer.asset.id
            : undefined,
        nftId: transfer.asset.type === 'Nft' ? transfer.asset.id : undefined,
        price:
          transfer.price === undefined
            ? undefined
            : toStringBigDecimal(transfer.price),
      })

      // 重複がない場合 nft 追加
      if (
        transfer.asset.type === 'Nft' &&
        mut_nft.every((item) => item.id !== transfer.asset.id)
      ) {
        mut_nft.push({
          id: transfer.asset.id,
          name: transfer.asset.name,
          image: transfer.asset.image,
          metadata: transfer.asset.metadata,
          updatedAt: transfer.asset.updatedAt,
        })
      }

      // 重複がない場合 cryptoCurrency 追加
      if (
        transfer.asset.type === 'CryptoCurrency' &&
        mut_cryptoCurrency.every((item) => item.id !== transfer.asset.id)
      ) {
        mut_cryptoCurrency.push({
          id: transfer.asset.id,
          name: transfer.asset.name,
          symbol: transfer.asset.symbol,
          icon: transfer.asset.icon,
          coingeckoId: transfer.asset.market?.coingeckoId,
          marketCapUsd:
            transfer.asset.market?.marketCapUsd === undefined
              ? undefined
              : Math.floor(transfer.asset.market.marketCapUsd),
          priceDatasourceId: transfer.asset.priceDatasourceId,
          updatedAt: transfer.asset.updatedAt,
        })
      }
    }
  }

  return {
    action: mut_actions,
    actionTransfer: mut_actionTransfer,
    cryptoCurrency: mut_cryptoCurrency,
    nft: mut_nft,
  }
}
