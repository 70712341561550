import type { BigDecimal } from '@0xtorch/big-decimal'
import {
  absoluteValue,
  createBigDecimal,
  times,
  toStringBigDecimal,
} from '@0xtorch/big-decimal'
import type { FiatCurrency } from '@0xtorch/core'
import { type DatabaseWithTransaction, schema } from '@pkg/database-portfolio'
import { drizzle } from 'drizzle-orm/sqlite-proxy'
import { SQLocalDrizzle } from 'sqlocal/drizzle'
import { setPortfolioUpdatedAtToLocalStorage } from './localStorage'
import type { RunToDatabase } from './types'

export const getFiatMark = (fiatCurrency: FiatCurrency): string => {
  switch (fiatCurrency.id) {
    case 'eur': {
      return '€'
    }
    case 'jpy': {
      return '¥'
    }
    case 'usd': {
      return '$'
    }
    case 'cny': {
      // TODO: implement
      return ''
    }
    case 'idr': {
      // TODO: implement
      return ''
    }
    case 'krw': {
      // TODO: implement
      return ''
    }
    case 'rub': {
      // TODO: implement
      return ''
    }
    case 'twd': {
      // TODO: implement
      return ''
    }
  }
}

const getFiatDecimals = (fiatCurrency: FiatCurrency): number => {
  switch (fiatCurrency.id) {
    case 'jpy': {
      return 0
    }
    case 'cny': {
      // TODO: implement
      return 2
    }
    case 'eur': {
      // TODO: implement
      return 2
    }
    case 'idr': {
      // TODO: implement
      return 2
    }
    case 'krw': {
      // TODO: implement
      return 2
    }
    case 'rub': {
      // TODO: implement
      return 2
    }
    case 'twd': {
      // TODO: implement
      return 2
    }
    case 'usd': {
      return 2
    }
  }
}

export const createPriceText = (
  value: BigDecimal,
  fiatCurrency: FiatCurrency,
): string => {
  const fiatMark = getFiatMark(fiatCurrency)
  const decimals = getFiatDecimals(fiatCurrency)
  const valueText = Number(
    toStringBigDecimal(
      times(absoluteValue(value), createBigDecimal(1n), decimals, 'ceil'),
    ),
  ).toLocaleString()
  return value.value >= 0n
    ? `+${fiatMark}${valueText}`
    : `-${fiatMark}${valueText}`
}

export const formatBigDecimal = (
  value: BigDecimal,
): {
  integer: string
  zeroCount?: number
  decimal?: string
} => {
  // 文字列化して小数点で分割
  const [integer, decimal] = toStringBigDecimal(value).split('.')
  if (decimal === undefined || decimal.length === 0) {
    // 整数は３桁ごとにカンマ区切りを入れる
    return { integer: integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
  }

  // 1以上の場合、小数点桁数最大 4 桁にする
  if (integer !== '0') {
    return {
      integer: integer.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      decimal: decimal.slice(0, 4).replace(/0+$/, ''),
    }
  }

  // １未満の場合、先頭の 0 を除いて桁数最大 4 桁にする
  // 先頭の 0 が 5 個以上の場合は zeroCount を記録して、先頭の 0 を除いた値を decimal として返す
  // それ以外の場合は有効桁数までに調整した値を返す
  const zeroCount = decimal.search(/[^0]/)
  const removedZero = decimal.replace(/^0+/, '')

  if (zeroCount >= 5) {
    return {
      integer: '0',
      zeroCount,
      decimal: removedZero.slice(0, 4),
    }
  }

  return {
    integer: '0',
    zeroCount: undefined,
    decimal: `${''.padStart(zeroCount, '0')}${removedZero.slice(0, 4)}`,
  }
}

export const createPortfolioDbFileName = (id: string): string => `${id}.sqlite`

export const getDatabase = (
  id: string,
): { database: DatabaseWithTransaction; destroy: () => Promise<void> } => {
  const { driver, batchDriver, transaction, destroy } = new SQLocalDrizzle(
    createPortfolioDbFileName(id),
  )
  const database = drizzle(driver, batchDriver, { schema })
  return {
    database: {
      database,
      transaction: async (querySteps) =>
        await transaction(async (tx) => {
          for (const queryStep of querySteps) {
            await Promise.all(
              queryStep(database).map((query) => tx.query(query)),
            )
          }
        }),
    },
    destroy,
  }
}

export const createReadFromDatabase =
  (database: DatabaseWithTransaction): RunToDatabase =>
  (run) =>
    run(database)

export const createWriteToDatabase =
  (database: DatabaseWithTransaction): RunToDatabase =>
  async (run) => {
    const result = await run(database)
    setPortfolioUpdatedAtToLocalStorage(Date.now())
    return result
  }

type ObjectKeys<T> = (keyof T extends infer U
  ? U extends keyof T
    ? U
    : never
  : never)[]

export const objectKeys = <T extends { [key: string]: unknown }>(
  obj: T,
): ObjectKeys<T> => {
  const res = Object.keys(obj)
  return res as ObjectKeys<T>
}

export const compress = async (file: File): Promise<File> => {
  const cs = new CompressionStream('gzip')
  const stream = file.stream().pipeThrough(cs)
  const buf = await new Response(stream).arrayBuffer()
  return new File([buf], file.name, { type: 'application/gzip' })
}
