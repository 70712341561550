import type { Setter } from 'jotai'
import { progressListAtom } from './progress'

type ExchangeAccountProgressData = {
  readonly accountId: number
  readonly checkingApiIdSet: Set<string>
  readonly checkedApiIdSet: Set<string>
  progressApiList: {
    readonly apiId: string
    readonly checkingSize: number
    checkedSize: number
  }[]
}

const mut_exchangeAccountProgressDataListMap: {
  [category: string]: ExchangeAccountProgressData[] | undefined
} = {}

type UpdateProgressListParameters = {
  readonly category: string
  readonly set: Setter
}

const updateProgressList = ({
  category,
  set,
}: UpdateProgressListParameters) => {
  let mut_checkingSize = 0
  let mut_checkedSize = 0
  for (const data of mut_exchangeAccountProgressDataListMap[category] ?? []) {
    mut_checkingSize += data.checkingApiIdSet.size
    mut_checkedSize += data.checkedApiIdSet.size
    for (const progressApi of data.progressApiList) {
      if (data.checkedApiIdSet.has(progressApi.apiId)) {
        continue
      }
      mut_checkedSize +=
        progressApi.checkedSize /
        progressApi.checkingSize /
        data.checkingApiIdSet.size
    }
  }
  const checkingSize = mut_checkingSize
  const checkedSize = mut_checkedSize

  if (checkingSize > 0) {
    const progress = (checkedSize / checkingSize) * 100
    set(progressListAtom, (current) =>
      current.some((item) => item.category === category)
        ? current.map((item) =>
            item.category === category
              ? {
                  ...item,
                  status: 'sync',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category,
              status: 'sync',
              progress,
            },
          ],
    )
  } else {
    set(progressListAtom, (current) =>
      current.filter((item) => item.category !== category),
    )
  }
}

type SaveStartExchangeAnalyzeProgressStatusParameters = {
  readonly category: string
  readonly accountApiIdSetList: {
    readonly accountId: number
    readonly apiIdSet: Set<string>
  }[]
  readonly set: Setter
}

export const saveStartExchangeAnalyzeProgressStatus = ({
  category,
  accountApiIdSetList,
  set,
}: SaveStartExchangeAnalyzeProgressStatusParameters) => {
  // set checking account data
  mut_exchangeAccountProgressDataListMap[category] = accountApiIdSetList.map(
    ({ accountId, apiIdSet }) => ({
      accountId,
      checkingApiIdSet: new Set(apiIdSet),
      checkedApiIdSet: new Set(),
      progressApiList: [],
    }),
  )

  // set progress
  updateProgressList({ category, set })
}

type SaveFetchedExchangeApiCallCountProgressStatusParameters = {
  readonly category: string
  readonly accountId: number
  readonly apiId: string
  readonly size: number
  readonly set: Setter
}

export const saveFetchedExchangeApiCallSizeProgressStatus = ({
  category,
  accountId,
  apiId,
  size,
  set,
}: SaveFetchedExchangeApiCallCountProgressStatusParameters) => {
  // set progress api data
  const mut_exchangeAccountProgressData =
    mut_exchangeAccountProgressDataListMap[category]?.find(
      (item) => item.accountId === accountId,
    )
  if (mut_exchangeAccountProgressData === undefined) {
    return
  }
  mut_exchangeAccountProgressData.progressApiList.push({
    apiId,
    checkingSize: size,
    checkedSize: 0,
  })

  // set progress
  updateProgressList({ category, set })
}

type SaveCalledExchangeApiProgressStatusParameters = {
  readonly category: string
  readonly accountId: number
  readonly apiId: string
  readonly set: Setter
}

export const saveCalledExchangeApiProgressStatus = ({
  category,
  accountId,
  apiId,
  set,
}: SaveCalledExchangeApiProgressStatusParameters) => {
  // update progress api data
  const mut_progressApiData = mut_exchangeAccountProgressDataListMap[category]
    ?.find((item) => item.accountId === accountId)
    ?.progressApiList.find((item) => item.apiId === apiId)
  if (mut_progressApiData === undefined) {
    return
  }
  mut_progressApiData.checkedSize += 1

  // set progress
  updateProgressList({ category, set })
}

type SaveFetchedExchangeApiProgressStatusParameters = {
  readonly category: string
  readonly accountId: number
  readonly apiId: string
  readonly set: Setter
}

export const saveFetchedExchangeApiProgressStatus = ({
  category,
  accountId,
  apiId,
  set,
}: SaveFetchedExchangeApiProgressStatusParameters) => {
  // set checked api data
  const mut_exchangeAccountProgressData =
    mut_exchangeAccountProgressDataListMap[category]?.find(
      (item) => item.accountId === accountId,
    )
  if (mut_exchangeAccountProgressData === undefined) {
    return
  }
  mut_exchangeAccountProgressData.checkedApiIdSet.add(apiId)
  mut_exchangeAccountProgressData.progressApiList =
    mut_exchangeAccountProgressData.progressApiList.filter(
      (item) => item.apiId !== apiId,
    )

  // set progress
  updateProgressList({ category, set })
}

type SaveEndExchangeAnalyzeProgressStatusParameters = {
  readonly category: string
  readonly set: Setter
}

export const saveEndExchangeAnalyzeProgressStatus = ({
  category,
  set,
}: SaveEndExchangeAnalyzeProgressStatusParameters) => {
  // reset
  mut_exchangeAccountProgressDataListMap[category] = undefined

  // remove progress
  updateProgressList({ category, set })
}
