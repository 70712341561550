import {
  taxTypes,
  transactionCategories,
  transactionSubCategories,
} from '@0xtorch/accounting'
import { actionTypes } from '@0xtorch/core'
import { relations } from 'drizzle-orm'
import {
  blob,
  index,
  int,
  integer,
  sqliteTable,
  text,
  unique,
  uniqueIndex,
} from 'drizzle-orm/sqlite-core'

export const portfolioTable = sqliteTable('portfolio', {
  /** Unique ID */
  id: text('id').primaryKey(),
  /** Name of the portfolio */
  name: text('name').notNull(),
  /** Fiat currency id */
  fiat: text('fiat', { enum: ['usd', 'jpy'] }).notNull(),
  timezone: text('timezone').notNull(),
  /** Start month of accounting period */
  startMonth: int('startMonth').notNull(),
  /** Cost basis Method for profit/loss calculation
   * - FIFO: First In First Out
   * - LIFO: Last In First Out
   * - ACB: Average Cost Basis
   * - WACB: Weighted Average Cost Basis
   */
  costBasis: text('costBasis', {
    enum: ['FIFO', 'LIFO', 'ACB', 'WACB'],
  }).notNull(),
  handleLoanAsTrade: int('handleLoanAsTrade', {
    mode: 'boolean',
  }),
  handleReplaceAsTrade: int('handleReplaceAsTrade', {
    mode: 'boolean',
  }),
  use5PercentRule: int('use5PercentRule', {
    mode: 'boolean',
  }),
  /** Whether to need period end market value evaluation & profit/loss calculation */
  needPeriodEndEvaluation: int('needPeriodEndEvaluation', {
    mode: 'boolean',
  }).notNull(),
  isTxGenerated: int('isTxGenerated', {
    mode: 'boolean',
  }).notNull(),
  startTime: int('startTime', { mode: 'timestamp_ms' }),
})

export const accountTable = sqliteTable(
  'account',
  {
    id: int('id').primaryKey({ autoIncrement: true }),
    group: text('group').notNull(),
    name: text('name').notNull(),
    type: text('type', {
      enum: ['evm', 'solana', 'exchange', 'service'],
    }).notNull(),
    category: text('category').notNull(),
    syncing: int('syncing', { mode: 'boolean' }),
    lastSyncedAt: int('lastSyncedAt', { mode: 'timestamp_ms' }),
    syncRegardlessOfUsage: int('syncRegardlessOfUsage', {
      mode: 'boolean',
    }),
    evmChainId: int('evmChainId'),
    evmAddress: blob('evmAddress').$type<Uint8Array>(),
    evmToBlock: int('evmToBlock'),
    evmLastTxCount: int('evmLastTxCount'),
    solanaAddress: text('solanaAddress'),
    exchangeApiKey: text('exchangeApiKey'),
    exchangeApiSecret: text('exchangeApiSecret'),
    exchangeApiPassword: text('exchangeApiPassword'),
  },
  (table) => ({
    unqEvm: unique().on(table.evmChainId, table.evmAddress),
    unqSolana: unique().on(table.solanaAddress),
  }),
)

export const accountCsvTable = sqliteTable('accountCsv', {
  id: int('id').primaryKey({ autoIncrement: true }),
  accountId: int('accountId')
    .notNull()
    .references(() => accountTable.id),
  csvId: text('csvId').notNull(),
  csvName: text('csvName').notNull(),
})

export const accountExchangeApiTable = sqliteTable(
  'accountExchangeApi',
  {
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
    apiId: text('apiId').notNull(),
    from: int('from', { mode: 'timestamp_ms' }).notNull(),
    to: int('to', { mode: 'timestamp_ms' }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('unq_accountExchangeApi').on(table.accountId, table.apiId),
  }),
)

export const accountEvmCsvTable = sqliteTable(
  'accountEvmCsv',
  {
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
    csvId: text('csvId').notNull(),
    order: int('order').notNull(),
    fileName: text('fileName').notNull(),
    hashCount: int('hashCount').notNull(),
    from: int('from', { mode: 'timestamp_ms' }).notNull(),
    to: int('to', { mode: 'timestamp_ms' }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('unq_accountEvmCsv').on(
      table.accountId,
      table.csvId,
      table.order,
    ),
  }),
)

export const evmTxIndexTable = sqliteTable(
  'evmTxIndex',
  {
    chainId: int('chainId').notNull(),
    hash: blob('hash').$type<Uint8Array>().notNull(),
    blockNumber: int('blockNumber').notNull(),
    timestamp: int('timestamp', { mode: 'timestamp_ms' }).notNull(),
    input: blob('input').$type<Uint8Array>(),
    value: blob('value').$type<Uint8Array>(),
  },
  (table) => ({
    idx: uniqueIndex('idx_evmTxIndex').on(table.chainId, table.hash),
  }),
)

export const accountEvmTxIndexRelationTable = sqliteTable(
  'accountEvmTxIndexRelation',
  {
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
    hash: blob('hash').$type<Uint8Array>().notNull(),
    analyzed: int('analyzed', { mode: 'boolean' }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_accountEvmTxIndexRelation').on(
      table.accountId,
      table.hash,
    ),
  }),
)

export const solanaSignatureTable = sqliteTable(
  'solanaSignature',
  {
    signature: text('signature').primaryKey(),
    timestamp: int('timestamp', { mode: 'timestamp_ms' }).notNull(),
  },
  (table) => ({
    timestampIdx: index('idx_solanaSignature_timestamp').on(table.timestamp),
  }),
)

export const accountSolanaSignatureTable = sqliteTable(
  'accountSolanaSignature',
  {
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
    signature: text('signature').notNull(),
    analyzed: int('analyzed', { mode: 'boolean' }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_accountSolanaSignature').on(
      table.accountId,
      table.signature,
    ),
  }),
)

export const solanaTokenAccountTable = sqliteTable(
  'solanaTokenAccount',
  {
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
    address: text('address').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_solanaTokenAccount').on(
      table.accountId,
      table.address,
    ),
  }),
)

export const evmInternalTransactionTable = sqliteTable(
  'evmInternalTransaction',
  {
    chainId: int('chainId').notNull(),
    contractAddress: blob('contractAddress').$type<Uint8Array>().notNull(),
    from: blob('from').$type<Uint8Array>().notNull(),
    gas: blob('gas').$type<Uint8Array>().notNull(),
    hash: blob('hash').$type<Uint8Array>().notNull(),
    isError: int('isError', {
      mode: 'boolean',
    }).notNull(),
    to: blob('to').$type<Uint8Array>().notNull(),
    value: blob('value').$type<Uint8Array>().notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_evmInternalTransaction').on(
      table.chainId,
      table.contractAddress,
      table.from,
      table.gas,
      table.hash,
      table.isError,
      table.to,
      table.value,
    ),
  }),
)

export const actionTable = sqliteTable(
  'action',
  {
    id: text('id').primaryKey(),
    sourceId: text('sourceId')
      .notNull()
      .references(() => actionSourceTable.id),
    order: int('order').notNull(),
    type: text('type', { enum: actionTypes }).notNull(),
    evidence: text('evidence', {
      // TODO core module から取得するようにする
      enum: ['contract', 'system-rule', 'user-rule', 'manual-check', 'none'],
    }).notNull(),
    timestamp: int('timestamp', { mode: 'timestamp_ms' }).notNull(),
    comment: text('comment'),
    app: text('app'),
    loanId: text('loanId'),
    crossId: text('crossId'),
    crossType: text('crossType', {
      // TODO core module から取得するようにする
      enum: ['start', 'middle', 'end'],
    }),
    targetId: text('targetId').references(() => nftTable.id),
    locked: int('locked', { mode: 'boolean' }).notNull(),
    generatedTx: int('generatedTx', { mode: 'boolean' }).notNull(),
    relatedAssetIds: text('relatedAssetIds'),
    fromAddresses: text('fromAddresses'),
    toAddresses: text('toAddresses'),
    hasUnknownPriceTx: int('hasUnknownPriceTx', { mode: 'boolean' }),
    hasInsufficientAmountTx: int('hasInsufficientAmountTx', {
      mode: 'boolean',
    }),
  },
  (table) => ({
    idx: index('idx_action').on(
      table.sourceId,
      table.order,
      table.type,
      table.evidence,
      table.timestamp,
      table.app,
      table.targetId,
      table.locked,
      table.relatedAssetIds,
      table.fromAddresses,
      table.toAddresses,
      table.hasUnknownPriceTx,
      table.hasInsufficientAmountTx,
    ),
    typeIdx: index('idx_action_type').on(table.type),
    evidenceIdx: index('idx_action_evidence').on(table.evidence),
    timestampIdx: index('idx_action_timestamp').on(table.timestamp),
    appIdx: index('idx_action_app').on(table.app),
    targetIdIdx: index('targetIdIdx').on(table.targetId),
    lockedIdx: index('idx_action_locked').on(table.locked),
    relatedAssetIdsIdx: index('idx_action_relatedAssetIds').on(
      table.relatedAssetIds,
    ),
    fromAddressesIdx: index('idx_action_fromAddresses').on(table.fromAddresses),
    toAddressesIdx: index('idx_action_toAddresses').on(table.toAddresses),
    hasUnknownPriceTxIdx: index('idx_action_hasUnknownPriceTx').on(
      table.hasUnknownPriceTx,
    ),
    hasInsufficientAmountTxIdx: index('idx_action_hasInsufficientAmountTx').on(
      table.hasInsufficientAmountTx,
    ),
  }),
)

export const actionsRelations = relations(actionTable, ({ one, many }) => ({
  actionTransferTable: many(actionTransferTable),
  actionSourceTable: one(actionSourceTable, {
    fields: [actionTable.sourceId],
    references: [actionSourceTable.id],
  }),
  nftTable: one(nftTable, {
    fields: [actionTable.targetId],
    references: [nftTable.id],
  }),
  transactionList: many(accountingTransactionTable),
}))

export const actionTransferTable = sqliteTable(
  'actionTransfer',
  {
    actionId: text('actionId')
      .notNull()
      .references(() => actionTable.id),
    order: int('order').notNull(),
    direction: text('direction', {
      enum: ['in', 'out', 'none'],
    }).notNull(),
    from: text('from'),
    to: text('to'),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    fiatId: text('fiatId'),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
    price: text('price'),
  },
  (table) => ({
    idx: uniqueIndex('idx_actionTransfer').on(table.actionId, table.order),
  }),
)

export const actionTransfersRelations = relations(
  actionTransferTable,
  ({ one }) => ({
    actionTable: one(actionTable, {
      fields: [actionTransferTable.actionId],
      references: [actionTable.id],
    }),
    cryptoCurrencyTable: one(cryptoCurrencyTable, {
      fields: [actionTransferTable.cryptoId],
      references: [cryptoCurrencyTable.id],
    }),
    nftTable: one(nftTable, {
      fields: [actionTransferTable.nftId],
      references: [nftTable.id],
    }),
  }),
)

export const actionSourceTable = sqliteTable('actionSource', {
  id: text('id').primaryKey(),
  type: text('type', { enum: ['evm', 'solana', 'other'] }).notNull(),
  evmChainId: int('evmChainId'),
  evmHash: blob('evmHash').$type<Uint8Array>(),
  evmStatus: text('evmStatus', { enum: ['success', 'reverted'] }),
  evmFunctionId: blob('evmFunctionId').$type<Uint8Array>(),
  evmFunctionName: text('evmFunctionName'),
  solanaSignature: text('solanaSignature'),
  solanaStatus: text('solanaStatus', {
    enum: ['success', 'reverted'],
  }),
})

export const actionSourceRelations = relations(
  actionSourceTable,
  ({ many }) => ({
    actionTable: many(actionTable),
  }),
)

export const actionAccountRelationTable = sqliteTable(
  'actionAccountRelation',
  {
    actionId: text('actionId')
      .notNull()
      .references(() => actionTable.id),
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
  },
  (table) => ({
    idx: uniqueIndex('idx_actionAccountRelation').on(
      table.actionId,
      table.accountId,
    ),
  }),
)

export const actionCsvRelationTable = sqliteTable(
  'actionCsvRelation',
  {
    actionId: text('actionId')
      .notNull()
      .references(() => actionTable.id),
    csvId: int('csvId')
      .notNull()
      .references(() => accountCsvTable.id),
  },
  (table) => ({
    idx: uniqueIndex('idx_actionCsvRelation').on(table.actionId, table.csvId),
  }),
)

export const cryptoCurrencyTable = sqliteTable('cryptoCurrency', {
  id: text('id').primaryKey(),
  name: text('name').notNull(),
  symbol: text('symbol').notNull(),
  icon: text('icon'),
  coingeckoId: text('coingeckoId'),
  marketCapUsd: integer('marketCapUsd'),
  priceDatasourceId: text('priceDatasourceId'),
  updatedAt: integer('updatedAt').notNull(),
})

export const nftTable = sqliteTable('nft', {
  id: text('id').primaryKey(),
  name: text('name'),
  image: text('image'),
  metadata: text('metadata'),
  updatedAt: integer('updatedAt').notNull(),
})

export const erc20TokenTable = sqliteTable(
  'erc20Token',
  {
    id: text('id').primaryKey(),
    chainId: int('chainId').notNull(),
    address: blob('address').$type<Uint8Array>(),
    name: text('name').notNull(),
    symbol: text('symbol').notNull(),
    decimals: int('decimals').notNull(),
    currencyId: text('currencyId').references(() => cryptoCurrencyTable.id),
  },
  (table) => ({
    idx: uniqueIndex('idx_erc20Token').on(table.chainId, table.address),
  }),
)

export const accountErc20TokenRelationTable = sqliteTable(
  'accountErc20TokenRelation',
  {
    accountId: int('accountId')
      .notNull()
      .references(() => accountTable.id),
    tokenId: text('tokenId')
      .notNull()
      .references(() => erc20TokenTable.id),
  },
  (table) => ({
    idx: uniqueIndex('idx_accountErc20TokenRelation').on(
      table.accountId,
      table.tokenId,
    ),
  }),
)

export const singleActionRuleTable = sqliteTable(
  'singleActionRule',
  {
    source: text('source').notNull(),
    targetType: text('targetType', { enum: actionTypes }).notNull(),
    newType: text('newType', { enum: actionTypes }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_singleActionRule').on(table.source, table.targetType),
  }),
)

export const accountingPeriodTable = sqliteTable('accountingPeriod', {
  id: int('id').primaryKey(),
  start: int('start', { mode: 'timestamp_ms' }).notNull(),
  end: int('end', { mode: 'timestamp_ms' }).notNull(),
})

export const assetBalanceHistoryTable = sqliteTable(
  'assetBalanceHistory',
  {
    accountId: int('accountingId')
      .notNull()
      .references(() => accountTable.id),
    key: text('key').notNull(),
    day: int('day').notNull(),
    hour: int('hour').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    fiatId: text('fiatId'),
    amount: text('amount').notNull(),
    price: text('price'),
    timestamp: int('timestamp', { mode: 'timestamp_ms' }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_assetBalanceHistory').on(
      table.accountId,
      table.key,
      table.day,
      table.hour,
      table.cryptoId,
      table.fiatId,
    ),
  }),
)

export const assetBalanceTable = sqliteTable(
  'assetBalance',
  {
    accountingPeriodId: int('accountingPeriodId')
      .notNull()
      .references(() => accountingPeriodTable.id),
    asset: text('asset').notNull(),
    order: int('order').notNull(),
    value: text('value').notNull(),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_assetBalance').on(
      table.accountingPeriodId,
      table.asset,
      table.order,
    ),
  }),
)

export const assetBorrowTable = sqliteTable(
  'assetBorrow',
  {
    accountingPeriodId: int('accountingPeriodId')
      .notNull()
      .references(() => accountingPeriodTable.id),
    asset: text('asset').notNull(),
    order: int('order').notNull(),
    value: text('value').notNull(),
    amount: text('amount').notNull(),
    borrowedValue: text('borrowedValue').notNull(),
    borrowedAmount: text('borrowedAmount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_assetBorrow').on(
      table.accountingPeriodId,
      table.asset,
      table.order,
    ),
  }),
)

export const loanTable = sqliteTable(
  'loan',
  {
    id: int('id').primaryKey({ autoIncrement: true }),
    accountingPeriodId: int('accountingPeriodId')
      .notNull()
      .references(() => accountingPeriodTable.id),
    loanId: text('loanId').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loan').on(table.accountingPeriodId, table.loanId),
  }),
)

export const loanRelations = relations(loanTable, ({ many }) => ({
  loanBorrows: many(loanBorrowTable),
  loanBorrowWithDebts: many(loanBorrowWithDebtTable),
  loanBorrowWithDebtPositions: many(loanBorrowWithDebtPositionTable),
  loanDeposits: many(loanDepositTable),
  loanDepositWithBonds: many(loanDepositWithBondTable),
  loanDepositWithBondPositions: many(loanDepositWithBondPositionTable),
}))

export const loanBorrowTable = sqliteTable(
  'loanBorrow',
  {
    loanId: int('loanId')
      .notNull()
      .references(() => loanTable.id),
    key: text('key').notNull(),
    order: int('order').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loanBorrowPosition').on(
      table.loanId,
      table.key,
      table.order,
    ),
  }),
)

export const loanBorrowRelations = relations(loanBorrowTable, ({ one }) => ({
  loan: one(loanTable, {
    fields: [loanBorrowTable.loanId],
    references: [loanTable.id],
  }),
  crypto: one(cryptoCurrencyTable, {
    fields: [loanBorrowTable.cryptoId],
    references: [cryptoCurrencyTable.id],
  }),
  nft: one(nftTable, {
    fields: [loanBorrowTable.nftId],
    references: [nftTable.id],
  }),
}))

export const loanBorrowWithDebtTable = sqliteTable(
  'loanBorrowWithDebt',
  {
    loanId: int('loanId')
      .notNull()
      .references(() => loanTable.id),
    key: text('key').notNull(),
    order: int('order').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loanBorrowWithDebt').on(
      table.loanId,
      table.key,
      table.order,
    ),
  }),
)

export const loanBorrowWithDebtRelations = relations(
  loanBorrowWithDebtTable,
  ({ one }) => ({
    loan: one(loanTable, {
      fields: [loanBorrowWithDebtTable.loanId],
      references: [loanTable.id],
    }),
    crypto: one(cryptoCurrencyTable, {
      fields: [loanBorrowWithDebtTable.cryptoId],
      references: [cryptoCurrencyTable.id],
    }),
    nft: one(nftTable, {
      fields: [loanBorrowWithDebtTable.nftId],
      references: [nftTable.id],
    }),
  }),
)

export const loanBorrowWithDebtPositionTable = sqliteTable(
  'loanBorrowWithDebtPosition',
  {
    loanId: int('loanId')
      .notNull()
      .references(() => loanTable.id),
    debtKey: text('debtKey').notNull(),
    debtOrder: int('debtOrder').notNull(),
    positionOrder: int('positionOrder').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loanBorrowWithDebtPosition').on(
      table.loanId,
      table.debtKey,
      table.debtOrder,
      table.positionOrder,
    ),
  }),
)

export const loanBorrowWithDebtPositionRelations = relations(
  loanBorrowWithDebtPositionTable,
  ({ one }) => ({
    loan: one(loanTable, {
      fields: [loanBorrowWithDebtPositionTable.loanId],
      references: [loanTable.id],
    }),
    crypto: one(cryptoCurrencyTable, {
      fields: [loanBorrowWithDebtPositionTable.cryptoId],
      references: [cryptoCurrencyTable.id],
    }),
    nft: one(nftTable, {
      fields: [loanBorrowWithDebtPositionTable.nftId],
      references: [nftTable.id],
    }),
  }),
)

export const loanDepositTable = sqliteTable(
  'loanDeposit',
  {
    loanId: int('loanId')
      .notNull()
      .references(() => loanTable.id),
    key: text('key').notNull(),
    order: int('order').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loanDeposit').on(
      table.loanId,
      table.key,
      table.order,
    ),
  }),
)

export const loanDepositRelations = relations(loanDepositTable, ({ one }) => ({
  loan: one(loanTable, {
    fields: [loanDepositTable.loanId],
    references: [loanTable.id],
  }),
  crypto: one(cryptoCurrencyTable, {
    fields: [loanDepositTable.cryptoId],
    references: [cryptoCurrencyTable.id],
  }),
  nft: one(nftTable, {
    fields: [loanDepositTable.nftId],
    references: [nftTable.id],
  }),
}))

export const loanDepositWithBondTable = sqliteTable(
  'loanDepositWithBond',
  {
    loanId: int('loanId')
      .notNull()
      .references(() => loanTable.id),
    key: text('key').notNull(),
    order: int('order').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loanDepositWithBond').on(
      table.loanId,
      table.key,
      table.order,
    ),
  }),
)

export const loanDepositWithBondRelations = relations(
  loanDepositWithBondTable,
  ({ one }) => ({
    loan: one(loanTable, {
      fields: [loanDepositWithBondTable.loanId],
      references: [loanTable.id],
    }),
    crypto: one(cryptoCurrencyTable, {
      fields: [loanDepositWithBondTable.cryptoId],
      references: [cryptoCurrencyTable.id],
    }),
    nft: one(nftTable, {
      fields: [loanDepositWithBondTable.nftId],
      references: [nftTable.id],
    }),
  }),
)

export const loanDepositWithBondPositionTable = sqliteTable(
  'loanDepositWithBondPosition',
  {
    loanId: int('loanId')
      .notNull()
      .references(() => loanTable.id),
    bondKey: text('bondKey').notNull(),
    bondOrder: int('bondOrder').notNull(),
    positionOrder: int('positionOrder').notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_loanDepositWithBondPosition').on(
      table.loanId,
      table.bondKey,
      table.bondOrder,
      table.positionOrder,
    ),
  }),
)

export const loanDepositWithBondPositionRelations = relations(
  loanDepositWithBondPositionTable,
  ({ one }) => ({
    loan: one(loanTable, {
      fields: [loanDepositWithBondPositionTable.loanId],
      references: [loanTable.id],
    }),
    crypto: one(cryptoCurrencyTable, {
      fields: [loanDepositWithBondPositionTable.cryptoId],
      references: [cryptoCurrencyTable.id],
    }),
    nft: one(nftTable, {
      fields: [loanDepositWithBondPositionTable.nftId],
      references: [nftTable.id],
    }),
  }),
)

export const crossActionBundleTable = sqliteTable(
  'crossActionBundle',
  {
    id: int('id').primaryKey({ autoIncrement: true }),
    accountingPeriodId: int('accountingPeriodId')
      .notNull()
      .references(() => accountingPeriodTable.id),
    crossActionBundleId: text('crossActionBundleId').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_crossActionBundle').on(
      table.accountingPeriodId,
      table.crossActionBundleId,
    ),
  }),
)

export const crossActionBundleRelations = relations(
  crossActionBundleTable,
  ({ many }) => ({
    crossActionBundleRelationList: many(crossActionBundleRelationTable),
  }),
)

export const crossActionBundleRelationTable = sqliteTable(
  'crossActionBundleRelation',
  {
    crossActionBundleId: int('crossActionBundleId')
      .notNull()
      .references(() => crossActionBundleTable.id),
    actionId: text('actionId')
      .notNull()
      .references(() => actionTable.id),
  },
  (table) => ({
    idx: uniqueIndex('idx_crossActionBundleRelation').on(
      table.crossActionBundleId,
      table.actionId,
    ),
  }),
)

export const crossActionBundleRelationRelations = relations(
  crossActionBundleRelationTable,
  ({ one }) => ({
    crossActionBundle: one(crossActionBundleTable, {
      fields: [crossActionBundleRelationTable.crossActionBundleId],
      references: [crossActionBundleTable.id],
    }),
    action: one(actionTable, {
      fields: [crossActionBundleRelationTable.actionId],
      references: [actionTable.id],
    }),
  }),
)

export const accountingTransactionTable = sqliteTable(
  'accountingTransaction',
  {
    id: text('id').primaryKey(),
    accountingPeriodId: int('accountingPeriodId')
      .notNull()
      .references(() => accountingPeriodTable.id),
    actionId: text('actionId')
      .notNull()
      .references(() => actionTable.id),
    order: int('order').notNull(),
    category: text('category', {
      enum: transactionCategories,
    }).notNull(),
    subCategory: text('subCategory', {
      enum: transactionSubCategories,
    }),
    targetId: text('targetId').references(() => nftTable.id),
    timestamp: int('timestamp', {
      mode: 'timestamp_ms',
    }).notNull(),
    comment: text('comment'),
    updatedAt: int('updatedAt', {
      mode: 'timestamp_ms',
    }).notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_accountingTransaction').on(
      table.actionId,
      table.order,
    ),
  }),
)

export const accountingTransactionRelations = relations(
  accountingTransactionTable,
  ({ one, many }) => ({
    action: one(actionTable, {
      fields: [accountingTransactionTable.actionId],
      references: [actionTable.id],
    }),
    transferList: many(accountingTransactionTransferTable),
    nft: one(nftTable, {
      fields: [accountingTransactionTable.targetId],
      references: [nftTable.id],
    }),
  }),
)

export const accountingTransactionTransferTable = sqliteTable(
  'accountingTransactionTransfer',
  {
    transactionId: text('transactionId')
      .notNull()
      .references(() => accountingTransactionTable.id),
    order: int('order').notNull(),
    isIn: int('isIn', {
      mode: 'boolean',
    }).notNull(),
    cryptoId: text('cryptoId').references(() => cryptoCurrencyTable.id),
    fiatId: text('fiatId'),
    nftId: text('nftId').references(() => nftTable.id),
    amount: text('amount').notNull(),
    price: text('price').notNull(),
    zeroCompleted: int('zeroCompleted', {
      mode: 'boolean',
    }).notNull(),
    cost: text('cost').notNull(),
    balance: text('balance').notNull(),
    borrowing: text('borrowing').notNull(),
    totalPl: text('totalPl').notNull(),
    tradePl: text('tradePl').notNull(),
    incomePl: text('incomePl').notNull(),
    feePl: text('feePl').notNull(),
  },
  (table) => ({
    idx: uniqueIndex('idx_accountingTransactionTransfer').on(
      table.transactionId,
      table.order,
    ),
  }),
)

export const accountingTransactionTransferRelations = relations(
  accountingTransactionTransferTable,
  ({ one }) => ({
    transaction: one(accountingTransactionTable, {
      fields: [accountingTransactionTransferTable.transactionId],
      references: [accountingTransactionTable.id],
    }),
    cryptoCurrency: one(cryptoCurrencyTable, {
      fields: [accountingTransactionTransferTable.cryptoId],
      references: [cryptoCurrencyTable.id],
    }),
    nft: one(nftTable, {
      fields: [accountingTransactionTransferTable.nftId],
      references: [nftTable.id],
    }),
  }),
)

export const assetValueTable = sqliteTable(
  'assetValue',
  {
    accountingPeriodId: int('accountingPeriodId')
      .notNull()
      .references(() => accountingPeriodTable.id),
    asset: text('asset').notNull(),
    type: text('type', { enum: ['own', 'borrow', 'lend'] }).notNull(),
    amount: text('amount').notNull(),
    cost: text('cost').notNull(),
    value: text('value').notNull(),
    isKnownPeriodEndPrice: int('isKnownPeriodEndPrice', {
      mode: 'boolean',
    }).notNull(),
  },
  (table) => ({
    unq: unique().on(table.accountingPeriodId, table.asset, table.type),
  }),
)

export const journalAccountTable = sqliteTable('journalAccount', {
  key: text('key').primaryKey(),
  account: text('account').notNull(),
  subAccount: text('subAccount').notNull(),
  debitTaxType: text('debitTaxType', {
    enum: taxTypes,
  }).notNull(),
  creditTaxType: text('creditTaxType', {
    enum: taxTypes,
  }).notNull(),
})
