import {
  type Erc20Token,
  type InternalTransaction,
  type TransactionIndex,
  createEvmAddressId,
} from '@0xtorch/evm'
import { divideArrayIntoChunks, hexToBlob } from '@pkg/basic'
import { eq, sql } from 'drizzle-orm'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import {
  accountErc20TokenRelationColumnCount,
  accountEvmTxIndexRelationColumnCount,
  erc20TokenColumnCount,
  evmInternalTransactionColumnCount,
  evmTxIndexColumnCount,
} from '../constants'
import {
  accountErc20TokenRelationTable,
  accountEvmTxIndexRelationTable,
  accountTable,
  erc20TokenTable,
  evmInternalTransactionTable,
  evmTxIndexTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'
import { conflictUpdateAllExcept, getMaxInsertRowCount } from '../utils'

type SaveEvmTxIndexesParameters = {
  database: DatabaseWithTransaction
  accountId: number
  chainId: number
  internalTransactions: readonly InternalTransaction[]
  txIndexes: readonly TransactionIndex[]
  erc20Tokens: readonly Erc20Token[]
  transactionCount: number
}

export const saveEvmTxIndexes = async ({
  database: { transaction },
  accountId,
  chainId,
  internalTransactions,
  txIndexes,
  erc20Tokens,
  transactionCount,
}: SaveEvmTxIndexesParameters): Promise<void> => {
  if (txIndexes.length === 0) {
    return
  }

  let toBlock = txIndexes[0].blockNumber
  const blobTxIndexes: (Omit<TransactionIndex, 'hash' | 'input' | 'value'> & {
    hash: Uint8Array
    input?: Uint8Array
    value?: Uint8Array
  })[] = []
  for (const index of txIndexes) {
    if (index.blockNumber > toBlock) {
      toBlock = index.blockNumber
    }
    blobTxIndexes.push({
      blockNumber: index.blockNumber,
      timestamp: index.timestamp,
      hash: hexToBlob(index.hash),
      input: index.input === undefined ? undefined : hexToBlob(index.input),
      value:
        index.value === undefined
          ? undefined
          : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            hexToBlob(`0x${index.value.toString(16)}`),
    })
  }

  const erc20TokenSqls: SQLiteInsertValue<typeof erc20TokenTable>[] = []
  const accountErc20RelationSqls: SQLiteInsertValue<
    typeof accountErc20TokenRelationTable
  >[] = []
  for (const token of erc20Tokens) {
    const tokenId = createEvmAddressId({ chainId, address: token.address })
    erc20TokenSqls.push({
      id: tokenId,
      chainId,
      address: hexToBlob(token.address),
      name: token.name,
      symbol: token.symbol,
      decimals: token.decimals,
      currencyId: token.currency.id,
    })
    accountErc20RelationSqls.push({
      accountId,
      tokenId,
    })
  }

  await transaction([
    (tx) => [
      // evmInternalTransaction に重複がない場合のみ保存
      ...divideArrayIntoChunks(
        internalTransactions,
        getMaxInsertRowCount(evmInternalTransactionColumnCount),
      ).map((internals) =>
        tx
          .insert(evmInternalTransactionTable)
          .values(
            internals.map((internal) => ({
              chainId,
              contractAddress:
                internal.contractAddress === undefined
                  ? Uint8Array.from([])
                  : hexToBlob(internal.contractAddress),
              from: hexToBlob(internal.from),
              gas: hexToBlob(`0x${internal.gas.toString(16)}`),
              hash: hexToBlob(internal.txHash),
              isError: internal.isError,
              to:
                internal.to === undefined
                  ? Uint8Array.from([])
                  : hexToBlob(internal.to),
              value: hexToBlob(`0x${internal.value.toString(16)}`),
            })),
          )
          .onConflictDoNothing(),
      ),
      // evmTxIndex に重複がない場合のみ保存
      ...divideArrayIntoChunks(
        blobTxIndexes,
        getMaxInsertRowCount(evmTxIndexColumnCount),
      ).map((indexes) =>
        tx
          .insert(evmTxIndexTable)
          .values(
            indexes.map((txIndex) => ({
              chainId,
              hash: txIndex.hash,
              blockNumber: txIndex.blockNumber,
              timestamp: new Date(txIndex.timestamp),
              input: txIndex.input,
              value: txIndex.value,
            })),
          )
          .onConflictDoNothing(),
      ),
      // accountEvmTxIndexRelation に重複がない場合のみ analyzed = false で保存
      ...divideArrayIntoChunks(
        blobTxIndexes,
        getMaxInsertRowCount(accountEvmTxIndexRelationColumnCount),
      ).map((indexes) =>
        tx
          .insert(accountEvmTxIndexRelationTable)
          .values(
            indexes.map((txIndex) => ({
              accountId,
              hash: txIndex.hash,
              analyzed: false,
            })),
          )
          .onConflictDoNothing(),
      ),
      // erc20 token に重複がない場合のみ保存
      ...divideArrayIntoChunks(
        erc20TokenSqls,
        getMaxInsertRowCount(erc20TokenColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(erc20TokenTable)
          .values([...chunk])
          .onConflictDoUpdate({
            target: [erc20TokenTable.id],
            set: conflictUpdateAllExcept(erc20TokenTable, ['id']),
          }),
      ),
      // accountErc20TokenRelation に重複がない場合のみ保存
      ...divideArrayIntoChunks(
        accountErc20RelationSqls,
        getMaxInsertRowCount(accountErc20TokenRelationColumnCount),
      ).map((chunk) =>
        tx
          .insert(accountErc20TokenRelationTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
    ],
    (tx) => [
      // account を最新データに合わせて更新
      tx
        .update(accountTable)
        .set({
          syncing: sql`(EXISTS (SELECT 1 FROM ${accountEvmTxIndexRelationTable} WHERE ${accountEvmTxIndexRelationTable.accountId} = ${accountId} AND ${accountEvmTxIndexRelationTable.analyzed} = ${false}))`,
          lastSyncedAt: new Date(),
          evmToBlock: sql`(CASE WHEN ${accountTable.evmToBlock} IS NULL OR ${accountTable.evmToBlock} < ${toBlock} THEN ${toBlock} ELSE ${accountTable.evmToBlock} END)`,
          evmLastTxCount: transactionCount,
        })
        .where(eq(accountTable.id, accountId)),
    ],
  ])
}
