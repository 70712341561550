import { accountingPeriodTable } from '../schema'
import type {
  DatabaseWithTransaction,
  InsertAccountingPeriodData,
} from '../types'
import { conflictUpdateAllExcept } from '../utils'

type UpsertAccountingPeriodListParameters = {
  readonly database: DatabaseWithTransaction
  readonly data: readonly InsertAccountingPeriodData[]
}

export const upsertAccountingPeriodList = async ({
  database: { database },
  data,
}: UpsertAccountingPeriodListParameters) => {
  if (data.length === 0) {
    return
  }

  await database
    .insert(accountingPeriodTable)
    .values([...data])
    .onConflictDoUpdate({
      target: accountingPeriodTable.id,
      set: conflictUpdateAllExcept(accountingPeriodTable, ['id']),
    })
}
