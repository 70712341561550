import type { Hex, LowerHex } from '@0xtorch/evm'

export const hexToBlob = (hex: Hex): Uint8Array => {
  if (hex === '0x') {
    return new Uint8Array()
  }
  const text = hex.length % 2 === 0 ? hex.slice(2) : `0${hex.slice(2)}`
  const bytes = text.match(/.{1,2}/g)
  if (bytes === null) {
    throw new Error('Invalid hex string')
  }
  return Uint8Array.from(bytes.map((byte) => Number.parseInt(byte, 16)))
}

export const blobToHex = (blob: Uint8Array): LowerHex => {
  let hex: LowerHex = '0x'
  for (const v of blob) {
    hex += v.toString(16).padStart(2, '0')
  }
  return hex
}
