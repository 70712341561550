import { createSolanaActionSource } from '@0xtorch/solana'
import { divideArrayIntoChunks } from '@pkg/basic'
import { and, eq, inArray, sql } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../../constants'
import {
  accountSolanaSignatureTable,
  accountTable,
  actionTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

type IgnoreLockedSolanaTxsParameters = {
  database: DatabaseWithTransaction
  signatures: readonly string[]
}

/** locked = true の action に紐づく signatures を取得
 * - locked = true の action に紐づく signature 関連の account solana signature は　analyzed=true に更新
 * - account.syncing を account solana signature の更新に合わせて更新
 */
export const ignoreLockedSolanaTxs = async ({
  database: { database, transaction },
  signatures,
}: IgnoreLockedSolanaTxsParameters): Promise<Set<string>> => {
  if (signatures.length === 0) {
    return new Set()
  }

  const accountIdRows = await database
    .select({ id: accountTable.id })
    .from(accountTable)
    .where(eq(accountTable.type, 'solana'))

  const sources = signatures.map((signature) =>
    createSolanaActionSource({ signature }),
  )

  // locked=true の action の source を取得
  const sourceResults = await Promise.all(
    divideArrayIntoChunks(sources, sqliteMaxHostParameterCount - 1).map(
      (chunk) =>
        database
          .selectDistinct({ source: actionTable.sourceId })
          .from(actionTable)
          .where(
            and(
              eq(actionTable.locked, true),
              inArray(actionTable.sourceId, [...chunk]),
            ),
          ),
    ),
  )

  // action source を signature list に変換
  const lockedSignatureSet = new Set<string>()
  for (const result of sourceResults) {
    for (const { source } of result) {
      if (source.startsWith('solana_')) {
        lockedSignatureSet.add(source.slice(7))
      }
    }
  }

  await transaction([
    (tx) => [
      // account solana signature の analyzed を true に更新
      ...divideArrayIntoChunks(
        [...lockedSignatureSet],
        sqliteMaxHostParameterCount - 1,
      ).map((chunk) =>
        tx
          .update(accountSolanaSignatureTable)
          .set({ analyzed: true })
          .where(inArray(accountSolanaSignatureTable.signature, [...chunk])),
      ),
    ],
    (tx) => [
      // account.syncing を更新
      ...accountIdRows.map(({ id }) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountSolanaSignatureTable} WHERE ${accountSolanaSignatureTable.accountId} = ${id} AND ${accountSolanaSignatureTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, id)),
      ),
    ],
  ])

  return lockedSignatureSet
}
