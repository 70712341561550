import LoadingOverlay from '@/components/loadingOverlay'
import { Toaster } from '@/components/ui/toaster'
import { useAuth } from '@clerk/clerk-react'
import {
  Outlet,
  createRootRoute,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
if (PUBLISHABLE_KEY === undefined || PUBLISHABLE_KEY === '') {
  throw new Error('Missing Publishable Key')
}

export const Route = createRootRoute({
  validateSearch: (search: Record<string, unknown>): { id?: string } => {
    return {
      id: (search.id as string) ?? undefined,
    }
  },
  component: () => {
    const { userId, isLoaded } = useAuth()
    const routerState = useRouterState()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
      if (!isLoaded) {
        return
      }
      if (
        userId === null &&
        routerState.location.pathname !== '/signIn' &&
        routerState.location.pathname !== '/signIn/factor-one' &&
        routerState.location.pathname !== '/waitlist'
      ) {
        navigate({
          to: '/signIn',
        })
      }
      if (
        userId !== null &&
        (routerState.location.pathname === '/signIn' ||
          routerState.location.pathname === '/signIn/factor-one' ||
          routerState.location.pathname === '/waitlist')
      ) {
        navigate({
          to: '/',
        })
      }
    }, [isLoaded, userId, routerState, navigate])

    if (!isLoaded) {
      return <LoadingOverlay description={t('loading/authenticating')} />
    }

    return (
      <>
        <Outlet />
        {/* <TanStackRouterDevtools position="top-left" /> */}
        <Toaster />
      </>
    )
  },
})
