import type { CryptoCurrency } from '@0xtorch/core'
import type { TransactionDetail } from '@0xtorch/solana'
import {
  createAssetsMiddleware,
  createSplTokenDatasource,
} from '@pkg/spl-token-datasource'

type CreateSolanaDatasourceCacheParameters = {
  readonly transactionList: readonly TransactionDetail[]
  readonly cryptoCurrencyList: readonly CryptoCurrency[]
  readonly splTokenApiEndpoint: string
  readonly assetApiEndpoint: string
}

export const createSolanaDatasourceCache = async ({
  transactionList,
  cryptoCurrencyList,
  splTokenApiEndpoint,
  assetApiEndpoint,
}: CreateSolanaDatasourceCacheParameters) => {
  // Create instructionProgramId set
  const programIdSet = new Set(
    transactionList.flatMap((transaction) =>
      transaction.transaction.message.instructions.map(
        (instruction) => instruction.programId,
      ),
    ),
  )

  // Create token mint address set
  const mintAddressSet = new Set(
    transactionList.flatMap((transaction) => [
      ...(transaction.meta?.preTokenBalances?.map((balance) => balance.mint) ??
        []),
      ...(transaction.meta?.postTokenBalances?.map((balance) => balance.mint) ??
        []),
    ]),
  )

  // Create cache data
  const assetMiddleware = createAssetsMiddleware(assetApiEndpoint)
  const splTokenDatasource = createSplTokenDatasource({
    apiEndpoint: splTokenApiEndpoint,
    cryptoCurrencies: cryptoCurrencyList,
  })
  await Promise.all([
    Promise.all(
      [...programIdSet].map((programId) =>
        assetMiddleware.getAnalyzerJson(programId),
      ),
    ),
    Promise.all(
      [...mintAddressSet].map((mintAddress) =>
        splTokenDatasource.getTokenByMintAccount(mintAddress),
      ),
    ),
  ])
}
