import {
  clearPortfolioDataFromLocalStorage,
  getPortfolioFromLocalStorage,
  getPortfolioUpdatedAtFromLocalStorage,
  getPortfolioUsedAtFromLocalStorage,
  setPortfolioIsWritableToLocalStorage,
  setPortfolioToLocalStorage,
  setPortfolioUpdatedAtToLocalStorage,
  setPortfolioUsedAtToLocalStorage,
} from '@/localStorage'
import type { Portfolio } from '@/types'
import {
  createReadFromDatabase,
  createWriteToDatabase,
  getDatabase,
} from '@/utils'
import { portfolioSyncInterval } from '@pkg/basic'
import { SQLocal } from 'sqlocal'
import type { SetupPortfolioReturnTypes } from '.'
import { migration } from '../../logic/migration'
import { syncAfterSetup } from '../../logic/syncAfterSetup'
import { syncDbFileToRemote } from '../../logic/syncDbFileToRemote'
import { createPortfolioDbFileName } from '../../utils'
import { getPortfolioDbFile } from './getPortfolioDbFile'
import { resetPortfolioLastUsedAt } from './resetPortfolioLastUsedAt'

export const changePortfolio = async (params: {
  token: string
  portfolios: readonly Portfolio[]
  id: string
}): Promise<SetupPortfolioReturnTypes> => {
  const { token, portfolios, id } = params

  const currentLocalPortfolio = getPortfolioFromLocalStorage()
  const currentLastUsedAt = getPortfolioUsedAtFromLocalStorage()
  const currentUpdatedAt = getPortfolioUpdatedAtFromLocalStorage()
  const currentPortfolio = portfolios.find(
    (p) => p.uuid === currentLocalPortfolio?.uuid,
  )

  const [newDbFile] = await Promise.all([
    // 変更後 portfolio db file を取得する
    getPortfolioDbFile({ token, id }),
    // 変更前 portfolio DB が未同期なら remote に upload
    currentPortfolio !== undefined &&
    currentUpdatedAt !== undefined &&
    currentUpdatedAt >= currentPortfolio.updatedAt
      ? syncDbFileToRemote({
          portfolio: currentPortfolio,
          token,
        })
      : Promise.resolve(),
    // 変更前 portfolio の lastUsedAt を最終使用者が自分なら破棄する
    currentPortfolio !== undefined &&
    currentLastUsedAt !== undefined &&
    currentLastUsedAt >= currentPortfolio.lastUsedAt
      ? resetPortfolioLastUsedAt({ token, id: currentPortfolio.uuid })
      : Promise.resolve(),
  ])

  // 既存 portfolio DB 削除
  if (currentLocalPortfolio !== undefined) {
    const { deleteDatabaseFile, destroy } = new SQLocal(
      createPortfolioDbFileName(currentLocalPortfolio.uuid),
    )
    try {
      await deleteDatabaseFile()
    } finally {
      await destroy()
    }
  }

  // 新 portfolio DB 保存
  const portfolio = portfolios.find((p) => p.uuid === id)
  if (portfolio === undefined) {
    throw new Error(`Portfolio not found: ${id}`)
  }
  const dbFileName = createPortfolioDbFileName(portfolio.uuid)
  if (newDbFile === undefined) {
    throw new Error(`Failed to get portfolio DB file: ${id}`)
  }
  const { overwriteDatabaseFile, destroy } = new SQLocal(dbFileName)
  try {
    await overwriteDatabaseFile(newDbFile)
  } finally {
    await destroy()
  }

  // local storage 更新
  clearPortfolioDataFromLocalStorage()
  setPortfolioToLocalStorage(portfolio)
  const readonly =
    portfolio.plan !== 'free' &&
    portfolio.plan !== 'mini' &&
    (!portfolio.isWriter ||
      portfolio.lastUsedAt > Date.now() - portfolioSyncInterval * 2)
  setPortfolioIsWritableToLocalStorage(!readonly)
  setPortfolioUpdatedAtToLocalStorage(portfolio.updatedAt)
  if (!readonly) {
    setPortfolioUsedAtToLocalStorage(portfolio.lastUsedAt)
  }

  // return data 作成
  const { database } = getDatabase(portfolio.uuid)
  const readFromDatabase = createReadFromDatabase(database)
  const writeToDatabase = readonly ? undefined : createWriteToDatabase(database)
  const portfolioPageData = {
    ...portfolio,
    readonly,
    readFromDatabase,
    writeToDatabase,
  }

  // migration
  await migration({
    readFromDatabase,
    writeToDatabase: writeToDatabase ?? readFromDatabase,
  })

  // sync 処理（完了を待たない）
  syncAfterSetup({
    ...portfolioPageData,
    token,
  })

  return {
    portfolios,
    portfolio: portfolioPageData,
  }
}
