export const apiEndpoint =
  typeof import.meta.env.VITE_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_API_ENDPOINT
    : ''
if (apiEndpoint === '') {
  throw new Error('VITE_API_ENDPOINT is not set')
}

export const assetApiEndpoint =
  typeof import.meta.env.VITE_ASSET_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_ASSET_API_ENDPOINT
    : ''
if (assetApiEndpoint === '') {
  throw new Error('VITE_ASSET_API_ENDPOINT is not set')
}

export const datasourceApiEndpoint =
  typeof import.meta.env.VITE_DATASOURCE_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_DATASOURCE_API_ENDPOINT
    : ''
if (datasourceApiEndpoint === '') {
  throw new Error('VITE_DATASOURCE_API_ENDPOINT is not set')
}

export const evmTokenApiEndpoint =
  typeof import.meta.env.VITE_EVM_TOKEN_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_EVM_TOKEN_API_ENDPOINT
    : ''
if (evmTokenApiEndpoint === '') {
  throw new Error('VITE_EVM_TOKEN_API_ENDPOINT is not set')
}

export const proxyApiEndpoint =
  typeof import.meta.env.VITE_PROXY_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_PROXY_API_ENDPOINT
    : ''
if (proxyApiEndpoint === '') {
  throw new Error('VITE_PROXY_API_ENDPOINT is not set')
}

export const priceDataSourceApiEndpoint =
  typeof import.meta.env.VITE_PRICE_DATASOURCE_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_PRICE_DATASOURCE_API_ENDPOINT
    : ''
if (priceDataSourceApiEndpoint === '') {
  throw new Error('VITE_PRICE_DATASOURCE_API_ENDPOINT is not set')
}

export const splTokenApiEndpoint =
  typeof import.meta.env.VITE_SPL_TOKEN_API_ENDPOINT === 'string'
    ? import.meta.env.VITE_SPL_TOKEN_API_ENDPOINT
    : ''
if (splTokenApiEndpoint === '') {
  throw new Error('VITE_SPL_TOKEN_API_ENDPOINT is not set')
}
