import { apiEndpoint } from '@/environment'

export const solanaRpcEndpoints: string[] = [
  `${apiEndpoint}/v1/proxy/solana-1`,
  `${apiEndpoint}/v1/proxy/solana-2`,
  `${apiEndpoint}/v1/proxy/solana-3`,
  `${apiEndpoint}/v1/proxy/solana-4`,
  `${apiEndpoint}/v1/proxy/solana-5`,
  `${apiEndpoint}/v1/proxy/solana-6`,
]
