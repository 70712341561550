import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Card } from '@/components/ui/card'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { Progress } from '@/components/ui/progress'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { accountCategoryList } from '@pkg/basic'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import type { TFunction } from 'i18next'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { progressListAtom } from '../../states/progress'
import type { AnalyzeProgress } from '../../types'

const AnalyzeProgressToast = () => {
  const { t } = useTranslation()
  const [progressList] = useAtom(progressListAtom)
  const [open, setOpen] = useState(false)

  return (
    <Card className="w-[360px] p-2">
      <Collapsible open={open} onOpenChange={setOpen}>
        <CollapsibleTrigger className="w-full">
          <div className="flex w-full items-center justify-between px-2">
            <p className="font-bold text-muted-foreground text-sm">
              {t('Syncing {{size}} chains / exchanges', {
                size: progressList.length,
              })}
            </p>
            <div className="ml-4">
              <ChevronDownIcon className={open ? 'rotate-180' : ''} />
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent className="mt-2 max-h-[112px] divide-y overflow-y-scroll border-t">
          {progressList.map((progress) => (
            <ProgressItem key={progress.category} progress={progress} />
          ))}
        </CollapsibleContent>
      </Collapsible>
    </Card>
  )
}

const getProgressStatusText = (
  progress: AnalyzeProgress,
  t: TFunction,
): string => {
  switch (progress.status) {
    case 'analyze': {
      return t('Analyzing')
    }
    case 'calculate': {
      return t('Calculating Profit & Loss')
    }
    case 'check-account': {
      return t('Checking Account')
    }
    case 'sync': {
      return t('Syncing')
    }
  }
}

const ProgressItem = ({ progress }: { progress: AnalyzeProgress }) => {
  const { t } = useTranslation()

  const category = accountCategoryList.find(
    ({ id }) => id === progress.category,
  )
  if (category === undefined) {
    return <></>
  }

  return (
    <div className="flex w-full items-center space-x-2 px-3 py-2">
      <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Avatar className="h-4 w-4">
              <AvatarImage src={category.icon} />
              <AvatarFallback className="bg-primary">
                {category.name[0].toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent className="bg-secondary text-secondary-foreground">
            <p>{category.name}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <p className="w-2/5 text-sm">{getProgressStatusText(progress, t)}</p>
      <div className="flex-1 pl-2">
        <Progress value={progress.progress} />
      </div>
      <p className="text-sm">{Math.floor(progress.progress)} %</p>
    </div>
  )
}

export default AnalyzeProgressToast
