import type { RunToDatabase } from '@/types'
import {
  type DatabaseWithTransaction,
  type Portfolio,
  insertPortfolio,
  selectPortfolio,
} from '@pkg/database-portfolio'

export const getPortfolio = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase?: RunToDatabase
}): Promise<Portfolio> => {
  const { readFromDatabase, writeToDatabase } = params
  const portfolio = await readFromDatabase((database) =>
    selectPortfolio({ database }),
  )
  if (portfolio === undefined) {
    if (writeToDatabase === undefined) {
      throw new Error('Portfolio not found')
    }
    await writeToDatabase((database) => initPortfolio({ database }))
    const newPortfolio = await readFromDatabase((database) =>
      selectPortfolio({ database }),
    )
    if (newPortfolio === undefined) {
      throw new Error('Portfolio not found')
    }
    return newPortfolio
  }
  return portfolio
}

const initPortfolio = async (params: {
  database: DatabaseWithTransaction
}) => {
  const { database } = params

  // TODO ベータ版では Asia/Tokyo 以外は選択できないようにする
  // TODO 後日 Intl API で取得したタイムゾーンによって設定を変える
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let portfolioLocale: PortfolioLocale
  // switch (timezone) {
  //   case 'Asia/Tokyo': {
  //     portfolioLocale = createPortfolioLocaleJp(timezone)
  //     break
  //   }
  //   default: {
  //     portfolioLocale = createPortfolioLocaleDefault(timezone)
  //     break
  //   }
  // }
  const portfolioLocale = createPortfolioLocaleJp('Asia/Tokyo')

  await insertPortfolio({
    database,
    data: {
      ...portfolioLocale,
      id: 'id',
      name: 'name',
      needPeriodEndEvaluation: false,
      isTxGenerated: true,
    },
  })
}

type PortfolioLocale = Pick<
  Portfolio,
  'fiat' | 'timezone' | 'startMonth' | 'costBasis'
>

const createPortfolioLocaleJp = (timezone: string): PortfolioLocale => ({
  fiat: 'jpy',
  timezone,
  startMonth: 1,
  costBasis: 'WACB',
})

const createPortfolioLocaleDefault = (timezone: string): PortfolioLocale => ({
  fiat: 'usd',
  timezone,
  startMonth: 1,
  costBasis: 'FIFO',
})
