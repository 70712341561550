import type { CryptoCurrencyDataSource, FiatCurrency } from '@0xtorch/core'
import {
  type AccountingPeriod,
  type DatabaseWithTransaction,
  selectAssetBalances,
  selectAssetBorrows,
  selectCryptoCurrencies,
  upsertAssetValues,
} from '@pkg/database-portfolio'
import { generateAssetValues } from './generateAssetValues'

type SaveAssetValuesParameters = {
  database: DatabaseWithTransaction
  accountingPeriod: AccountingPeriod
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  fiat: FiatCurrency
}

export const saveAssetValues = async ({
  database,
  accountingPeriod,
  cryptoCurrencyDataSource,
  fiat,
}: SaveAssetValuesParameters) => {
  const [assetBalances, assetBorrows, cryptoCurrencies] = await Promise.all([
    selectAssetBalances({ database, accountingPeriodId: accountingPeriod.id }),
    selectAssetBorrows({ database, accountingPeriodId: accountingPeriod.id }),
    selectCryptoCurrencies({ database }),
  ])

  const assetValues = await generateAssetValues({
    cryptoCurrencyDataSource,
    accountingPeriod,
    assetBalances,
    assetBorrows,
    cryptoCurrencies,
    fiat,
  })

  // asset value data を保存
  await upsertAssetValues({
    database,
    accountingPeriodId: accountingPeriod.id,
    data: assetValues,
  })
}
