import { createBigDecimal } from '@0xtorch/big-decimal'
import type { CryptoCurrency } from '@0xtorch/core'
import type { cryptoCurrencyTable } from '../schema'

export const parseToCryptoCurrency = (
  data: typeof cryptoCurrencyTable.$inferSelect,
): CryptoCurrency => ({
  icon: data.icon ?? undefined,
  symbol: data.symbol,
  type: 'CryptoCurrency',
  id: data.id,
  name: data.name,
  market: {
    coingeckoId: data.coingeckoId ?? undefined,
    marketCapUsd: data.marketCapUsd ?? undefined,
  },
  priceDatasourceId: data.priceDatasourceId ?? undefined,
  updatedAt: data.updatedAt,
})
