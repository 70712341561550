import { getPortfolio } from '@/api/getPortfolio'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { fiatCurrencies } from '@/constants/fiatCurrency'
import { assetApiEndpoint } from '@/environment'
import type { PortfolioPageData } from '@/types'
import {
  createCalculateTransactionPlByWacbWorker,
  createCreateAssetBalancesForWacbWorker,
  createCreateTransactionsWorker,
} from '@/workers'
import { generateAccountingTransactionList } from '@pkg/analyze'
import { createCryptoCurrencyDataSource } from '@pkg/price-datasource'
import { MixIcon, ReloadIcon } from '@radix-ui/react-icons'
import { terminate } from '@shopify/web-worker'
import { useQuery } from '@tanstack/react-query'
import { useRouterState } from '@tanstack/react-router'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { isCalculatingAtom } from '../../states/calculate'

const UpdatingTransactionToast = (
  props: Pick<PortfolioPageData, 'readFromDatabase' | 'writeToDatabase'>,
) => {
  const { readFromDatabase, writeToDatabase } = props
  const { location } = useRouterState()

  const { data: portfolio, refetch } = useQuery({
    queryKey: ['portfolio', location.search.id],
    queryFn: async () => getPortfolio({ readFromDatabase, writeToDatabase }),
  })

  const [isCalculating, setIsCalculating] = useAtom(isCalculatingAtom)
  const calculate = async () => {
    if (portfolio === undefined) {
      return
    }
    if (writeToDatabase === undefined) {
      return
    }
    setIsCalculating(true)
    const createTransactionsWorker = createCreateTransactionsWorker()
    const calculateTransactionPlByWacbWorker =
      createCalculateTransactionPlByWacbWorker()
    const createAssetBalancesForWacbWorker =
      createCreateAssetBalancesForWacbWorker()
    try {
      const fiatCurrency = fiatCurrencies.find(
        ({ id }) => portfolio.fiat === id,
      )
      if (fiatCurrency === undefined) {
        throw new Error(`Fiat currency not found: ${portfolio.fiat}`)
      }
      const cryptoCurrencyDataSource =
        createCryptoCurrencyDataSource(assetApiEndpoint)
      await writeToDatabase((database) =>
        generateAccountingTransactionList({
          database,
          fiatCurrency,
          fiatCurrencyList: fiatCurrencies,
          cryptoCurrencyDataSource,
          createTransactions: (parameters) =>
            createTransactionsWorker.createTransactions(parameters),
          createAssetBalancesForWacb: (parameters) =>
            createAssetBalancesForWacbWorker.createAssetBalancesForWacb(
              parameters,
            ),
          calculateTransactionPlByWacb: (parameters) =>
            calculateTransactionPlByWacbWorker.calculateTransactionPlByWacb(
              parameters,
            ),
        }),
      )
      await refetch()
    } catch (error) {
      console.error(error)
    } finally {
      setIsCalculating(false)
      terminate(createTransactionsWorker)
      terminate(calculateTransactionPlByWacbWorker)
      terminate(createAssetBalancesForWacbWorker)
    }
  }

  return (
    <UI
      isHidden={portfolio === undefined || portfolio.isTxGenerated}
      isCalculating={isCalculating}
      onCalculate={calculate}
    />
  )
}

const UI = ({
  isHidden,
  isCalculating,
  onCalculate,
}: {
  readonly isHidden: boolean
  readonly isCalculating: boolean
  readonly onCalculate: () => void
}) => {
  if (isHidden) {
    return <></>
  }

  const { t } = useTranslation()

  return (
    <div className="fixed bottom-0 left-0 z-50 p-4">
      <Card>
        <div className="flex w-full items-center justify-between space-x-4 px-4 py-4">
          <p className="font-bold text-muted-foreground text-sm">
            {t('Profit/Loss calculation is incompleted')}
          </p>
          <Button disabled={isCalculating} onClick={onCalculate}>
            {isCalculating ? (
              <ReloadIcon className="mr-1.5 animate-spin" />
            ) : (
              <MixIcon className="mr-1.5" />
            )}
            <span>{t('Calculate')}</span>
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default UpdatingTransactionToast
