import type { AssetBalance } from '@0xtorch/accounting'
import { createBigDecimal } from '@0xtorch/big-decimal'
import type { assetBalanceTable } from '../schema'
import type { AssetBalances } from '../types'

export const parseToAssetBalances = (
  data: readonly (typeof assetBalanceTable.$inferSelect)[],
): readonly AssetBalances[] => {
  const assetBalances = new Map<string, AssetBalance[]>()
  for (const row of data) {
    const balance: AssetBalance = {
      value: createBigDecimal(row.value),
      amount: createBigDecimal(row.amount),
    }
    const existedBalances = assetBalances.get(row.asset)
    if (existedBalances === undefined) {
      assetBalances.set(row.asset, [balance])
    } else {
      existedBalances.push(balance)
      assetBalances.set(row.asset, existedBalances)
    }
  }
  return [...assetBalances].map(([asset, balances]) => ({
    asset,
    balances,
  }))
}
