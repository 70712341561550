import {
  cannotUseFetchAddressDataChainIdSet,
  evmChains,
} from '@/constants/evmChain'
import { evmTokenApiEndpoint } from '@/environment'
import type { RunToDatabase } from '@/types'
import {
  createAnalyzeEvmTransactionWorker,
  createAnalyzeSolanaTransactionWorker,
  createFetchBitbankApiDataWorker,
  createFetchBitgetApiDataWorker,
  createFetchBybitApiDataWorker,
  createFetchEvmAddressDataWorker,
  createFetchEvmTransactionListWorker,
  createFetchSolanaAddressDataWorker,
  createFetchSolanaTransactionsWorker,
} from '@/workers'
import type { FiatCurrency } from '@0xtorch/core'
import {
  analyzeEvmAccountList,
  analyzeExchangeAccounts,
  analyzeSolanaAccounts,
} from '@pkg/analyze'
import { accountCategoryList } from '@pkg/basic'
import type {
  Account,
  AccountEvm,
  AccountExchange,
  AccountExchangeApi,
  AccountSolana,
} from '@pkg/database-portfolio'
import {
  selectAccountExchangeApis,
  selectAccounts,
  selectCryptoCurrencies,
  selectPortfolio,
} from '@pkg/database-portfolio'
import { createCryptoCurrencyDataSource } from '@pkg/price-datasource'
import { terminate } from '@shopify/web-worker'
import type { Setter } from 'jotai'
import {
  saveAnalyzedEvmChainTransactionProgressStatus,
  saveEndEvmChainAnalyzeProgressStatus,
  saveFetchedEvmChainAccountDataProgressStatus,
  saveFetchedEvmChainTransactionProgressStatus,
  saveStartAnalyzeEvmTransactionListProgressStatus,
  saveStartEvmChainAnalyzeProgressStatus,
} from '../states/evm'
import {
  saveCalledExchangeApiProgressStatus,
  saveEndExchangeAnalyzeProgressStatus,
  saveFetchedExchangeApiCallSizeProgressStatus,
  saveFetchedExchangeApiProgressStatus,
  saveStartExchangeAnalyzeProgressStatus,
} from '../states/exchange'
import {
  saveAnalyzedSolanaTransactionProgressStatus,
  saveEndSolanaAnalyzeProgressStatus,
  saveFetchedSolanaAccountDataProgressStatus,
  saveFetchedSolanaTransactionProgressStatus,
  saveStartAnalyzeSolanaTransactionListProgressStatus,
  saveStartSolanaAnalyzeProgressStatus,
} from '../states/solana'

type AnalyzeAccountListParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  readonly targetAccountIdList: readonly number[] | undefined
  readonly fiatCurrencyList: readonly FiatCurrency[]
  readonly assetApiEndpoint: string
  readonly datasourceApiEndpoint: string
  readonly proxyApiEndpoint: string
  readonly splTokenApiEndpoint: string
  readonly solanaRpcEndpoints: readonly string[]
  readonly set: Setter
}

export const analyzeAccountList = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  targetAccountIdList,
  fiatCurrencyList,
  assetApiEndpoint,
  datasourceApiEndpoint,
  proxyApiEndpoint,
  splTokenApiEndpoint,
  solanaRpcEndpoints,
  set,
}: AnalyzeAccountListParameters) => {
  const cryptoCurrencyDataSource =
    createCryptoCurrencyDataSource(assetApiEndpoint)

  // DB から account list , portfolio, crypto currency list 取得
  const [portfolio, accountList, accountExchangeApiList, cryptoCurrencyList] =
    await Promise.all([
      readFromDatabase((database) => selectPortfolio({ database })),
      readFromDatabase((database) => selectAccounts({ database })),
      readFromDatabase((database) => selectAccountExchangeApis({ database })),
      readFromDatabase((database) =>
        selectCryptoCurrencies({
          database,
        }),
      ),
    ])
  if (portfolio === undefined) {
    throw new Error('portfolio not found')
  }
  const fiatCurrency = fiatCurrencyList.find(({ id }) => id === portfolio.fiat)
  if (fiatCurrency === undefined) {
    throw new Error(`fiat currency not found: ${portfolio.fiat}`)
  }
  const startTime = portfolio.startTime?.getTime() ?? 0

  // target account id に合致する account を category 毎にまとめる / undefined の場合は全アカウント対象
  const targetAccountIdSet = new Set(targetAccountIdList)
  const targetAccountList =
    targetAccountIdList === undefined
      ? accountList
      : accountList.filter((account) => targetAccountIdSet.has(account.id))
  const {
    evmChainAccountList,
    exchangeCategoryAccountList,
    solanaAccountList,
  } = groupAccountListByCategory(targetAccountList, accountExchangeApiList)

  // category 毎に account action 生成・保存
  const fetchEvmAddressDataWorker = createFetchEvmAddressDataWorker()
  const fetchEvmTransactionListWorker = createFetchEvmTransactionListWorker()
  const analyzeEvmTransactionWorker = createAnalyzeEvmTransactionWorker()
  const fetchSolanaAddressDataWorker = createFetchSolanaAddressDataWorker()
  const fetchSolanaTransactionsWorker = createFetchSolanaTransactionsWorker()
  const analyzeSolanaTransactionWorker = createAnalyzeSolanaTransactionWorker()
  try {
    await Promise.all([
      ...evmChainAccountList.map((evmChainAccount) => {
        const chain = evmChains.find(({ id }) => id === evmChainAccount.chainId)
        if (chain === undefined) {
          throw new Error(`chain not found: ${evmChainAccount.chainId}`)
        }
        return analyzeEvmAccountList({
          readFromDatabase,
          writeToDatabase,
          getToken,
          startTime,
          chain,
          accountList: evmChainAccount.accountList,
          allEvmAccountAddressList: accountList
            .filter(
              (account): account is AccountEvm =>
                account.type === 'evm' &&
                account.evmChainId === evmChainAccount.chainId,
            )
            .map((account) => account.evmAddress),
          fiatCurrency,
          cryptoCurrencyDataSource,
          assetApiEndpoint,
          datasourceApiEndpoint,
          evmTokenApiEndpoint,
          cannotUseFetchAddressData: cannotUseFetchAddressDataChainIdSet.has(
            evmChainAccount.chainId,
          ),
          fetchAddressData: fetchEvmAddressDataWorker.fetchEvmAddressData,
          fetchTransactionList:
            fetchEvmTransactionListWorker.fetchEvmTransactionList,
          analyzeTransaction: analyzeEvmTransactionWorker.analyzeEvmTransaction,
          onStart: (accountIdSet) => {
            saveStartEvmChainAnalyzeProgressStatus({
              chain,
              accountIdSet,
              set,
            })
          },
          onEnd: () => {
            saveEndEvmChainAnalyzeProgressStatus({
              chain,
              set,
            })
          },
          onFetchedAccount: (accountId) => {
            saveFetchedEvmChainAccountDataProgressStatus({
              chain,
              accountId,
              set,
            })
          },
          onStartAnalyzeTransaction: (transactionHashSet) => {
            saveStartAnalyzeEvmTransactionListProgressStatus({
              chain,
              transactionHashSet,
              set,
            })
          },
          onFetchedTransaction: (transactionHash) => {
            saveFetchedEvmChainTransactionProgressStatus({
              chain,
              transactionHash,
              set,
            })
          },
          onAnalyzedTransaction: (transactionHash) => {
            saveAnalyzedEvmChainTransactionProgressStatus({
              chain,
              transactionHash,
              set,
            })
          },
        })
      }),
      ...exchangeCategoryAccountList.map((exchangeCategoryAccount) => {
        const category = accountCategoryList.find(
          ({ id }) => id === exchangeCategoryAccount.category,
        )
        if (
          category === undefined ||
          category.type !== 'exchange' ||
          category.exchangeApis === undefined ||
          category.exchangeApis.length === 0
        ) {
          return undefined
        }
        const onStart = (
          accountApiIdSetList: {
            readonly accountId: number
            readonly apiIdSet: Set<string>
          }[],
        ) => {
          saveStartExchangeAnalyzeProgressStatus({
            category: category.id,
            accountApiIdSetList,
            set,
          })
        }
        const onEnd = () => {
          saveEndExchangeAnalyzeProgressStatus({
            category: category.id,
            set,
          })
        }
        const onFetchedCallSize = (values: {
          readonly accountId: number
          readonly apiId: string
          readonly size: number
        }) => {
          saveFetchedExchangeApiCallSizeProgressStatus({
            category: category.id,
            ...values,
            set,
          })
        }
        const onCalled = (values: {
          readonly accountId: number
          readonly apiId: string
        }) => {
          saveCalledExchangeApiProgressStatus({
            category: category.id,
            ...values,
            set,
          })
        }
        const onFetchedApi = (values: {
          readonly accountId: number
          readonly apiId: string
        }) => {
          saveFetchedExchangeApiProgressStatus({
            category: category.id,
            ...values,
            set,
          })
        }
        switch (category.id) {
          case 'binance': {
            // TODO 一時的にコメントアウト
            throw new Error(`unsupported exchange api category: ${category.id}`)
            // const worker = createFetchBinanceApiDataWorker()
            // return analyzeExchangeAccounts({
            //   readFromDatabase,
            //   writeToDatabase,
            //   getToken,
            //   startTime,
            //   category: exchangeCategoryAccount.category,
            //   apiIdList: category.exchangeApis,
            //   accountList: exchangeCategoryAccount.accountList,
            //   cryptoCurrencyList,
            //   fiatCurrency,
            //   fiatCurrencyList,
            //   cryptoCurrencyDataSource,
            //   proxyApiEndpoint,
            //   saveApiData: worker.fetchBinanceApiData,
            //   onStart,
            //   onEnd,
            //   onFetchedCallSize,
            //   onCalled,
            //   onFetchedApi,
            // }).finally(() => {
            //   terminate(worker)
            // })
          }
          case 'bitbank': {
            const worker = createFetchBitbankApiDataWorker()
            return analyzeExchangeAccounts({
              readFromDatabase,
              writeToDatabase,
              getToken,
              startTime,
              category: exchangeCategoryAccount.category,
              apiIdList: category.exchangeApis,
              accountList: exchangeCategoryAccount.accountList,
              cryptoCurrencyList,
              fiatCurrency,
              fiatCurrencyList,
              cryptoCurrencyDataSource,
              proxyApiEndpoint,
              saveApiData: worker.saveBitbankApiData,
              onStart,
              onEnd,
              onFetchedCallSize,
              onCalled,
              onFetchedApi,
            }).finally(() => {
              terminate(worker)
            })
          }
          case 'bitget': {
            const worker = createFetchBitgetApiDataWorker()
            return analyzeExchangeAccounts({
              readFromDatabase,
              writeToDatabase,
              getToken,
              startTime,
              category: exchangeCategoryAccount.category,
              apiIdList: category.exchangeApis,
              accountList: exchangeCategoryAccount.accountList,
              cryptoCurrencyList,
              fiatCurrency,
              fiatCurrencyList,
              cryptoCurrencyDataSource,
              proxyApiEndpoint,
              saveApiData: worker.saveBitgetApiData,
              onStart,
              onEnd,
              onFetchedCallSize,
              onCalled,
              onFetchedApi,
            }).finally(() => {
              terminate(worker)
            })
          }
          case 'bybit': {
            const worker = createFetchBybitApiDataWorker()
            return analyzeExchangeAccounts({
              readFromDatabase,
              writeToDatabase,
              getToken,
              startTime,
              category: exchangeCategoryAccount.category,
              apiIdList: category.exchangeApis,
              accountList: exchangeCategoryAccount.accountList,
              cryptoCurrencyList,
              fiatCurrency,
              fiatCurrencyList,
              cryptoCurrencyDataSource,
              proxyApiEndpoint,
              saveApiData: worker.saveBybitApiData,
              onStart,
              onEnd,
              onFetchedCallSize,
              onCalled,
              onFetchedApi,
            }).finally(() => {
              terminate(worker)
            })
          }
          case 'okx': {
            // TODO 一時的にコメントアウト
            throw new Error(`unsupported exchange api category: ${category.id}`)
            // const worker = createFetchOkxApiDataWorker()
            // return analyzeExchangeAccounts({
            //   readFromDatabase,
            //   writeToDatabase,
            //   getToken,
            //   startTime,
            //   category: exchangeCategoryAccount.category,
            //   apiIdList: category.exchangeApis,
            //   accountList: exchangeCategoryAccount.accountList,
            //   cryptoCurrencyList,
            //   fiatCurrency,
            //   fiatCurrencyList,
            //   cryptoCurrencyDataSource,
            //   proxyApiEndpoint,
            //   saveApiData: worker.fetchOkxApiData,
            //   onStart,
            //   onEnd,
            //   onFetchedCallSize,
            //   onCalled,
            //   onFetchedApi,
            // }).finally(() => {
            //   terminate(worker)
            // })
          }
          default: {
            throw new Error(`unsupported exchange api category: ${category.id}`)
          }
        }
      }),
      solanaAccountList.length > 0
        ? analyzeSolanaAccounts({
            readFromDatabase,
            writeToDatabase,
            getToken,
            startTime,
            accountList: solanaAccountList,
            allSolanaAccountAddresses: accountList
              .filter((account) => account.type === 'solana')
              .map((account) => account.solanaAddress),
            cryptoCurrencyList,
            fiatCurrency,
            cryptoCurrencyDataSource,
            rpcEndpoints: solanaRpcEndpoints,
            evmTokenApiEndpoint,
            splTokenApiEndpoint,
            assetApiEndpoint,
            fetchAddressData:
              fetchSolanaAddressDataWorker.fetchSolanaAddressData,
            fetchTransactions:
              fetchSolanaTransactionsWorker.fetchSolanaTransactions,
            analyzeTransaction:
              analyzeSolanaTransactionWorker.analyzeSolanaTransaction,
            onStart: (accountIdSet) => {
              saveStartSolanaAnalyzeProgressStatus({
                accountIdSet,
                set,
              })
            },
            onEnd: () => {
              saveEndSolanaAnalyzeProgressStatus({
                set,
              })
            },
            onFetchedAccount: (accountId) => {
              saveFetchedSolanaAccountDataProgressStatus({
                accountId,
                set,
              })
            },
            onStartAnalyzeTransaction: (transactionSignatureSet) => {
              saveStartAnalyzeSolanaTransactionListProgressStatus({
                transactionSignatureSet,
                set,
              })
            },
            onFetchedTransaction: (transactionSignature) => {
              saveFetchedSolanaTransactionProgressStatus({
                transactionSignature,
                set,
              })
            },
            onAnalyzedTransaction: (transactionSignature) => {
              saveAnalyzedSolanaTransactionProgressStatus({
                transactionSignature,
                set,
              })
            },
          })
        : undefined,
    ])
  } finally {
    terminate(fetchEvmAddressDataWorker)
    terminate(fetchEvmTransactionListWorker)
    terminate(analyzeEvmTransactionWorker)
    terminate(fetchSolanaAddressDataWorker)
    terminate(fetchSolanaTransactionsWorker)
    terminate(analyzeSolanaTransactionWorker)
  }
}

const groupAccountListByCategory = (
  accountList: readonly Account[],
  accountExchangeApiList: readonly AccountExchangeApi[],
): {
  readonly evmChainAccountList: readonly {
    readonly chainId: number
    readonly accountList: readonly AccountEvm[]
  }[]
  readonly exchangeCategoryAccountList: readonly {
    readonly category: string
    readonly accountList: readonly (AccountExchange & {
      readonly apiList: readonly AccountExchangeApi[]
    })[]
  }[]
  readonly solanaAccountList: readonly AccountSolana[]
} => {
  const mut_evmChainAccountList: {
    readonly chainId: number
    readonly accountList: AccountEvm[]
  }[] = []
  const mut_exchangeCategoryAccountList: {
    readonly category: string
    readonly accountList: (AccountExchange & {
      readonly apiList: readonly AccountExchangeApi[]
    })[]
  }[] = []
  const mut_solanaAccountList: AccountSolana[] = []

  for (const account of accountList) {
    switch (account.type) {
      case 'evm': {
        const mut_accountList = mut_evmChainAccountList.find(
          ({ chainId }) => chainId === account.evmChainId,
        )?.accountList
        if (mut_accountList === undefined) {
          mut_evmChainAccountList.push({
            chainId: account.evmChainId,
            accountList: [account],
          })
        } else {
          mut_accountList.push(account)
        }
        break
      }
      case 'exchange': {
        const mut_accountList = mut_exchangeCategoryAccountList.find(
          ({ category }) => category === account.category,
        )?.accountList
        if (mut_accountList === undefined) {
          mut_exchangeCategoryAccountList.push({
            category: account.category,
            accountList: [
              {
                ...account,
                apiList: accountExchangeApiList.filter(
                  (api) => api.accountId === account.id,
                ),
              },
            ],
          })
        } else {
          mut_accountList.push({
            ...account,
            apiList: accountExchangeApiList.filter(
              (api) => api.accountId === account.id,
            ),
          })
        }
        break
      }
      case 'service': {
        break
      }
      case 'solana': {
        mut_solanaAccountList.push(account)
        break
      }
    }
  }

  return {
    evmChainAccountList: mut_evmChainAccountList,
    exchangeCategoryAccountList: mut_exchangeCategoryAccountList,
    solanaAccountList: mut_solanaAccountList,
  }
}
