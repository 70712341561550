import type { CrossActionBundle, Loan } from '@0xtorch/accounting'
import { toStringBigDecimal } from '@0xtorch/big-decimal'
import { divideArrayIntoChunks } from '@pkg/basic'
import { and, between, eq, inArray } from 'drizzle-orm'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import {
  assetBalanceColumnCount,
  assetBorrowColumnCount,
  crossActionBundleColumnCount,
  crossActionBundleRelationColumnCount,
  loanBorrowColumnCount,
  loanBorrowWithDebtColumnCount,
  loanBorrowWithDebtPositionColumnCount,
  loanColumnCount,
  loanDepositColumnCount,
  loanDepositWithBondColumnCount,
  loanDepositWithBondPositionColumnCount,
  sqliteMaxHostParameterCount,
} from '../constants'
import { parseCrossActionBundlesToInsertData } from '../parsers/crossActionBundle'
import { parseLoansToInsertData } from '../parsers/loan'
import {
  accountingTransactionTable,
  accountingTransactionTransferTable,
  actionTable,
  assetBalanceTable,
  assetBorrowTable,
  crossActionBundleRelationTable,
  crossActionBundleTable,
  loanBorrowTable,
  loanBorrowWithDebtPositionTable,
  loanBorrowWithDebtTable,
  loanDepositTable,
  loanDepositWithBondPositionTable,
  loanDepositWithBondTable,
  loanTable,
} from '../schema'
import type {
  AccountingPeriod,
  AssetBalances,
  AssetBorrows,
  DatabaseWithTransaction,
} from '../types'
import { getMaxInsertRowCount } from '../utils'

type SaveAccountingPeriodDataParameters = {
  database: DatabaseWithTransaction
  accountingPeriod: AccountingPeriod
  loans: readonly Loan[]
  crossActionBundles: readonly CrossActionBundle[]
  assetBalances: readonly AssetBalances[]
  assetBorrows: readonly AssetBorrows[]
}

export const saveAccountingPeriodData = async ({
  database: { database, transaction },
  accountingPeriod,
  loans,
  crossActionBundles,
  assetBalances,
  assetBorrows,
}: SaveAccountingPeriodDataParameters) => {
  // hasInsufficientAmountTx, hasUnknownPriceTx を　true に更新する対象となる actionId 抽出
  const [hasInsufficientAmountTxActionIds, hasUnknownPriceTxActionIds] =
    await Promise.all([
      database
        .selectDistinct({ id: accountingTransactionTable.actionId })
        .from(accountingTransactionTable)
        .where(
          and(
            eq(
              accountingTransactionTable.accountingPeriodId,
              accountingPeriod.id,
            ),
            eq(accountingTransactionTable.category, 'insufficient'),
          ),
        ),
      database
        .selectDistinct({ id: accountingTransactionTable.actionId })
        .from(accountingTransactionTable)
        .leftJoin(
          accountingTransactionTransferTable,
          eq(
            accountingTransactionTable.id,
            accountingTransactionTransferTable.transactionId,
          ),
        )
        .where(
          and(
            eq(
              accountingTransactionTable.accountingPeriodId,
              accountingPeriod.id,
            ),
            eq(accountingTransactionTransferTable.zeroCompleted, true),
          ),
        ),
    ])

  const {
    loan: loanSqls,
    loanBorrow: loanBorrowSqls,
    loanBorrowWithDebt: loanBorrowWithDebtSqls,
    loanBorrowWithDebtPosition: loanBorrowWithDebtPositionSqls,
    loanDeposit: loanDepositSqls,
    loanDepositWithBond: loanDepositWithBondSqls,
    loanDepositWithBondPosition: loanDepositWithBondPositionSqls,
  } = parseLoansToInsertData(accountingPeriod.id, loans)
  const {
    crossActionBundle: crossActionBundleSqls,
    crossActionBundleRelation: crossActionBundleRelationSqls,
  } = parseCrossActionBundlesToInsertData(
    accountingPeriod.id,
    crossActionBundles,
  )
  const assetBalanceSqls = assetBalances.flatMap((item) =>
    item.balances.map(
      (balance, order): SQLiteInsertValue<typeof assetBalanceTable> => ({
        accountingPeriodId: accountingPeriod.id,
        asset: item.asset,
        order,
        value: toStringBigDecimal(balance.value),
        amount: toStringBigDecimal(balance.amount),
      }),
    ),
  )
  const assetBorrowSqls = assetBorrows.flatMap((item) =>
    item.borrows.map(
      (borrow, order): SQLiteInsertValue<typeof assetBorrowTable> => ({
        accountingPeriodId: accountingPeriod.id,
        asset: item.asset,
        order,
        value: toStringBigDecimal(borrow.value),
        amount: toStringBigDecimal(borrow.amount),
        borrowedValue: toStringBigDecimal(borrow.borrowedValue),
        borrowedAmount: toStringBigDecimal(borrow.borrowedAmount),
      }),
    ),
  )

  await transaction([
    (tx) => [
      // loan 保存
      ...divideArrayIntoChunks(
        loanSqls,
        getMaxInsertRowCount(loanColumnCount),
      ).map((chunk) => tx.insert(loanTable).values([...chunk])),
      // cross action bundle 保存
      ...divideArrayIntoChunks(
        crossActionBundleSqls,
        getMaxInsertRowCount(crossActionBundleColumnCount),
      ).map((chunk) => tx.insert(crossActionBundleTable).values([...chunk])),
      // asset balance 保存
      ...divideArrayIntoChunks(
        assetBalanceSqls,
        getMaxInsertRowCount(assetBalanceColumnCount),
      ).map((chunk) => tx.insert(assetBalanceTable).values([...chunk])),
      // asset borrow 保存
      ...divideArrayIntoChunks(
        assetBorrowSqls,
        getMaxInsertRowCount(assetBorrowColumnCount),
      ).map((chunk) => tx.insert(assetBorrowTable).values([...chunk])),
      // action generatedTx 更新
      tx
        .update(actionTable)
        .set({
          generatedTx: true,
        })
        .where(
          between(
            actionTable.timestamp,
            new Date(accountingPeriod.start),
            new Date(accountingPeriod.end),
          ),
        ),
    ],
    (tx) => [
      // loan 関連 table 保存
      ...divideArrayIntoChunks(
        loanBorrowWithDebtPositionSqls,
        getMaxInsertRowCount(loanBorrowWithDebtPositionColumnCount + 1),
      ).map((chunk) =>
        tx.insert(loanBorrowWithDebtPositionTable).values([...chunk]),
      ),
      ...divideArrayIntoChunks(
        loanBorrowWithDebtSqls,
        getMaxInsertRowCount(loanBorrowWithDebtColumnCount + 1),
      ).map((chunk) => tx.insert(loanBorrowWithDebtTable).values([...chunk])),
      ...divideArrayIntoChunks(
        loanBorrowSqls,
        getMaxInsertRowCount(loanBorrowColumnCount + 1),
      ).map((chunk) => tx.insert(loanBorrowTable).values([...chunk])),
      ...divideArrayIntoChunks(
        loanDepositWithBondPositionSqls,
        getMaxInsertRowCount(loanDepositWithBondPositionColumnCount + 1),
      ).map((chunk) =>
        tx.insert(loanDepositWithBondPositionTable).values([...chunk]),
      ),
      ...divideArrayIntoChunks(
        loanDepositWithBondSqls,
        getMaxInsertRowCount(loanDepositWithBondColumnCount + 1),
      ).map((chunk) => tx.insert(loanDepositWithBondTable).values([...chunk])),
      ...divideArrayIntoChunks(
        loanDepositSqls,
        getMaxInsertRowCount(loanDepositColumnCount + 1),
      ).map((chunk) => tx.insert(loanDepositTable).values([...chunk])),
      // cross action bundle relation 保存
      ...divideArrayIntoChunks(
        crossActionBundleRelationSqls,
        getMaxInsertRowCount(crossActionBundleRelationColumnCount + 1),
      ).map((chunk) =>
        tx.insert(crossActionBundleRelationTable).values([...chunk]),
      ),
      // action hasInsufficientAmountTx 更新
      ...divideArrayIntoChunks(
        hasInsufficientAmountTxActionIds,
        sqliteMaxHostParameterCount - 1,
      ).map((chunk) =>
        tx
          .update(actionTable)
          .set({
            hasInsufficientAmountTx: true,
          })
          .where(
            inArray(
              actionTable.id,
              chunk.map(({ id }) => id),
            ),
          ),
      ),
    ],
    (tx) => [
      // action hasUnknownPriceTx 更新
      ...divideArrayIntoChunks(
        hasInsufficientAmountTxActionIds,
        sqliteMaxHostParameterCount - 1,
      ).map((chunk) =>
        tx
          .update(actionTable)
          .set({
            hasUnknownPriceTx: true,
          })
          .where(
            inArray(
              actionTable.id,
              chunk.map(({ id }) => id),
            ),
          ),
      ),
    ],
  ])
}
