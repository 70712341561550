import type {
  AccountCategory,
  AccountCategoryBlockchain,
  AccountCategoryExchange,
  AccountCategoryService,
  AccountCategoryWallet,
} from '../types/accountCategory'
import { blankApiKeyEvmChainList } from './evmChain'

export const exchangeCategoryList: readonly AccountCategoryExchange[] = [
  {
    id: 'binance',
    type: 'exchange',
    name: 'Binance',
    icon: '/images/binance.svg',
    url: 'https://www.binance.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    // TODO 一時的にコメントアウト
    // exchangeApis: ['deposit', 'withdraw'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1WELjWy-wLZJ90qNIqoIiYNdMImToxIjPphT2ShWoHn4/edit?usp=sharing',
    csvs: ['binance-convert', 'binance-withdraw'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1Ln-JZx1WPDHX4vGfulgDpdtSjd56YfY_mVrRCjyzPf0/edit?usp=sharing',
  },
  {
    id: 'bitbank',
    type: 'exchange',
    name: 'bitbank',
    icon: '/images/bitbank.svg',
    url: 'https://bitbank.cc',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApis: ['deposit', 'withdrawal', 'trade'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1urhxiZBVWL9CFx47DYV_Udpq3rFStBc3WfUTirESKIQ/edit?usp=sharing',
    csvs: [
      'bitbank-dealer',
      'bitbank-spot-trade',
      'bitbank-withdrawal-btc',
      'bitbank-withdrawal-eth',
      'bitbank-withdrawal-jpy',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1iHhqqKr8ER0uF1wB3qvOnXfWuCNUEwO2ZhBMWcpONJ8/edit?usp=sharing',
  },
  {
    id: 'bitget',
    type: 'exchange',
    name: 'Bitget',
    icon: '/images/bitget.svg',
    url: 'https://www.bitget.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
    exchangeApis: ['deposit', 'withdrawal'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1bnZBgRHZkDFYBaEYNJ1qj8An-sE_HqhwnU05Ivdr_rI/edit?usp=sharing',
    csvs: ['bitget-spot-trade-jst'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1r2RhYbAGYOvlOw4t_N3IH43GReNhhq94FMHLS6yhEqs/edit?usp=sharing',
  },
  {
    id: 'bitflyer',
    type: 'exchange',
    name: 'bitFlyer',
    icon: '/images/bitflyer.svg',
    url: 'https://bitflyer.com',
    csvs: ['bitflyer-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1KCPTSI4SSxwrqkQt4VwrmzB-vGaWjzERIlbnSm6nbA8/edit?usp=sharing',
  },
  {
    id: 'bybit',
    type: 'exchange',
    name: 'Bybit',
    icon: '/images/bybit.svg',
    url: 'https://www.bybit.com',
    // exchangeApiKey: true,
    // exchangeApiSecret: true,
    // exchangeApis: ['deposit', 'withdrawal'],
    // apiGuideUrl:
    // 'https://docs.google.com/document/d/1zVXXFOnZKQSh3qcqXsRoYIHzzBdGkli8DsYANyiNAwc/edit?usp=sharing',
    csvs: ['mexc-spot-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1WqEMsHHb-Z_2YdfLqdlG9Fcbfb3dGFrjaNvkQGbWrWA/edit?usp=sharing',
  },
  {
    id: 'coincheck',
    type: 'exchange',
    name: 'Coincheck',
    icon: '/images/coincheck.svg',
    url: 'https://coincheck.com',
    csvs: ['coincheck-standard'],
    csvGuideUrl:
      'https://docs.google.com/document/d/15Tiw7KKqcAAagJGAHi3Haiw6FoMMjONSakYFtEmB294/edit?usp=sharing',
  },
  {
    id: 'ftx-japan',
    type: 'exchange',
    name: 'FTX Japan',
    icon: '/images/ftx-japan.svg',
    url: 'https://www.liquid.com/ja',
    csvs: ['ftx-japan-pdf'],
  },
  {
    id: 'liquid',
    type: 'exchange',
    name: 'Liquid by Quoine',
    icon: '/images/liquid.svg',
    url: 'https://www.liquid.com',
    csvs: ['liquid-trade'],
  },
  {
    id: 'mexc',
    type: 'exchange',
    name: 'MEXC',
    icon: '/images/mexc.svg',
    url: 'https://www.mexc.com',
    csvs: ['mexc-spot-trade', 'mexc-future-ja-jst'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1TbmveO9WbhVldHH0iw2hh2zRS9IJ4WM8YzCAT9dERKw/edit?usp=sharing',
  },
  {
    id: 'okj',
    type: 'exchange',
    name: 'OKJ (OKCoin Japan)',
    icon: '/images/okj.svg',
    url: 'https://www.okcoin.jp',
    csvs: ['okj-trade', 'okj-deposit-withdrawal'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1IbIvoURiQxTacdpn_KY9o2npFVDqFhp5aVsCqktj6eA/edit?usp=sharing',
  },
  {
    id: 'okx',
    type: 'exchange',
    name: 'OKX',
    icon: '/images/okx.svg',
    url: 'https://www.okx.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
    exchangeApis: ['deposit', 'withdrawal', 'spot-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1VivP85ACdUPc_mbKvwUk6XanisHvPtbjcUP7-Bayw0c/edit?usp=sharing',
  },
  {
    id: 'zaif',
    type: 'exchange',
    name: 'Zaif',
    icon: '/images/zaif.svg',
    url: 'https://zaif.jp',
    csvs: ['zaif-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1KsIe11cGZJ84MRKzrTuWUQfYJOC35rty5vfzOC7q9TE/edit?usp=sharing',
  },
]

export const walletCategoryList: readonly AccountCategoryWallet[] = [
  {
    id: 'metamask',
    type: 'wallet',
    name: 'MetaMask',
    icon: '/images/metamask.svg',
    url: 'https://metamask.io',
  },
  {
    id: 'rabby',
    type: 'wallet',
    name: 'Rabby',
    icon: '/images/rabby.svg',
    url: 'https://rabby.io',
  },
  {
    id: 'phantom',
    type: 'wallet',
    name: 'Phantom',
    icon: '/images/phantom.svg',
    url: 'https://phantom.app',
  },
]

export const serviceCategoryList: readonly AccountCategoryService[] = [
  {
    id: 'cryptorch',
    type: 'service',
    name: 'Cryptorch',
    icon: 'icon.svg',
    url: 'https://portfolio.cryptorch.dev',
    csvs: ['cryptorch-transaction-jp'],
  },
  {
    id: 'cryptact',
    type: 'service',
    name: 'cryptact',
    icon: '/images/cryptact.svg',
    url: 'https://www.cryptact.com',
    csvs: ['cryptact-ledger-ja'],
  },
]

export const blockchainCategoryList: readonly AccountCategoryBlockchain[] = [
  {
    id: 'solana',
    type: 'blockchain',
    name: 'Solana',
    icon: '/images/solana.svg',
    url: 'https://solana.com',
  } satisfies AccountCategoryBlockchain,
  ...blankApiKeyEvmChainList.map(
    (chain): AccountCategoryBlockchain => ({
      id: chain.network,
      type: 'blockchain',
      name: chain.name,
      icon: `/images/${chain.network}.svg`,
      url: chain.explorer.baseUrl,
    }),
  ),
].toSorted((a, b) => a.name.localeCompare(b.name))

export const accountCategoryList: readonly AccountCategory[] = [
  ...exchangeCategoryList,
  ...walletCategoryList,
  ...serviceCategoryList,
  ...blockchainCategoryList,
]
