import {
  type Action,
  type CryptoCurrencyDataSource,
  type FiatCurrency,
  setActionPrices,
} from '@0xtorch/core'
import type { Chain, Hex, LowerHex, TransactionDecoded } from '@0xtorch/evm'
import { divideArrayIntoChunks } from '@pkg/basic'
import type { AccountEvm } from '@pkg/database-portfolio'
import {
  getEvmTxIndexes,
  ignoreLockedActionEvmTxs,
  saveEvmTxAndActions,
  saveEvmTxIndexes,
} from '@pkg/database-portfolio'
import { createEvmAnalyzeDataSource } from './createEvmAnalyzeDataSource'
import { createEvmDatasourceCache } from './createEvmDatasourceCache'
import type {
  AnalyzeEvmTransactionParameters,
  FetchEvmAddressDataParameters,
  FetchEvmAddressDataReturnTypes,
  FetchEvmTransactionListParameters,
  FetchEvmTransactionListReturnTypes,
  RunToDatabase,
} from './types'

const mut_analyzingEvmAccountListMap: {
  [chainId: number]: AccountEvm[] | undefined
} = {}
const mut_waitingEvmAccountListMap: {
  [chainId: number]: AccountEvm[] | undefined
} = {}

type AnalyzeEvmAccountListParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  startTime: number
  chain: Chain
  accountList: readonly AccountEvm[]
  allEvmAccountAddressList: readonly LowerHex[]
  fiatCurrency: FiatCurrency
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  datasourceApiEndpoint: string
  evmTokenApiEndpoint: string
  assetApiEndpoint: string
  cannotUseFetchAddressData: boolean
  fetchAddressData: (
    parameters: FetchEvmAddressDataParameters,
  ) => Promise<FetchEvmAddressDataReturnTypes>
  fetchTransactionList: (
    parameters: FetchEvmTransactionListParameters,
  ) => Promise<FetchEvmTransactionListReturnTypes>
  analyzeTransaction: (parameters: AnalyzeEvmTransactionParameters) => Promise<{
    readonly transaction: TransactionDecoded
    readonly actions: readonly Action[]
  }>
  onStart: (accountIdSet: Set<number>) => void
  onEnd: () => void
  onFetchedAccount: (accountId: number) => void
  onStartAnalyzeTransaction: (transactionHashSet: Set<Hex>) => void
  onFetchedTransaction: (transactionHash: Hex) => void
  onAnalyzedTransaction: (transactionHash: Hex) => void
}

export const analyzeEvmAccountList = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  startTime,
  chain,
  accountList,
  allEvmAccountAddressList,
  fiatCurrency,
  cryptoCurrencyDataSource,
  datasourceApiEndpoint,
  evmTokenApiEndpoint,
  assetApiEndpoint,
  cannotUseFetchAddressData,
  fetchAddressData,
  fetchTransactionList,
  analyzeTransaction,
  onStart,
  onEnd,
  onFetchedAccount,
  onStartAnalyzeTransaction,
  onFetchedTransaction,
  onAnalyzedTransaction,
}: AnalyzeEvmAccountListParameters): Promise<void> => {
  if (mut_analyzingEvmAccountListMap[chain.id] === undefined) {
    mut_analyzingEvmAccountListMap[chain.id] = []
  }
  if (mut_waitingEvmAccountListMap[chain.id] === undefined) {
    mut_waitingEvmAccountListMap[chain.id] = []
  }

  // 解析中 evm account に含まれる account は除外する
  const filteredAccountList = accountList.filter(({ id }) =>
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    mut_analyzingEvmAccountListMap[chain.id]!.every(
      (account) => account.id !== id,
    ),
  )

  // 解析中 evm account が存在する場合、残った account を解析待ち evm account に入れて終了
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  if (mut_analyzingEvmAccountListMap[chain.id]!.length > 0) {
    for (const account of filteredAccountList) {
      if (
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        mut_waitingEvmAccountListMap[chain.id]!.every(
          ({ id }) => id !== account.id,
        )
      ) {
        const mut_waitingEvmAccountList =
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          mut_waitingEvmAccountListMap[chain.id]!
        mut_waitingEvmAccountList.push(account)
      }
    }
    return
  }

  // 解析中 evm account に入れて解析開始
  const mut_analyzingEvmAccountList =
    mut_analyzingEvmAccountListMap[chain.id] ?? []
  mut_analyzingEvmAccountList.push(...filteredAccountList)
  const accountIdSet = new Set(filteredAccountList.map(({ id }) => id))
  onStart(accountIdSet)

  try {
    // Account 毎の index & internal transaction data を取得
    for (const account of filteredAccountList) {
      const addressDataResult = cannotUseFetchAddressData
        ? undefined
        : await fetchAddressData({ chain, account, getToken })
      if (addressDataResult !== undefined) {
        const {
          internalTransactions,
          indexes,
          erc20Transfers,
          transactionCount,
        } = addressDataResult

        // erc20 transfer から erc20 token を取得
        const erc20TokenAddresses = new Set<LowerHex>()
        for (const { address, value } of erc20Transfers) {
          if (value === 0n) {
            continue
          }
          erc20TokenAddresses.add(address)
        }
        const datasource = createEvmAnalyzeDataSource({
          assetApiEndpoint,
          datasourceApiEndpoint,
          evmTokenApiEndpoint,
        })
        const erc20Tokens =
          erc20TokenAddresses.size === 0
            ? []
            : await datasource.getErc20Tokens({
                chainId: chain.id,
                addresses: [...erc20TokenAddresses],
              })

        // 取得した index data (internal transaction 含む) を DB に保存
        // start time 以前の tx の index data は除外
        // erc20 token, account erc20 token relation も DB に保存
        // account.evmToBlock も最新 block に更新
        await writeToDatabase((database) =>
          saveEvmTxIndexes({
            database,
            accountId: account.id,
            chainId: chain.id,
            ...addressDataResult,
            internalTransactions,
            txIndexes: indexes.filter(
              ({ timestamp }) => timestamp >= startTime,
            ),
            erc20Tokens,
            transactionCount,
          }),
        )
      }

      // account data 取得完了 callback
      onFetchedAccount(account.id)
    }

    // analyzed = false な tx index data 及び関連 internal transaction data を DB から取得
    const { indexes, internalTransactions } = await readFromDatabase(
      (database) =>
        getEvmTxIndexes({
          database,
          chainId: chain.id,
          analyzed: false,
        }),
    )

    // indexes.length が 0 の場合は終了
    if (indexes.length === 0) {
      return
    }

    // 該当 tx を source とする isLocked=true の action が存在する tx は除外する
    // 除外した tx について、 accountEvmIndexRelation.analyzed を true に更新
    const ignoreHashes = await writeToDatabase((database) =>
      ignoreLockedActionEvmTxs({
        database,
        chainId: chain.id,
        hashes: indexes.map(({ hash }) => hash),
      }),
    )
    const targetIndexes = indexes.filter(({ hash }) => !ignoreHashes.has(hash))

    // transaction 解析開始 callback
    onStartAnalyzeTransaction(new Set(targetIndexes.map(({ hash }) => hash)))

    // filter をかけた index data を 100件毎に分割して100件毎処理
    const chunkSize = 100
    for (const indexChunk of divideArrayIntoChunks(targetIndexes, chunkSize)) {
      // transaction detail 一括取得
      const { transactionList } = await fetchTransactionList({
        chain,
        indexes: indexChunk,
        internalTransactionList: internalTransactions,
        onFetched: onFetchedTransaction,
      })

      // datasource cache 作成
      try {
        await createEvmDatasourceCache({
          assetApiEndpoint,
          datasourceApiEndpoint,
          evmTokenApiEndpoint,
          chainId: chain.id,
          transactionList,
          accountAddresses: new Set(allEvmAccountAddressList),
        })
      } catch (error) {
        // TODO error toast を表示するようにする
        console.error('Failed to create datasource cache')
        console.error(error)
        continue
      }

      const decodedTxs: TransactionDecoded[] = []
      const actions: Action[] = []

      // tx 1件ずつ解析
      for (const transaction of transactionList) {
        try {
          const { transaction: decodedTx, actions: generatedActions } =
            await analyzeTransaction({
              chain,
              transaction,
              datasourceApiEndpoint,
              evmTokenApiEndpoint,
              assetApiEndpoint,
              accountAddressList: allEvmAccountAddressList,
            })
          decodedTxs.push(decodedTx)
          actions.push(...generatedActions)
        } catch (error) {
          // TODO error toast を表示するようにする
          console.error(
            'Failed to analyze evm transaction:',
            transaction.chainId,
            transaction.hash,
          )
          console.error(error)
          continue
        }
        // transaction 解析完了 callback
        onAnalyzedTransaction(transaction.hash)
      }

      // action が空の場合は次の chunk へ
      if (actions.length === 0) {
        continue
      }

      // 生成した action の価格設定
      const pricedActions = await setActionPrices({
        actions,
        fiat: fiatCurrency,
        dataSource: cryptoCurrencyDataSource,
      })

      // - rule に合わせて action 更新
      // - DB に action 保存 & 関連データ更新
      // portfolio.isTxGenerated を false に更新
      // accountEvmIndexRelation.analyzed を true に更新
      // decoded transaction を actionSource に保存
      // action を保存・ rule があれば rule に合わせて更新
      await writeToDatabase((database) =>
        saveEvmTxAndActions({
          database,
          chainId: chain.id,
          transactions: decodedTxs,
          actions: pricedActions,
        }),
      )
    }
  } catch (error) {
    console.warn(
      'analyzeEvmAccountList error on chain:',
      chain.name,
      '(id:',
      chain.id,
      ')',
    )
    console.warn(error)
    throw error
  } finally {
    // 処理完了時に解析中 evm account を空にする
    mut_analyzingEvmAccountListMap[chain.id] = []

    // 処理完了を callback
    onEnd()

    // 解析待ち evm account が存在する場合は account list を解析待ち evm account に変えて再度呼び出す
    // 解析待ち evm account は空にする
    const waitingEvmAccountList = mut_waitingEvmAccountListMap[chain.id]
    if (
      waitingEvmAccountList !== undefined &&
      waitingEvmAccountList.length > 0
    ) {
      const newAccountList = [...waitingEvmAccountList]
      mut_waitingEvmAccountListMap[chain.id] = []
      await analyzeEvmAccountList({
        readFromDatabase,
        writeToDatabase,
        getToken,
        startTime,
        chain,
        accountList: newAccountList,
        allEvmAccountAddressList: [
          ...new Set([
            ...allEvmAccountAddressList,
            ...newAccountList.map(({ evmAddress }) => evmAddress),
          ]),
        ],
        fiatCurrency,
        cryptoCurrencyDataSource,
        datasourceApiEndpoint,
        evmTokenApiEndpoint,
        assetApiEndpoint,
        cannotUseFetchAddressData,
        fetchAddressData,
        fetchTransactionList,
        analyzeTransaction,
        onStart,
        onEnd,
        onFetchedAccount,
        onStartAnalyzeTransaction,
        onFetchedTransaction,
        onAnalyzedTransaction,
      })
    }
  }
}
