import { createBigDecimal } from '@0xtorch/big-decimal'
import type { CryptoCurrency, FiatCurrency, Nft, Transfer } from '@0xtorch/core'
import type {
  actionTransferTable,
  cryptoCurrencyTable,
  nftTable,
} from '../schema'
import { parseToCryptoCurrency } from './cryptoCurrency'
import { parseToNft } from './nft'

export type ActionTransferData = typeof actionTransferTable.$inferSelect & {
  cryptoCurrencyTable: typeof cryptoCurrencyTable.$inferSelect | null
  nftTable: typeof nftTable.$inferSelect | null
}

export const parseToActionTransfer = (
  data: ActionTransferData,
  fiatCurrencyList: readonly FiatCurrency[],
  fiatCurrency: FiatCurrency,
): Transfer => ({
  from: data.from ?? undefined,
  to: data.to ?? undefined,
  price: data.price === null ? undefined : createBigDecimal(data.price),
  asset: parseToAsset(data, fiatCurrencyList),
  amount: createBigDecimal(data.amount),
  direction: data.direction,
})

const parseToAsset = (
  data: ActionTransferData,
  fiatCurrencyList: readonly FiatCurrency[],
): CryptoCurrency | FiatCurrency | Nft => {
  if (data.cryptoCurrencyTable !== null) {
    return parseToCryptoCurrency(data.cryptoCurrencyTable)
  }
  if (data.nftTable !== null) {
    return parseToNft(data.nftTable)
  }
  if (data.fiatId === null) {
    throw new Error('Invalid action transfer data')
  }
  const fiatCurrency = fiatCurrencyList.find((item) => item.id === data.fiatId)
  if (fiatCurrency === undefined) {
    throw new Error(`Invalid fiat currency id: ${data.fiatId}`)
  }
  return fiatCurrency
}
