import { eq, isNotNull } from 'drizzle-orm'
import {
  accountSolanaSignatureTable,
  accountTable,
  solanaTokenAccountTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

type GetSolanaIndexesParameters = {
  database: DatabaseWithTransaction
  analyzed: boolean
}

type GetSolanaIndexesReturnTypes = {
  signatures: readonly string[]
  tokenAccountOwnerMap: Record<string, string | undefined>
}

export const getSolanaIndexes = async ({
  database: { database },
  analyzed,
}: GetSolanaIndexesParameters): Promise<GetSolanaIndexesReturnTypes> => {
  const [signatureRows, tokenAccountRows] = await Promise.all([
    database
      .selectDistinct({ signature: accountSolanaSignatureTable.signature })
      .from(accountSolanaSignatureTable)
      .where(eq(accountSolanaSignatureTable.analyzed, analyzed)),
    database
      .select({
        ownerAddress: accountTable.solanaAddress,
        tokenAccountAddress: solanaTokenAccountTable.address,
      })
      .from(solanaTokenAccountTable)
      .innerJoin(
        accountTable,
        eq(solanaTokenAccountTable.accountId, accountTable.id),
      )
      .where(isNotNull(accountTable.solanaAddress)),
  ])

  const signatureSet = new Set(signatureRows.map(({ signature }) => signature))
  const tokenAccountOwnerMap: Record<string, string | undefined> = {}
  for (const { ownerAddress, tokenAccountAddress } of tokenAccountRows) {
    tokenAccountOwnerMap[tokenAccountAddress] = ownerAddress ?? undefined
  }

  return {
    signatures: [...signatureSet],
    tokenAccountOwnerMap,
  }
}
