import { asc, ne } from 'drizzle-orm'
import type { SQLiteUpdateSetSource } from 'drizzle-orm/sqlite-core'
import {
  accountingPeriodTable,
  accountingTransactionTable,
  accountingTransactionTransferTable,
  actionTable,
  assetBalanceTable,
  assetBorrowTable,
  crossActionBundleRelationTable,
  crossActionBundleTable,
  loanBorrowTable,
  loanBorrowWithDebtPositionTable,
  loanBorrowWithDebtTable,
  loanDepositTable,
  loanDepositWithBondPositionTable,
  loanDepositWithBondTable,
  loanTable,
  portfolioTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'

type UpdatePortfolioParameters = {
  database: DatabaseWithTransaction
  updating: Partial<SQLiteUpdateSetSource<typeof portfolioTable>>
  withDeletingAccountingData?: boolean
}

export const updatePortfolio = async ({
  database: { database, transaction },
  updating,
  withDeletingAccountingData,
}: UpdatePortfolioParameters) => {
  if (withDeletingAccountingData === true) {
    // 最古の会計年度を取得
    const oldestAccountingPeriod = await database
      .select({ id: accountingPeriodTable.id })
      .from(accountingPeriodTable)
      .orderBy(asc(accountingPeriodTable.id))
      .limit(1)
    const oldestAccountingPeriodId = oldestAccountingPeriod[0]?.id ?? 0

    await transaction([
      (tx) => [
        // update portfolio
        tx
          .update(portfolioTable)
          .set(updating),
        // update action
        tx
          .update(actionTable)
          .set({
            generatedTx: false,
            hasInsufficientAmountTx: null,
            hasUnknownPriceTx: null,
          }),
        // 最古の会計年度を除いて accounting period 削除
        tx
          .delete(accountingPeriodTable)
          .where(ne(accountingPeriodTable.id, oldestAccountingPeriodId)),
        // delete accounting transaction
        tx.delete(accountingTransactionTable),
        tx.delete(accountingTransactionTransferTable),
        // delete loan
        tx.delete(loanTable),
        tx.delete(loanBorrowTable),
        tx.delete(loanBorrowWithDebtTable),
        tx.delete(loanBorrowWithDebtPositionTable),
        tx.delete(loanDepositTable),
        tx.delete(loanDepositWithBondTable),
        tx.delete(loanDepositWithBondPositionTable),
        // delete cross action bundle
        tx.delete(crossActionBundleTable),
        tx.delete(crossActionBundleRelationTable),
        // 最古の会計年度を除いて asset balance 削除
        tx
          .delete(assetBalanceTable)
          .where(
            ne(assetBalanceTable.accountingPeriodId, oldestAccountingPeriodId),
          ),
        // delete asset borrow
        tx.delete(assetBorrowTable),
      ],
    ])
  } else {
    await database.update(portfolioTable).set(updating)
  }
}
