import type {
  calculateTransactionPlByWacb,
  createAssetBalancesForWacb,
  createTransactions,
} from '@0xtorch/accounting'
import type { CryptoCurrencyDataSource, FiatCurrency } from '@0xtorch/core'
import { parseTimezoneToHour } from '@pkg/basic'
import type {
  AccountingPeriod,
  DatabaseWithTransaction,
  Portfolio,
} from '@pkg/database-portfolio'
import {
  getOldestActionTimestamp,
  selectPortfolio,
  updatePortfolio,
  upsertAccountingPeriodList,
} from '@pkg/database-portfolio'
import { generateAccountingTransactionListOfAccountingPeriod } from './generateAccountingTransactionListOfAccountingPeriod'
import { saveAssetValues } from './saveAssetValues'

type GenerateAccountingTransactionListParameters = {
  readonly database: DatabaseWithTransaction
  readonly fiatCurrency: FiatCurrency
  readonly fiatCurrencyList: readonly FiatCurrency[]
  readonly cryptoCurrencyDataSource: CryptoCurrencyDataSource
  readonly createTransactions: (
    parameters: Parameters<typeof createTransactions>['0'],
  ) => Promise<ReturnType<typeof createTransactions>>
  readonly createAssetBalancesForWacb: (
    parameters: Parameters<typeof createAssetBalancesForWacb>['0'],
  ) => Promise<ReturnType<typeof createAssetBalancesForWacb>>
  readonly calculateTransactionPlByWacb: (
    parameters: Parameters<typeof calculateTransactionPlByWacb>['0'],
  ) => Promise<ReturnType<typeof calculateTransactionPlByWacb>>
}

export const generateAccountingTransactionList = async ({
  database,
  fiatCurrency,
  fiatCurrencyList,
  cryptoCurrencyDataSource,
  createTransactions,
  createAssetBalancesForWacb,
  calculateTransactionPlByWacb,
}: GenerateAccountingTransactionListParameters) => {
  // DB から portfolio 取得
  const portfolio = await selectPortfolio({ database })
  if (portfolio === undefined) {
    return
  }

  // portfolio が損益計算済だったら終了
  if (portfolio.isTxGenerated) {
    return
  }

  // DB から会計期間リスト , 最古の action timestamp 取得
  const [oldestActionTimestamp] = await Promise.all([
    getOldestActionTimestamp({ database }),
  ])

  // action が無ければ会計取引生成済に更新して終了
  if (oldestActionTimestamp === undefined) {
    await updatePortfolio({
      database,
      updating: {
        isTxGenerated: true,
      },
    })
    return
  }

  // accounting period list を現在時刻基準で更新 / DB に既に同一会計期の accounting period が存在する場合はそちらを優先
  const accountingPeriodList = createAccountingPeriodList(
    portfolio,
    oldestActionTimestamp,
  )

  // 更新した会計期間を DB に保存
  await upsertAccountingPeriodList({
    database,
    data: accountingPeriodList.map((item) => ({
      id: item.id,
      start: new Date(item.start),
      end: new Date(item.end),
    })),
  })

  // 会計期間毎に Transaction 生成・損益計算
  let forceUpdate = false
  for (const accountingPeriod of accountingPeriodList) {
    const updated = await generateAccountingTransactionListOfAccountingPeriod({
      database,
      portfolio,
      accountingPeriod,
      fiatCurrency,
      fiatCurrencyList,
      forceUpdate,
      createTransactions,
      createAssetBalancesForWacb,
      calculateTransactionPlByWacb,
    })
    forceUpdate = forceUpdate || updated
  }

  for (const accountingPeriod of accountingPeriodList) {
    // 会計年度の asset value 更新
    await saveAssetValues({
      database,
      accountingPeriod,
      cryptoCurrencyDataSource,
      fiat: fiatCurrency,
    })
  }

  // portfolio の損益計算済フラグを立てる
  await updatePortfolio({
    database,
    updating: {
      isTxGenerated: true,
    },
  })
}

const createAccountingPeriodList = (
  portfolio: Portfolio,
  oldestTimestamp: number,
): readonly AccountingPeriod[] => {
  const timezoneHour = parseTimezoneToHour(portfolio.timezone)
  const startMonth = portfolio.startMonth - 1
  const startDate = createStartDate(oldestTimestamp, startMonth, timezoneHour)
  const mut_accountingPeriodList: AccountingPeriod[] = []

  const now = Date.now()
  let currentDate = new Date(startDate.getTime())
  while (currentDate.getTime() < now) {
    const nextDate = new Date(currentDate.getTime())
    nextDate.setFullYear(nextDate.getFullYear() + 1)

    mut_accountingPeriodList.push({
      id: currentDate.getFullYear(),
      start: currentDate.getTime(),
      end: nextDate.getTime() - 1,
    })

    currentDate = nextDate
  }

  return mut_accountingPeriodList
}

const createStartDate = (
  timestamp: number,
  startMonth: number,
  timezoneHour: number,
): Date => {
  const date = new Date(timestamp)
  const year = date.getFullYear()

  // 同一年の開始日時を作成
  const startDate = new Date(
    `${year.toString().padStart(4, '0')}-${(startMonth + 1).toString().padStart(2, '0')}-01T00:00:00.000${timezoneHour >= 0 ? '+' : '-'}${(Math.abs(timezoneHour) % 24).toString().padStart(2, '0')}:00`,
  )
  const nextDate = new Date(startDate.getTime())
  nextDate.setFullYear(nextDate.getFullYear() + 1)

  if (timestamp < startDate.getTime()) {
    // timestamp < startDate の場合、前年の開始日時を返す
    startDate.setFullYear(startDate.getFullYear() - 1)
    return startDate
  }
  if (timestamp >= nextDate.getTime()) {
    // timestamp >= nextDate の場合、翌年の開始日時を返す
    return nextDate
  }
  // startDate <= timestamp の場合、同一年の開始日時をそのまま返す
  return startDate
}
