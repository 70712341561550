import type { RunToDatabase } from '@/types'
import { sql } from 'drizzle-orm'

const migrate0000 = sql`CREATE TABLE \`accountCsv\` (
	\`id\` integer PRIMARY KEY AUTOINCREMENT NOT NULL,
	\`accountId\` integer NOT NULL,
	\`csvId\` text NOT NULL,
	\`csvName\` text NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`accountErc20TokenRelation\` (
	\`accountId\` integer NOT NULL,
	\`tokenId\` text NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`tokenId\`) REFERENCES \`erc20Token\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`accountEvmCsv\` (
	\`accountId\` integer NOT NULL,
	\`csvId\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`fileName\` text NOT NULL,
	\`hashCount\` integer NOT NULL,
	\`from\` integer NOT NULL,
	\`to\` integer NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`accountEvmTxIndexRelation\` (
	\`accountId\` integer NOT NULL,
	\`hash\` blob NOT NULL,
	\`analyzed\` integer NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`accountExchangeApi\` (
	\`accountId\` integer NOT NULL,
	\`apiId\` text NOT NULL,
	\`from\` integer NOT NULL,
	\`to\` integer NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`accountSolanaSignature\` (
	\`accountId\` integer NOT NULL,
	\`signature\` text NOT NULL,
	\`analyzed\` integer NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`account\` (
	\`id\` integer PRIMARY KEY AUTOINCREMENT NOT NULL,
	\`group\` text NOT NULL,
	\`name\` text NOT NULL,
	\`type\` text NOT NULL,
	\`category\` text NOT NULL,
	\`syncing\` integer,
	\`lastSyncedAt\` integer,
	\`syncRegardlessOfUsage\` integer,
	\`evmChainId\` integer,
	\`evmAddress\` blob,
	\`evmToBlock\` integer,
	\`evmLastTxCount\` integer,
	\`solanaAddress\` text,
	\`exchangeApiKey\` text,
	\`exchangeApiSecret\` text,
	\`exchangeApiPassword\` text
);
--> statement-breakpoint
CREATE TABLE \`accountingPeriod\` (
	\`id\` integer PRIMARY KEY NOT NULL,
	\`start\` integer NOT NULL,
	\`end\` integer NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`accountingTransaction\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`accountingPeriodId\` integer NOT NULL,
	\`actionId\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`category\` text NOT NULL,
	\`subCategory\` text,
	\`targetId\` text,
	\`timestamp\` integer NOT NULL,
	\`comment\` text,
	\`updatedAt\` integer NOT NULL,
	FOREIGN KEY (\`accountingPeriodId\`) REFERENCES \`accountingPeriod\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`actionId\`) REFERENCES \`action\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`targetId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`accountingTransactionTransfer\` (
	\`transactionId\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`isIn\` integer NOT NULL,
	\`cryptoId\` text,
	\`fiatId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	\`price\` text NOT NULL,
	\`zeroCompleted\` integer NOT NULL,
	\`cost\` text NOT NULL,
	\`balance\` text NOT NULL,
	\`borrowing\` text NOT NULL,
	\`totalPl\` text NOT NULL,
	\`tradePl\` text NOT NULL,
	\`incomePl\` text NOT NULL,
	\`feePl\` text NOT NULL,
	FOREIGN KEY (\`transactionId\`) REFERENCES \`accountingTransaction\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`actionAccountRelation\` (
	\`actionId\` text NOT NULL,
	\`accountId\` integer NOT NULL,
	FOREIGN KEY (\`actionId\`) REFERENCES \`action\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`actionCsvRelation\` (
	\`actionId\` text NOT NULL,
	\`csvId\` integer NOT NULL,
	FOREIGN KEY (\`actionId\`) REFERENCES \`action\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`csvId\`) REFERENCES \`accountCsv\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`actionSource\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`type\` text NOT NULL,
	\`evmChainId\` integer,
	\`evmHash\` blob,
	\`evmStatus\` text,
	\`evmFunctionId\` blob,
	\`evmFunctionName\` text,
	\`solanaSignature\` text,
	\`solanaStatus\` text
);
--> statement-breakpoint
CREATE TABLE \`action\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`sourceId\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`type\` text NOT NULL,
	\`evidence\` text NOT NULL,
	\`timestamp\` integer NOT NULL,
	\`comment\` text,
	\`app\` text,
	\`loanId\` text,
	\`crossId\` text,
	\`crossType\` text,
	\`targetId\` text,
	\`locked\` integer NOT NULL,
	\`generatedTx\` integer NOT NULL,
	\`relatedAssetIds\` text,
	\`fromAddresses\` text,
	\`toAddresses\` text,
	\`hasUnknownPriceTx\` integer,
	\`hasInsufficientAmountTx\` integer,
	FOREIGN KEY (\`sourceId\`) REFERENCES \`actionSource\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`targetId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`actionTransfer\` (
	\`actionId\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`direction\` text NOT NULL,
	\`from\` text,
	\`to\` text,
	\`cryptoId\` text,
	\`fiatId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	\`price\` text,
	FOREIGN KEY (\`actionId\`) REFERENCES \`action\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`assetBalanceHistory\` (
	\`accountingId\` integer NOT NULL,
	\`key\` text NOT NULL,
	\`day\` integer NOT NULL,
	\`hour\` integer NOT NULL,
	\`cryptoId\` text,
	\`fiatId\` text,
	\`amount\` text NOT NULL,
	\`price\` text,
	\`timestamp\` integer NOT NULL,
	FOREIGN KEY (\`accountingId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`assetBalance\` (
	\`accountingPeriodId\` integer NOT NULL,
	\`asset\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`value\` text NOT NULL,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`accountingPeriodId\`) REFERENCES \`accountingPeriod\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`assetBorrow\` (
	\`accountingPeriodId\` integer NOT NULL,
	\`asset\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`value\` text NOT NULL,
	\`amount\` text NOT NULL,
	\`borrowedValue\` text NOT NULL,
	\`borrowedAmount\` text NOT NULL,
	FOREIGN KEY (\`accountingPeriodId\`) REFERENCES \`accountingPeriod\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`assetValue\` (
	\`accountingPeriodId\` integer NOT NULL,
	\`asset\` text NOT NULL,
	\`type\` text NOT NULL,
	\`amount\` text NOT NULL,
	\`cost\` text NOT NULL,
	\`value\` text NOT NULL,
	\`isKnownPeriodEndPrice\` integer NOT NULL,
	FOREIGN KEY (\`accountingPeriodId\`) REFERENCES \`accountingPeriod\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`crossActionBundleRelation\` (
	\`crossActionBundleId\` integer NOT NULL,
	\`actionId\` text NOT NULL,
	FOREIGN KEY (\`crossActionBundleId\`) REFERENCES \`crossActionBundle\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`actionId\`) REFERENCES \`action\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`crossActionBundle\` (
	\`id\` integer PRIMARY KEY AUTOINCREMENT NOT NULL,
	\`accountingPeriodId\` integer NOT NULL,
	\`crossActionBundleId\` text NOT NULL,
	FOREIGN KEY (\`accountingPeriodId\`) REFERENCES \`accountingPeriod\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`cryptoCurrency\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`name\` text NOT NULL,
	\`symbol\` text NOT NULL,
	\`icon\` text,
	\`coingeckoId\` text,
	\`marketCapUsd\` integer,
	\`priceDatasourceId\` text,
	\`updatedAt\` integer NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`erc20Token\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`chainId\` integer NOT NULL,
	\`address\` blob,
	\`name\` text NOT NULL,
	\`symbol\` text NOT NULL,
	\`decimals\` integer NOT NULL,
	\`currencyId\` text,
	FOREIGN KEY (\`currencyId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`evmInternalTransaction\` (
	\`chainId\` integer NOT NULL,
	\`contractAddress\` blob NOT NULL,
	\`from\` blob NOT NULL,
	\`gas\` blob NOT NULL,
	\`hash\` blob NOT NULL,
	\`isError\` integer NOT NULL,
	\`to\` blob NOT NULL,
	\`value\` blob NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`evmTxIndex\` (
	\`chainId\` integer NOT NULL,
	\`hash\` blob NOT NULL,
	\`blockNumber\` integer NOT NULL,
	\`timestamp\` integer NOT NULL,
	\`input\` blob,
	\`value\` blob
);
--> statement-breakpoint
CREATE TABLE \`journalAccount\` (
	\`key\` text PRIMARY KEY NOT NULL,
	\`account\` text NOT NULL,
	\`subAccount\` text NOT NULL,
	\`debitTaxType\` text NOT NULL,
	\`creditTaxType\` text NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`loanBorrow\` (
	\`loanId\` integer NOT NULL,
	\`key\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`cryptoId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`loanId\`) REFERENCES \`loan\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`loanBorrowWithDebtPosition\` (
	\`loanId\` integer NOT NULL,
	\`debtKey\` text NOT NULL,
	\`debtOrder\` integer NOT NULL,
	\`positionOrder\` integer NOT NULL,
	\`cryptoId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`loanId\`) REFERENCES \`loan\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`loanBorrowWithDebt\` (
	\`loanId\` integer NOT NULL,
	\`key\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`cryptoId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`loanId\`) REFERENCES \`loan\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`loanDeposit\` (
	\`loanId\` integer NOT NULL,
	\`key\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`cryptoId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`loanId\`) REFERENCES \`loan\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`loanDepositWithBondPosition\` (
	\`loanId\` integer NOT NULL,
	\`bondKey\` text NOT NULL,
	\`bondOrder\` integer NOT NULL,
	\`positionOrder\` integer NOT NULL,
	\`cryptoId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`loanId\`) REFERENCES \`loan\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`loanDepositWithBond\` (
	\`loanId\` integer NOT NULL,
	\`key\` text NOT NULL,
	\`order\` integer NOT NULL,
	\`cryptoId\` text,
	\`nftId\` text,
	\`amount\` text NOT NULL,
	FOREIGN KEY (\`loanId\`) REFERENCES \`loan\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`cryptoId\`) REFERENCES \`cryptoCurrency\`(\`id\`) ON UPDATE no action ON DELETE no action,
	FOREIGN KEY (\`nftId\`) REFERENCES \`nft\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`loan\` (
	\`id\` integer PRIMARY KEY AUTOINCREMENT NOT NULL,
	\`accountingPeriodId\` integer NOT NULL,
	\`loanId\` text NOT NULL,
	FOREIGN KEY (\`accountingPeriodId\`) REFERENCES \`accountingPeriod\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE TABLE \`nft\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`name\` text,
	\`image\` text,
	\`metadata\` text,
	\`updatedAt\` integer NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`portfolio\` (
	\`id\` text PRIMARY KEY NOT NULL,
	\`name\` text NOT NULL,
	\`fiat\` text NOT NULL,
	\`timezone\` text NOT NULL,
	\`startMonth\` integer NOT NULL,
	\`costBasis\` text NOT NULL,
	\`handleLoanAsTrade\` integer,
	\`handleReplaceAsTrade\` integer,
	\`use5PercentRule\` integer,
	\`needPeriodEndEvaluation\` integer NOT NULL,
	\`isTxGenerated\` integer NOT NULL,
	\`startTime\` integer
);
--> statement-breakpoint
CREATE TABLE \`singleActionRule\` (
	\`source\` text NOT NULL,
	\`targetType\` text NOT NULL,
	\`newType\` text NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`solanaSignature\` (
	\`signature\` text PRIMARY KEY NOT NULL,
	\`timestamp\` integer NOT NULL
);
--> statement-breakpoint
CREATE TABLE \`solanaTokenAccount\` (
	\`accountId\` integer NOT NULL,
	\`address\` text NOT NULL,
	FOREIGN KEY (\`accountId\`) REFERENCES \`account\`(\`id\`) ON UPDATE no action ON DELETE no action
);
--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_accountErc20TokenRelation\` ON \`accountErc20TokenRelation\` (\`accountId\`,\`tokenId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`unq_accountEvmCsv\` ON \`accountEvmCsv\` (\`accountId\`,\`csvId\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_accountEvmTxIndexRelation\` ON \`accountEvmTxIndexRelation\` (\`accountId\`,\`hash\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`unq_accountExchangeApi\` ON \`accountExchangeApi\` (\`accountId\`,\`apiId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_accountSolanaSignature\` ON \`accountSolanaSignature\` (\`accountId\`,\`signature\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`account_evmChainId_evmAddress_unique\` ON \`account\` (\`evmChainId\`,\`evmAddress\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`account_solanaAddress_unique\` ON \`account\` (\`solanaAddress\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_accountingTransaction\` ON \`accountingTransaction\` (\`actionId\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_accountingTransactionTransfer\` ON \`accountingTransactionTransfer\` (\`transactionId\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_actionAccountRelation\` ON \`actionAccountRelation\` (\`actionId\`,\`accountId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_actionCsvRelation\` ON \`actionCsvRelation\` (\`actionId\`,\`csvId\`);--> statement-breakpoint
CREATE INDEX \`idx_action\` ON \`action\` (\`sourceId\`,\`order\`,\`type\`,\`evidence\`,\`timestamp\`,\`app\`,\`targetId\`,\`locked\`,\`relatedAssetIds\`,\`fromAddresses\`,\`toAddresses\`,\`hasUnknownPriceTx\`,\`hasInsufficientAmountTx\`);--> statement-breakpoint
CREATE INDEX \`idx_action_type\` ON \`action\` (\`type\`);--> statement-breakpoint
CREATE INDEX \`idx_action_evidence\` ON \`action\` (\`evidence\`);--> statement-breakpoint
CREATE INDEX \`idx_action_timestamp\` ON \`action\` (\`timestamp\`);--> statement-breakpoint
CREATE INDEX \`idx_action_app\` ON \`action\` (\`app\`);--> statement-breakpoint
CREATE INDEX \`targetIdIdx\` ON \`action\` (\`targetId\`);--> statement-breakpoint
CREATE INDEX \`idx_action_locked\` ON \`action\` (\`locked\`);--> statement-breakpoint
CREATE INDEX \`idx_action_relatedAssetIds\` ON \`action\` (\`relatedAssetIds\`);--> statement-breakpoint
CREATE INDEX \`idx_action_fromAddresses\` ON \`action\` (\`fromAddresses\`);--> statement-breakpoint
CREATE INDEX \`idx_action_toAddresses\` ON \`action\` (\`toAddresses\`);--> statement-breakpoint
CREATE INDEX \`idx_action_hasUnknownPriceTx\` ON \`action\` (\`hasUnknownPriceTx\`);--> statement-breakpoint
CREATE INDEX \`idx_action_hasInsufficientAmountTx\` ON \`action\` (\`hasInsufficientAmountTx\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_actionTransfer\` ON \`actionTransfer\` (\`actionId\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_assetBalanceHistory\` ON \`assetBalanceHistory\` (\`accountingId\`,\`key\`,\`day\`,\`hour\`,\`cryptoId\`,\`fiatId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_assetBalance\` ON \`assetBalance\` (\`accountingPeriodId\`,\`asset\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_assetBorrow\` ON \`assetBorrow\` (\`accountingPeriodId\`,\`asset\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`assetValue_accountingPeriodId_asset_type_unique\` ON \`assetValue\` (\`accountingPeriodId\`,\`asset\`,\`type\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_crossActionBundleRelation\` ON \`crossActionBundleRelation\` (\`crossActionBundleId\`,\`actionId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_crossActionBundle\` ON \`crossActionBundle\` (\`accountingPeriodId\`,\`crossActionBundleId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_erc20Token\` ON \`erc20Token\` (\`chainId\`,\`address\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_evmInternalTransaction\` ON \`evmInternalTransaction\` (\`chainId\`,\`contractAddress\`,\`from\`,\`gas\`,\`hash\`,\`isError\`,\`to\`,\`value\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_evmTxIndex\` ON \`evmTxIndex\` (\`chainId\`,\`hash\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loanBorrowPosition\` ON \`loanBorrow\` (\`loanId\`,\`key\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loanBorrowWithDebtPosition\` ON \`loanBorrowWithDebtPosition\` (\`loanId\`,\`debtKey\`,\`debtOrder\`,\`positionOrder\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loanBorrowWithDebt\` ON \`loanBorrowWithDebt\` (\`loanId\`,\`key\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loanDeposit\` ON \`loanDeposit\` (\`loanId\`,\`key\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loanDepositWithBondPosition\` ON \`loanDepositWithBondPosition\` (\`loanId\`,\`bondKey\`,\`bondOrder\`,\`positionOrder\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loanDepositWithBond\` ON \`loanDepositWithBond\` (\`loanId\`,\`key\`,\`order\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_loan\` ON \`loan\` (\`accountingPeriodId\`,\`loanId\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_singleActionRule\` ON \`singleActionRule\` (\`source\`,\`targetType\`);--> statement-breakpoint
CREATE INDEX \`idx_solanaSignature_timestamp\` ON \`solanaSignature\` (\`timestamp\`);--> statement-breakpoint
CREATE UNIQUE INDEX \`idx_solanaTokenAccount\` ON \`solanaTokenAccount\` (\`accountId\`,\`address\`);`

const migrates = [migrate0000]

export const migration = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  const TABLE_NAME = '__migrations'
  await readFromDatabase((database) =>
    (async () => {
      return await database.database.run(sql`CREATE TABLE IF NOT EXISTS \`${TABLE_NAME}\` (
      \`id\` INTEGER PRIMARY KEY NOT NULL
    );`)
    })(),
  )
  const data = await readFromDatabase((database) =>
    (async () => {
      return await database.database.values<[number]>(
        sql`SELECT \`id\` FROM \`${TABLE_NAME}\` ORDER BY \`id\` DESC LIMIT 1;`,
      )
    })(),
  )
  const lastMigrationId = data.length > 0 ? data[0][0] : -1
  for (const [index, migrate] of migrates.entries()) {
    if (index <= lastMigrationId) {
      continue
    }
    await writeToDatabase((database) =>
      (async () => {
        await database.database.run(migrate)
      })(),
    )
    await writeToDatabase((database) =>
      (async () => {
        await database.database.run(
          sql`INSERT INTO \`${TABLE_NAME}\` (\`id\`) VALUES (${sql.raw(`${index}`)});`,
        )
      })(),
    )
  }
}
