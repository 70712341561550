import type { CrossActionBundle } from '@0xtorch/accounting'
import type { FiatCurrency } from '@0xtorch/core'
import { parseToCrossActionBundle } from '../parsers/crossActionBundle'
import type { DatabaseWithTransaction } from '../types'

type SelectCrossActionBundlesParameters = {
  readonly database: DatabaseWithTransaction
  readonly accountingPeriodId: number
  readonly fiatCurrencyList: readonly FiatCurrency[]
  readonly fiatCurrency: FiatCurrency
}

export const selectCrossActionBundles = async ({
  database: { database },
  accountingPeriodId,
  fiatCurrencyList,
  fiatCurrency,
}: SelectCrossActionBundlesParameters): Promise<
  readonly CrossActionBundle[]
> => {
  const data = await database.query.crossActionBundleTable.findMany({
    where: (crossActionBundleTable, { eq }) =>
      eq(crossActionBundleTable.accountingPeriodId, accountingPeriodId),
    with: {
      crossActionBundleRelationList: {
        with: {
          action: {
            with: {
              actionTransferTable: {
                with: {
                  cryptoCurrencyTable: true,
                  nftTable: true,
                },
              },
              nftTable: true,
            },
          },
        },
      },
    },
  })

  return data.map((row) =>
    parseToCrossActionBundle(row, fiatCurrencyList, fiatCurrency),
  )
}
