import { apiClient } from '../../constants'
import { decompress } from '../../logic/decompress'

export const getPortfolioDbFile = async (params: {
  token: string
  id: string
  timestamp?: number
}): Promise<Blob | undefined> => {
  const { token, id, timestamp } = params

  const response = await apiClient.v1.portfolio[':id'].db.$get(
    {
      param: { id },
      query: { timestamp: timestamp?.toString() },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!response.ok) {
    if (response.status === 401 || response.status === 404) {
      return undefined
    }
    throw new Error(
      `Failed to get portfolio DB file: ${response.status} ${response.statusText}`,
    )
  }
  const file = await response.blob()
  const dbFile = await decompress(file)
  return dbFile
}
