import type { Nft } from '@0xtorch/core'
import type { nftTable } from '../schema'

export const parseToNft = (data: typeof nftTable.$inferSelect): Nft => ({
  type: 'Nft',
  id: data.id,
  name: data.name ?? undefined,
  image: data.image ?? undefined,
  metadata: data.metadata ?? undefined,
  updatedAt: data.updatedAt ?? 0,
})
