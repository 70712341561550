import { eq } from 'drizzle-orm'
import { accountExchangeApiSchema } from '../querySchema'
import { accountExchangeApiTable } from '../schema'
import type { AccountExchangeApi, DatabaseWithTransaction } from '../types'

type SelectAccountExchangeApisParameters = {
  database: DatabaseWithTransaction
  accountId?: number
}

export const selectAccountExchangeApis = async ({
  database: { database },
  accountId,
}: SelectAccountExchangeApisParameters): Promise<
  readonly AccountExchangeApi[]
> => {
  const data = await database
    .select()
    .from(accountExchangeApiTable)
    .where(
      accountId === undefined
        ? undefined
        : eq(accountExchangeApiTable.accountId, accountId),
    )
  return data.map((item) => accountExchangeApiSchema.parse(item))
}
