import type { Action, Nft } from '@0xtorch/core'
import { isErc721NftId, isErc1155NftId } from '@0xtorch/evm'
import { isSolanaNftId } from '@0xtorch/solana'
import { divideArrayIntoChunks } from '@pkg/basic'
import type { DatabaseWithTransaction } from '@pkg/database-portfolio'
import { selectNfts, upsertNfts } from '@pkg/database-portfolio'
import type { AppType as EvmAppType } from 'evm-token-worker'
import { hc } from 'hono/client'
import type { AppType as SolanaAppType } from 'solana-token'

type FetchAndSaveActionNftListParameters = {
  readonly database: DatabaseWithTransaction
  readonly evmTokenApiEndpoint: string
  readonly splTokenApiEndpoint: string
  readonly actionList: readonly Action[]
}

export const fetchAndSaveActionNftList = async ({
  database,
  evmTokenApiEndpoint,
  splTokenApiEndpoint,
  actionList,
}: FetchAndSaveActionNftListParameters) => {
  // action list から NFT list 作成
  const nftList = parseActionListToNftList(actionList)
  //
  // ID が一致する updatedAt > 0 の NFT を DB から取得
  const localNoZeroTimeNftList = await selectNfts({
    database,
    idList: nftList.map((nft) => nft.id),
    after: 1,
  })
  const localNoZeroTimeNftIdSet = new Set(
    localNoZeroTimeNftList.map((nft) => nft.id),
  )
  //
  // updatedAt=0 の DB には存在しない NFT id list 作成
  const nftIdSet = new Set(
    nftList
      .filter(
        (nft) => nft.updatedAt === 0 && !localNoZeroTimeNftIdSet.has(nft.id),
      )
      .map((nft) => nft.id),
  )
  const nftIdList = [...nftIdSet]
  const evmNftIdList = nftIdList.filter(
    (id) => isErc721NftId(id) || isErc1155NftId(id),
  )
  const solanaNftIdList = nftIdList.filter((id) => isSolanaNftId(id))

  const [evmNftList, solanaNftList] = await Promise.all([
    // EVM NFT data を evm-token API から取得
    getEvmNftList(evmTokenApiEndpoint, evmNftIdList),
    // Solana NFT data を solana-token API から取得
    getSolanaNftList(splTokenApiEndpoint, solanaNftIdList),
  ])

  // API から取得した NFT data を DB に保存
  await upsertNfts({
    database,
    data: [...evmNftList, ...solanaNftList],
  })
}

export const parseActionListToNftList = (
  actionList: readonly Action[],
): readonly Nft[] => {
  const nftIdSet = new Set<string>()
  const mut_nftList: Nft[] = []
  for (const action of actionList) {
    if (action.target !== undefined && !nftIdSet.has(action.target.id)) {
      nftIdSet.add(action.target.id)
      mut_nftList.push(action.target)
    }
    for (const transfer of action.transfers) {
      if (transfer.asset.type === 'Nft' && !nftIdSet.has(transfer.asset.id)) {
        nftIdSet.add(transfer.asset.id)
        mut_nftList.push(transfer.asset)
      }
    }
  }
  return mut_nftList
}

const getEvmNftList = (() => {
  const mut_nftCache: Nft[] = []

  const execute = async (
    apiEndpoint: string,
    nftIdList: readonly string[],
  ): Promise<readonly Nft[]> => {
    // キャッシュが存在するデータを抽出
    const cached = mut_nftCache.filter((item) => nftIdList.includes(item.id))

    // 全てキャッシュに存在する場合、キャッシュを返す
    const requestNftIdList = nftIdList.filter(
      (id) => !cached.some((item) => item.id === id),
    )
    if (requestNftIdList.length === 0) {
      return cached
    }

    // キャッシュが無い ID に対して API request
    // id を 40 個までに分割してリクエスト
    const client = hc<EvmAppType>(apiEndpoint)
    const idListChunkList = divideArrayIntoChunks(requestNftIdList, 40)
    const responseList = await Promise.all(
      idListChunkList.map((idList) =>
        (async () => {
          const response = await client.v1.nft.$get({
            query: { id: [...idList] },
          })
          if (!response.ok) {
            return []
          }
          return await response.json()
        })(),
      ),
    )
    const nftList = responseList.flat()

    // request 結果をキャッシュに保存
    mut_nftCache.push(...nftList)

    // キャッシュと request 結果を結合して返す
    return [...cached, ...nftList]
  }

  return execute
})()

const getSolanaNftList = (() => {
  const mut_nftCache: Nft[] = []

  const execute = async (
    apiEndpoint: string,
    nftIdList: readonly string[],
  ): Promise<readonly Nft[]> => {
    // キャッシュが存在するデータを抽出
    const cached = mut_nftCache.filter((item) => nftIdList.includes(item.id))

    // 全てキャッシュに存在する場合、キャッシュを返す
    const requestNftIdList = nftIdList.filter(
      (id) => !cached.some((item) => item.id === id),
    )
    if (requestNftIdList.length === 0) {
      return cached
    }

    // キャッシュが無い ID に対して API request
    // id を 40 個までに分割してリクエスト
    const client = hc<SolanaAppType>(apiEndpoint)
    const idListChunkList = divideArrayIntoChunks(requestNftIdList, 40)
    const responseList = await Promise.all(
      idListChunkList.map((idList) =>
        (async () => {
          const response = await client.v1.nft.$get({
            query: { id: [...idList] },
          })
          if (!response.ok) {
            return []
          }
          return await response.json()
        })(),
      ),
    )
    const nftList = responseList.flat()

    // request 結果をキャッシュに保存
    mut_nftCache.push(...nftList)

    // キャッシュと request 結果を結合して返す
    return [...cached, ...nftList]
  }

  return execute
})()
