import type { LowerHex, TransactionDetail } from '@0xtorch/evm'
import {
  createErc721NftId,
  createErc1155NftId,
  isHex,
  toLowerHex,
} from '@0xtorch/evm'
import { createEvmAnalyzeDataSource } from './createEvmAnalyzeDataSource'

type CreateEvmDatasourceCacheParameters = {
  readonly assetApiEndpoint: string
  readonly datasourceApiEndpoint: string
  readonly evmTokenApiEndpoint: string
  readonly chainId: number
  readonly transactionList: readonly TransactionDetail[]
  readonly accountAddresses: Set<LowerHex>
}

export const createEvmDatasourceCache = async ({
  assetApiEndpoint,
  datasourceApiEndpoint,
  evmTokenApiEndpoint,
  chainId,
  transactionList,
  accountAddresses,
}: CreateEvmDatasourceCacheParameters) => {
  const datasource = createEvmAnalyzeDataSource({
    assetApiEndpoint,
    datasourceApiEndpoint,
    evmTokenApiEndpoint,
  })

  // address
  const addresses = new Set<LowerHex>()
  // erc20 token
  const erc20Addresses = new Set<LowerHex>()
  // event abi
  const eventSignatures = new Set<LowerHex>()
  // function abi
  const functionIds = new Set<LowerHex>()
  // nft
  const nftIds = new Set<string>()

  for (const transaction of transactionList) {
    addresses.add(transaction.from)
    if (transaction.to !== undefined) {
      addresses.add(transaction.to)
    }
    for (const log of transaction.logs) {
      addresses.add(log.address)
      if (
        log.topics.length > 0 &&
        (log.eventName === undefined || log.args === undefined)
      ) {
        eventSignatures.add(log.topics[0])
      }
    }
    if (transaction.input.length >= 10) {
      const functionId = transaction.input.slice(0, 10)
      if (isHex(functionId)) {
        functionIds.add(toLowerHex(functionId))
      }
    }
    for (const erc20Transfer of transaction.erc20Transfers) {
      erc20Addresses.add(erc20Transfer.address)
    }
    for (const erc721Transfer of transaction.erc721Transfers) {
      if (
        !accountAddresses.has(erc721Transfer.from) &&
        !accountAddresses.has(erc721Transfer.to)
      ) {
        continue
      }

      nftIds.add(
        createErc721NftId({
          chainId,
          address: erc721Transfer.address,
          tokenId: erc721Transfer.tokenId,
        }),
      )
    }
    for (const erc1155Transfer of transaction.erc1155Transfers) {
      if (
        !accountAddresses.has(erc1155Transfer.from) &&
        !accountAddresses.has(erc1155Transfer.to)
      ) {
        continue
      }

      nftIds.add(
        createErc1155NftId({
          chainId,
          address: erc1155Transfer.address,
          tokenId: erc1155Transfer.tokenId,
        }),
      )
    }
  }

  await Promise.all([
    datasource.getAddresses({ chainId, addresses: [...addresses] }),
    datasource.getErc20Tokens({ chainId, addresses: [...erc20Addresses] }),
    datasource.getEventAbis({ signatures: [...eventSignatures] }),
    datasource.getNfts({ ids: [...nftIds] }),
    [...functionIds].map((functionId) =>
      datasource.getFunctionAbi({ functionId }),
    ),
    [...functionIds].map((functionId) =>
      datasource.getJsonAnalyzer({ functionId }),
    ),
  ])
}
