import { apiClient } from '../../constants'

export const resetPortfolioLastUsedAt = async (params: {
  token: string
  id: string
}) => {
  const { token, id } = params

  const response = await apiClient.v1.portfolio[':id'].use.$post(
    {
      json: {
        timestamp: 0,
      },
      param: { id },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (!response.ok) {
    throw new Error(
      `Failed to update lastUsed: ${response.status} ${response.statusText}`,
    )
  }
}
