import { createBigDecimal, divide, times } from '@0xtorch/big-decimal'
import type {
  CryptoCurrencyDataSource,
  CryptoCurrencyPrice,
} from '@0xtorch/core'

export const createCryptoCurrencyDataSource = (
  assetApiEndpoint: string,
): CryptoCurrencyDataSource => ({
  getHistoricalPrices: async ({
    targetCurrency,
    vsCurrency,
    timestampList,
  }) => {
    if (timestampList.length === 0) {
      return []
    }
    if (
      vsCurrency.id !== 'eur' &&
      vsCurrency.id !== 'usd' &&
      vsCurrency.id !== 'jpy'
    ) {
      throw new Error(`Not supported vs currency: ${vsCurrency.id}`)
    }

    // targetCurrency に priceDatasourceId が設定されている場合は、その priceDatasourceId で price 取得
    const targetCurrencyId =
      targetCurrency.priceDatasourceId ?? targetCurrency.id

    // EVM / Solana token の場合は価格データ無いので空配列を返す
    if (
      targetCurrencyId.startsWith('evm_') ||
      targetCurrencyId.startsWith('solana_')
    ) {
      return []
    }

    // timestamp list をもとに取得対象日付 list 作成
    const dateTextList = createDateTextListByTimestampList(timestampList)

    // 日付毎の csv file 取得
    const [resultList, usdBtcResultList, vsBtcResultList] = await Promise.all([
      Promise.all(
        dateTextList.map((date) =>
          getPriceList({
            endpoint: assetApiEndpoint,
            targetCurrencyId,
            vsCurrencyId:
              targetCurrencyId === 'bitcoin' ? vsCurrency.id : 'usd',
            date,
          }),
        ),
      ),
      targetCurrencyId === 'bitcoin' || vsCurrency.id === 'usd'
        ? undefined
        : Promise.all(
            dateTextList.map((date) =>
              getPriceList({
                endpoint: assetApiEndpoint,
                targetCurrencyId: 'bitcoin',
                vsCurrencyId: 'usd',
                date,
              }),
            ),
          ),
      targetCurrencyId === 'bitcoin' || vsCurrency.id === 'usd'
        ? undefined
        : Promise.all(
            dateTextList.map((date) =>
              getPriceList({
                endpoint: assetApiEndpoint,
                targetCurrencyId: 'bitcoin',
                vsCurrencyId: vsCurrency.id,
                date,
              }),
            ),
          ),
    ])
    const priceList =
      targetCurrencyId === 'bitcoin' || vsCurrency.id === 'usd'
        ? resultList.flat()
        : createPriceListWithFiatBtcPrices(
            resultList.flat(),
            usdBtcResultList === undefined ? [] : usdBtcResultList.flat(),
            vsBtcResultList === undefined ? [] : vsBtcResultList.flat(),
          )

    // priceDatasourceId が設定されている場合は price の currencyId を変換して返す
    return targetCurrency.priceDatasourceId === undefined
      ? priceList
      : priceList.map((price) => ({
          ...price,
          cryptoCurrencyId: targetCurrency.id,
        }))
  },
})

const createDateTextListByTimestampList = (
  timestampList: readonly number[],
) => [
  ...new Set(
    timestampList.flatMap((timestamp) => [
      createDateTextByTimestamp(createPreviousDateTimestamp(timestamp)),
      createDateTextByTimestamp(timestamp),
      createDateTextByTimestamp(createNextDateTimestamp(timestamp)),
    ]),
  ),
]

const createNextDateTimestamp = (timestamp: number) => {
  const date = new Date(timestamp)
  date.setDate(date.getDate() + 1)
  return date.getTime()
}

const createPreviousDateTimestamp = (timestamp: number) => {
  const date = new Date(timestamp)
  date.setDate(date.getDate() - 1)
  return date.getTime()
}

const createDateTextByTimestamp = (timestamp: number) =>
  new Date(timestamp).toISOString().split('T')[0]

const timestampRegex = /^\d+$/
const priceRegex = /^\d+(\.\d+)?$/

export const getPriceList = (() => {
  const mut_priceListCache: {
    [key: string]: readonly CryptoCurrencyPrice[] | undefined
  } = {}
  const execute = async ({
    endpoint,
    targetCurrencyId,
    vsCurrencyId,
    date,
  }: {
    readonly endpoint: string
    readonly targetCurrencyId: string
    readonly vsCurrencyId: string
    readonly date: string
  }): Promise<readonly CryptoCurrencyPrice[]> => {
    const key = `${targetCurrencyId}/${vsCurrencyId}/${date}`

    // キャッシュがあればそれを返す
    const cached = mut_priceListCache[key]
    if (cached !== undefined) {
      return cached
    }

    // キャッシュが無い場合は API request してキャッシュを更新する
    const url = new URL(
      `/prices/${targetCurrencyId}/${vsCurrencyId}/${date}.csv`,
      endpoint,
    )
    const response = await fetch(url.toString())
    if (!response.ok) {
      // 404 は空データとしてキャッシュに保存
      if (response.status === 404) {
        mut_priceListCache[key] = []
      }
      return []
    }
    const csv = await response.text()
    // CSV パースして price list 作成
    const priceList = csv
      .split('\n')
      .slice(1)
      .filter((row) => row.length > 0 && row.includes(','))
      .map((line): CryptoCurrencyPrice => {
        const [timestampText, priceText] = line
          .split(',')
          .map((value) => value.trim())
        if (
          !timestampRegex.test(timestampText) ||
          !priceRegex.test(priceText)
        ) {
          throw new Error(`Invalid CSV format: ${line}`)
        }
        return {
          cryptoCurrencyId: targetCurrencyId,
          fiatCurrencyId: vsCurrencyId,
          price: createBigDecimal(priceText),
          timestamp: Number(timestampText),
        }
      })
    mut_priceListCache[key] = priceList

    // API request で取得したデータを返す
    return priceList
  }

  return execute
})()

const createPriceListWithFiatBtcPrices = (
  usdTargetPriceList: readonly CryptoCurrencyPrice[],
  usdBtcPriceList: readonly CryptoCurrencyPrice[],
  vsBtcPriceList: readonly CryptoCurrencyPrice[],
): readonly CryptoCurrencyPrice[] => {
  const mut_priceList: CryptoCurrencyPrice[] = []
  for (const usdTargetPrice of usdTargetPriceList) {
    // 1時間以内の
    const usdBtcPrice = usdBtcPriceList
      .filter(
        ({ timestamp: priceTimestamp }) =>
          priceTimestamp >= usdTargetPrice.timestamp - 1000 * 60 * 60 &&
          priceTimestamp <= usdTargetPrice.timestamp + 1000 * 60 * 60,
      )
      .reduce(
        (closestPrice: CryptoCurrencyPrice | undefined, price) =>
          closestPrice === undefined ||
          Math.abs(price.timestamp - usdTargetPrice.timestamp) <
            Math.abs(closestPrice.timestamp - usdTargetPrice.timestamp)
            ? price
            : closestPrice,
        undefined,
      )
    const vsBtcPrice = vsBtcPriceList
      .filter(
        ({ timestamp: priceTimestamp }) =>
          priceTimestamp >= usdTargetPrice.timestamp - 1000 * 60 * 60 &&
          priceTimestamp <= usdTargetPrice.timestamp + 1000 * 60 * 60,
      )
      .reduce(
        (closestPrice: CryptoCurrencyPrice | undefined, price) =>
          closestPrice === undefined ||
          Math.abs(price.timestamp - usdTargetPrice.timestamp) <
            Math.abs(closestPrice.timestamp - usdTargetPrice.timestamp)
            ? price
            : closestPrice,
        undefined,
      )
    if (usdBtcPrice === undefined || vsBtcPrice === undefined) {
      continue
    }
    mut_priceList.push({
      ...usdTargetPrice,
      price: divide(
        times(usdTargetPrice.price, vsBtcPrice.price, 18),
        usdBtcPrice.price,
        18,
      ),
    })
  }
  return mut_priceList
}
