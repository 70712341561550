import type { Transaction } from '@0xtorch/accounting'
import { createBigDecimal, toStringBigDecimal } from '@0xtorch/big-decimal'
import type { CryptoCurrency, FiatCurrency, Nft } from '@0xtorch/core'
import type {
  accountingTransactionTable,
  accountingTransactionTransferTable,
  cryptoCurrencyTable,
  nftTable,
} from '../schema'
import type {
  InsertAccountingTransactionData,
  InsertAccountingTransactionTransferData,
  InsertCryptoCurrencyData,
  InsertNftData,
} from '../types'
import { parseToCryptoCurrency } from './cryptoCurrency'
import { parseToNft } from './nft'

type TransactionTransferData =
  typeof accountingTransactionTransferTable.$inferSelect & {
    cryptoCurrency: typeof cryptoCurrencyTable.$inferSelect | null
    nft: typeof nftTable.$inferSelect | null
  }

export type TransactionData = typeof accountingTransactionTable.$inferSelect & {
  transferList: TransactionTransferData[]
  nft: typeof nftTable.$inferSelect | null
}

export const parseToTransaction = (
  data: TransactionData,
  fiatCurrencyList: readonly FiatCurrency[],
): Transaction => {
  const transaction: Transaction = {
    actionId: data.actionId,
    order: data.order,
    timestamp: data.timestamp.getTime(),
    category: data.category,
    subCategory: data.subCategory ?? undefined,
    crossId: undefined,
    comment: data.comment ?? undefined,
    target: data.nft === null ? undefined : parseToNft(data.nft),
    ins: data.transferList
      .filter(({ isIn }) => isIn)
      .map((transfer) => ({
        asset: parseToAsset(transfer, fiatCurrencyList),
        amount: createBigDecimal(transfer.amount),
        price: createBigDecimal(transfer.price),
        zeroCompleted: transfer.zeroCompleted,
        cost: createBigDecimal(transfer.cost),
        balance: createBigDecimal(transfer.balance),
        borrowing: createBigDecimal(transfer.borrowing),
        feePl: createBigDecimal(transfer.feePl),
        incomePl: createBigDecimal(transfer.incomePl),
        tradePl: createBigDecimal(transfer.tradePl),
        totalPl: createBigDecimal(transfer.totalPl),
      })),
    outs: data.transferList
      .filter(({ isIn }) => !isIn)
      .map((transfer) => ({
        asset: parseToAsset(transfer, fiatCurrencyList),
        amount: createBigDecimal(transfer.amount),
        price: createBigDecimal(transfer.price),
        zeroCompleted: transfer.zeroCompleted,
        cost: createBigDecimal(transfer.cost),
        balance: createBigDecimal(transfer.balance),
        borrowing: createBigDecimal(transfer.borrowing),
        feePl: createBigDecimal(transfer.feePl),
        incomePl: createBigDecimal(transfer.incomePl),
        tradePl: createBigDecimal(transfer.tradePl),
        totalPl: createBigDecimal(transfer.totalPl),
      })),
  }
  return transaction
}

const parseToAsset = (
  data: TransactionTransferData,
  fiatCurrencyList: readonly FiatCurrency[],
): CryptoCurrency | FiatCurrency | Nft => {
  if (data.cryptoCurrency !== null) {
    return parseToCryptoCurrency(data.cryptoCurrency)
  }
  if (data.nft !== null) {
    return parseToNft(data.nft)
  }
  if (data.fiatId === null) {
    throw new Error('Invalid action transfer data')
  }
  const fiatCurrency = fiatCurrencyList.find((item) => item.id === data.fiatId)
  if (fiatCurrency === undefined) {
    throw new Error(`Invalid fiat currency id: ${data.fiatId}`)
  }
  return fiatCurrency
}

export const parseTransactionsToInsertData = (
  accountingPeriodId: number,
  transactions: readonly Transaction[],
): {
  readonly transaction: readonly InsertAccountingTransactionData[]
  readonly transfer: readonly InsertAccountingTransactionTransferData[]
  readonly cryptoCurrency: readonly InsertCryptoCurrencyData[]
  readonly nft: readonly InsertNftData[]
} => {
  const mut_insertTransactionData: InsertAccountingTransactionData[] = []
  const mut_insertTransferData: InsertAccountingTransactionTransferData[] = []
  const mut_insertCryptoCurrencyData: InsertCryptoCurrencyData[] = []
  const mut_insertNftData: InsertNftData[] = []

  for (const transaction of transactions) {
    // transaction 追加
    mut_insertTransactionData.push({
      id: `${transaction.actionId}_${transaction.order}`,
      accountingPeriodId,
      category: transaction.category,
      subCategory: transaction.subCategory,
      actionId: transaction.actionId,
      order: transaction.order,
      targetId: transaction.target?.id,
      timestamp: new Date(transaction.timestamp),
      comment: transaction.comment,
      updatedAt: new Date(),
    })

    // 重複がない場合 target nft 追加
    if (
      transaction.target !== undefined &&
      mut_insertNftData.every((item) => item.id !== transaction.target?.id)
    ) {
      mut_insertNftData.push({
        id: transaction.target.id,
        name: transaction.target.name,
        image: transaction.target.image,
        metadata: transaction.target.metadata,
        updatedAt: transaction.target.updatedAt,
      })
    }

    let mut_order = -1
    for (const transfer of transaction.ins) {
      let assetType: 'crypto' | 'fiat' | 'nft'
      switch (transfer.asset.type) {
        case 'CryptoCurrency': {
          assetType = 'crypto'
          break
        }
        case 'FiatCurrency': {
          assetType = 'fiat'
          break
        }
        case 'Nft': {
          assetType = 'nft'
          break
        }
      }
      // transfer 追加
      mut_order += 1
      mut_insertTransferData.push({
        transactionId: `${transaction.actionId}_${transaction.order}`,
        order: mut_order,
        isIn: true,
        cryptoId:
          transfer.asset.type === 'CryptoCurrency'
            ? transfer.asset.id
            : undefined,
        fiatId:
          transfer.asset.type === 'FiatCurrency'
            ? transfer.asset.id
            : undefined,
        nftId: transfer.asset.type === 'Nft' ? transfer.asset.id : undefined,
        amount: toStringBigDecimal(transfer.amount),
        price: toStringBigDecimal(transfer.price),
        zeroCompleted: transfer.zeroCompleted ?? false,
        cost: toStringBigDecimal(transfer.cost),
        balance: toStringBigDecimal(transfer.balance),
        borrowing: toStringBigDecimal(transfer.borrowing),
        totalPl: toStringBigDecimal(transfer.totalPl),
        tradePl: toStringBigDecimal(transfer.tradePl),
        incomePl: toStringBigDecimal(transfer.incomePl),
        feePl: toStringBigDecimal(transfer.feePl),
      })

      // 重複がない場合 nft 追加
      if (
        transfer.asset.type === 'Nft' &&
        mut_insertNftData.every((item) => item.id !== transfer.asset.id)
      ) {
        mut_insertNftData.push({
          id: transfer.asset.id,
          name: transfer.asset.name,
          image: transfer.asset.image,
          metadata: transfer.asset.metadata,
          updatedAt: transfer.asset.updatedAt,
        })
      }

      // 重複がない場合 cryptoCurrency 追加
      if (
        transfer.asset.type === 'CryptoCurrency' &&
        mut_insertCryptoCurrencyData.every(
          (item) => item.id !== transfer.asset.id,
        )
      ) {
        mut_insertCryptoCurrencyData.push({
          id: transfer.asset.id,
          name: transfer.asset.name,
          symbol: transfer.asset.symbol,
          icon: transfer.asset.icon,
          coingeckoId: transfer.asset.market?.coingeckoId,
          marketCapUsd:
            transfer.asset.market?.marketCapUsd === undefined
              ? undefined
              : Math.floor(transfer.asset.market.marketCapUsd),
          priceDatasourceId: transfer.asset.priceDatasourceId,
          updatedAt: transfer.asset.updatedAt,
        })
      }
    }

    for (const transfer of transaction.outs) {
      let assetType: 'crypto' | 'fiat' | 'nft'
      switch (transfer.asset.type) {
        case 'CryptoCurrency': {
          assetType = 'crypto'
          break
        }
        case 'FiatCurrency': {
          assetType = 'fiat'
          break
        }
        case 'Nft': {
          assetType = 'nft'
          break
        }
      }
      // transfer 追加
      mut_order += 1
      mut_insertTransferData.push({
        transactionId: `${transaction.actionId}_${transaction.order}`,
        order: mut_order,
        isIn: false,
        cryptoId:
          transfer.asset.type === 'CryptoCurrency'
            ? transfer.asset.id
            : undefined,
        fiatId:
          transfer.asset.type === 'FiatCurrency'
            ? transfer.asset.id
            : undefined,
        nftId: transfer.asset.type === 'Nft' ? transfer.asset.id : undefined,
        amount: toStringBigDecimal(transfer.amount),
        price: toStringBigDecimal(transfer.price),
        zeroCompleted: transfer.zeroCompleted ?? false,
        cost: toStringBigDecimal(transfer.cost),
        balance: toStringBigDecimal(transfer.balance),
        borrowing: toStringBigDecimal(transfer.borrowing),
        totalPl: toStringBigDecimal(transfer.totalPl),
        tradePl: toStringBigDecimal(transfer.tradePl),
        incomePl: toStringBigDecimal(transfer.incomePl),
        feePl: toStringBigDecimal(transfer.feePl),
      })

      // 重複がない場合 nft 追加
      if (
        transfer.asset.type === 'Nft' &&
        mut_insertNftData.every((item) => item.id !== transfer.asset.id)
      ) {
        mut_insertNftData.push({
          id: transfer.asset.id,
          name: transfer.asset.name,
          image: transfer.asset.image,
          metadata: transfer.asset.metadata,
          updatedAt: transfer.asset.updatedAt,
        })
      }

      // 重複がない場合 cryptoCurrency 追加
      if (
        transfer.asset.type === 'CryptoCurrency' &&
        mut_insertCryptoCurrencyData.every(
          (item) => item.id !== transfer.asset.id,
        )
      ) {
        mut_insertCryptoCurrencyData.push({
          id: transfer.asset.id,
          name: transfer.asset.name,
          symbol: transfer.asset.symbol,
          icon: transfer.asset.icon,
          coingeckoId: transfer.asset.market?.coingeckoId,
          marketCapUsd:
            transfer.asset.market?.marketCapUsd === undefined
              ? undefined
              : Math.floor(transfer.asset.market.marketCapUsd),
          priceDatasourceId: transfer.asset.priceDatasourceId,
          updatedAt: transfer.asset.updatedAt,
        })
      }
    }
  }

  return {
    transaction: mut_insertTransactionData,
    transfer: mut_insertTransferData,
    cryptoCurrency: mut_insertCryptoCurrencyData,
    nft: mut_insertNftData,
  }
}
