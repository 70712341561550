import type { Transaction } from '@0xtorch/accounting'
import { divideArrayIntoChunks } from '@pkg/basic'
import { inArray } from 'drizzle-orm'
import {
  accountingTransactionColumnCount,
  accountingTransactionTransferColumnCount,
  sqliteMaxHostParameterCount,
} from '../constants'
import { transactionsToInsertSqls } from '../createSql/transactionsToInsertSqls'
import {
  accountingTransactionTable,
  accountingTransactionTransferTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'
import { getMaxInsertRowCount } from '../utils'

type InsertAccountingTransactionsParameters = {
  database: DatabaseWithTransaction
  accountingPeriodId: number
  transactions: readonly Transaction[]
}

export const insertAccountingTransactions = async ({
  database: { transaction },
  accountingPeriodId,
  transactions,
}: InsertAccountingTransactionsParameters) => {
  const { transactionSqls, transferSqls, transactionIds } =
    transactionsToInsertSqls(transactions, accountingPeriodId)
  await transaction([
    (tx) => [
      ...divideArrayIntoChunks(transactionIds, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx
            .delete(accountingTransactionTransferTable)
            .where(
              inArray(accountingTransactionTransferTable.transactionId, [
                ...chunk,
              ]),
            ),
      ),
    ],
    (tx) => [
      ...divideArrayIntoChunks(
        transactionSqls,
        getMaxInsertRowCount(accountingTransactionColumnCount),
      ).map((chunk) =>
        tx.insert(accountingTransactionTable).values([...chunk]),
      ),
      ...divideArrayIntoChunks(
        transferSqls,
        getMaxInsertRowCount(accountingTransactionTransferColumnCount),
      ).map((chunk) =>
        tx.insert(accountingTransactionTransferTable).values([...chunk]),
      ),
    ],
  ])
}
