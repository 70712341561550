import { actionTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type GetOldestActionTimestampParameters = {
  readonly database: DatabaseWithTransaction
}

export const getOldestActionTimestamp = async ({
  database: { database },
}: GetOldestActionTimestampParameters): Promise<number | undefined> => {
  const data = await database
    .select()
    .from(actionTable)
    .orderBy(actionTable.timestamp)
    .limit(1)
  if (data.length === 0) {
    return undefined
  }
  return data[0].timestamp.getTime()
}
