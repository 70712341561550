import type { CrossActionBundle } from '@0xtorch/accounting'
import type { FiatCurrency } from '@0xtorch/core'
import { sql } from 'drizzle-orm'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import {
  type actionTable,
  type crossActionBundleRelationTable,
  crossActionBundleTable,
  type nftTable,
} from '../schema'
import { parseToAction } from './action'
import type { ActionTransferData } from './actionTransfer'

type ActionData = typeof actionTable.$inferSelect & {
  actionTransferTable: ActionTransferData[]
  nftTable: typeof nftTable.$inferSelect | null
}

type CrossActionBundleData = typeof crossActionBundleTable.$inferSelect & {
  crossActionBundleRelationList: (typeof crossActionBundleRelationTable.$inferSelect & {
    action: ActionData
  })[]
}

export const parseToCrossActionBundle = (
  data: CrossActionBundleData,
  fiatCurrencyList: readonly FiatCurrency[],
  fiatCurrency: FiatCurrency,
): CrossActionBundle => ({
  id: data.crossActionBundleId,
  actions: data.crossActionBundleRelationList
    .filter((row) => row.action !== null)
    .map((row) => ({
      ...parseToAction(row.action, fiatCurrencyList, fiatCurrency),
      locked: undefined,
      generatedTx: undefined,
    })),
})

export const parseCrossActionBundlesToInsertData = (
  periodId: number,
  crossActionBundles: readonly CrossActionBundle[],
): {
  readonly crossActionBundle: readonly SQLiteInsertValue<
    typeof crossActionBundleTable
  >[]
  readonly crossActionBundleRelation: readonly SQLiteInsertValue<
    typeof crossActionBundleRelationTable
  >[]
} => {
  const crossActionBundle: SQLiteInsertValue<typeof crossActionBundleTable>[] =
    []
  const crossActionBundleRelation: SQLiteInsertValue<
    typeof crossActionBundleRelationTable
  >[] = []

  for (const bundle of crossActionBundles) {
    crossActionBundle.push({
      accountingPeriodId: periodId,
      crossActionBundleId: bundle.id,
    })
    const crossActionBundleIdSql = sql`(select ${crossActionBundleTable.id} from ${crossActionBundleTable} where (${crossActionBundleTable.accountingPeriodId} = ${periodId} and ${crossActionBundleTable.crossActionBundleId} = ${bundle.id}))`
    for (const action of bundle.actions) {
      crossActionBundleRelation.push({
        actionId: `${action.source}_${action.order}`,
        crossActionBundleId: crossActionBundleIdSql,
      })
    }
  }

  return {
    crossActionBundle,
    crossActionBundleRelation,
  }
}
