import { portfolioTable } from '../schema'
import type { DatabaseWithTransaction, InsertPortfolioData } from '../types'

type InsertPortfolioParameters = {
  readonly database: DatabaseWithTransaction
  readonly data: InsertPortfolioData
}

export const insertPortfolio = async ({
  database: { database },
  data,
}: InsertPortfolioParameters) =>
  await database
    .insert(portfolioTable)
    .values({
      ...data,
    })
    .execute()
