type AnalyzerJson = {
  readonly programId: string
  readonly json: unknown
}

export const createAssetsMiddleware = (() => {
  const mut_analyzerJsonesCache: AnalyzerJson[] = []

  return (apiEndpoint: string) => ({
    getAnalyzerJson: async (programId: string) => {
      const cached = mut_analyzerJsonesCache.find(
        (item) => item.programId === programId,
      )
      if (cached !== undefined) {
        return cached.json
      }
      const response = await fetch(
        `${apiEndpoint}/solanas/analyzers/${programId}.json`,
      )
      if (!response.ok) {
        if (response.status === 404) {
          mut_analyzerJsonesCache.push({ programId, json: undefined })
        }
        return undefined
      }
      const json: unknown = await response.json()
      mut_analyzerJsonesCache.push({ programId, json })
      return json
    },
  })
})()
