import { ClerkProvider } from '@clerk/clerk-react'
import { jaJP } from '@clerk/localizations'
import { dark } from '@clerk/themes'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from './themeProvider'

const localizationJaJp = {
  ...jaJP,
  signIn: {
    ...jaJP.signIn,
    start: {
      ...jaJP.signIn?.start,
      actionLink__join_waitlist: 'ウェイトリストに参加',
      actionText__join_waitlist: 'アカウント未登録の場合、',
    },
  },
  waitlist: {
    start: {
      actionLink: 'サインイン',
      actionText: 'アカウントをお持ちですか？',
      formButton: 'ウェイトリストに参加する',
      subtitle: 'ウェイトリストに登録したいメールアドレスを入力してください',
      title: 'ウェイトリストに参加',
    },
    success: {
      message: 'まもなくリダイレクトされます',
      subtitle: '準備ができ次第、ご連絡いたします',
      title: 'ウェイトリストへの参加ありがとうございます！',
    },
  },
}

export const AuthProvider = (props: {
  publishableKey: string
  children: ReactNode
}) => {
  const { publishableKey, children } = props

  const { theme } = useTheme()
  const { i18n } = useTranslation()

  return (
    <ClerkProvider
      publishableKey={publishableKey}
      appearance={
        theme === 'dark' ||
        (theme === 'system' &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
          ? {
              baseTheme: dark,
            }
          : undefined
      }
      localization={i18n.language === 'ja' ? localizationJaJp : undefined}
    >
      {children}
    </ClerkProvider>
  )
}
