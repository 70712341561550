import type { AssetBorrow } from '@0xtorch/accounting'
import { createBigDecimal } from '@0xtorch/big-decimal'
import type { assetBorrowTable } from '../schema'
import type { AssetBorrows } from '../types'

export const parseToAssetBorrowList = (
  data: readonly (typeof assetBorrowTable.$inferSelect)[],
): readonly AssetBorrows[] => {
  const assetBorrows = new Map<string, AssetBorrow[]>()
  for (const row of data) {
    const borrow: AssetBorrow = {
      value: createBigDecimal(row.value),
      amount: createBigDecimal(row.amount),
      borrowedAmount: createBigDecimal(row.borrowedAmount),
      borrowedValue: createBigDecimal(row.borrowedValue),
    }
    const existedBorrows = assetBorrows.get(row.asset)
    if (existedBorrows === undefined) {
      assetBorrows.set(row.asset, [borrow])
    } else {
      existedBorrows.push(borrow)
      assetBorrows.set(row.asset, existedBorrows)
    }
  }
  return [...assetBorrows].map(([asset, borrows]) => ({
    asset,
    borrows,
  }))
}
