import { apiEndpoint } from '@/environment'
import type { Portfolio, PortfolioPageData } from '@/types'
import {
  compress,
  createReadFromDatabase,
  createWriteToDatabase,
  getDatabase,
} from '@/utils'
import {
  type DatabaseWithTransaction,
  type Portfolio as PortfolioDatabase,
  insertPortfolio,
} from '@pkg/database-portfolio'
import { SQLocal } from 'sqlocal'
import { createPortfolioDbFileName } from '../utils'
import { migration } from './migration'

export const registerNewPortfolio = async (params: {
  portfolioCount: number
  token: string
}): Promise<PortfolioPageData> => {
  const { portfolioCount, token } = params

  // ID 生成
  const uuid = crypto.randomUUID().slice(0, 13).replace(/-/g, '')
  const name =
    portfolioCount === 0 ? 'Portfolio' : `Portfolio${portfolioCount + 1}`

  // portfolio 作成
  const { database } = getDatabase(uuid)
  const readFromDatabase = createReadFromDatabase(database)
  const writeToDatabase = createWriteToDatabase(database)
  await migration({ readFromDatabase, writeToDatabase })
  await initPortfolio({ database, uuid, name })

  const { getDatabaseFile, destroy } = new SQLocal(
    createPortfolioDbFileName(uuid),
  )

  try {
    // portfolio 登録 API call
    const file = await getDatabaseFile()
    // 圧縮 (gzip)
    const gzipFile = await compress(file)
    const body = new FormData()
    body.append('file', gzipFile)
    const timestamp = Date.now()
    const url = new URL('/v1/portfolio', apiEndpoint)
    url.searchParams.set('name', name)
    url.searchParams.set('id', uuid)
    url.searchParams.set('timestamp', '0')
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
    if (!response.ok) {
      throw new Error(
        `Failed to create portfolio: ${response.status} ${response.statusText}`,
      )
    }

    const portfolio: Portfolio = {
      uuid,
      name,
      updatedAt: timestamp,
      lastUsedAt: timestamp,
      // TODO ベータ版に限り自動で mini plan を付与する
      // plan: 'free',
      plan: 'mini',
      isWriter: true,
      isOwner: true,
    }
    return {
      ...portfolio,
      readonly: false,
      readFromDatabase: createReadFromDatabase(database),
      writeToDatabase: createWriteToDatabase(database),
    }
  } finally {
    await destroy()
  }
}

const initPortfolio = async (params: {
  database: DatabaseWithTransaction
  uuid: string
  name: string
}) => {
  const { database, uuid, name } = params

  // TODO ベータ版では Asia/Tokyo 以外は選択できないようにする
  // TODO 後日 Intl API で取得したタイムゾーンによって設定を変える
  const portfolioLocale = createPortfolioLocaleJp('Asia/Tokyo')
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let portfolioLocale: PortfolioLocale
  // switch (timezone) {
  //   case 'Asia/Tokyo': {
  //     portfolioLocale = createPortfolioLocaleJp(timezone)
  //     break
  //   }
  //   default: {
  //     portfolioLocale = createPortfolioLocaleDefault(timezone)
  //     break
  //   }
  // }

  await insertPortfolio({
    database,
    data: {
      ...portfolioLocale,
      id: uuid,
      name,
      needPeriodEndEvaluation: false,
      isTxGenerated: true,
    },
  })
}

type PortfolioLocale = Pick<
  PortfolioDatabase,
  'fiat' | 'timezone' | 'startMonth' | 'costBasis'
>

const createPortfolioLocaleJp = (timezone: string): PortfolioLocale => ({
  fiat: 'jpy',
  timezone,
  startMonth: 1,
  costBasis: 'WACB',
})

const createPortfolioLocaleDefault = (timezone: string): PortfolioLocale => ({
  fiat: 'usd',
  timezone,
  startMonth: 1,
  costBasis: 'FIFO',
})
