import type { Portfolio } from '@/types'
import { apiClient } from '../../constants'

export const getPortfolios = async (params: {
  token: string
}): Promise<readonly Portfolio[]> => {
  const { token } = params

  const response = await apiClient.v1.portfolio.$get(undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!response.ok) {
    throw new Error(
      `Failed to get portfolios: ${response.status} ${response.statusText}`,
    )
  }
  return await response.json()
}
