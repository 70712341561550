import { eq, inArray, sql } from 'drizzle-orm'
import {
  accountingTransactionTable,
  accountingTransactionTransferTable,
  assetBalanceTable,
  assetBorrowTable,
  crossActionBundleRelationTable,
  crossActionBundleTable,
  loanBorrowTable,
  loanBorrowWithDebtPositionTable,
  loanBorrowWithDebtTable,
  loanDepositTable,
  loanDepositWithBondPositionTable,
  loanDepositWithBondTable,
  loanTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'

type ResetAccountingPeriodDataParameters = {
  database: DatabaseWithTransaction
  accountingPeriodId: number
}

export const resetAccountingPeriodData = async ({
  database: { transaction },
  accountingPeriodId,
}: ResetAccountingPeriodDataParameters) => {
  await transaction([
    (tx) => [
      // accounting transaction transfer
      tx
        .delete(accountingTransactionTransferTable)
        .where(
          inArray(
            accountingTransactionTransferTable.transactionId,
            sql`(SELECT ${accountingTransactionTable.id} FROM ${accountingTransactionTable} WHERE ${accountingTransactionTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // loan deposit with bond position
      tx
        .delete(loanDepositWithBondPositionTable)
        .where(
          inArray(
            loanDepositWithBondPositionTable.loanId,
            sql`(SELECT ${loanTable.id} FROM ${loanTable} WHERE ${loanTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // loan deposit with bond
      tx
        .delete(loanDepositWithBondTable)
        .where(
          inArray(
            loanDepositWithBondTable.loanId,
            sql`(SELECT ${loanTable.id} FROM ${loanTable} WHERE ${loanTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // loan deposit
      tx
        .delete(loanDepositTable)
        .where(
          inArray(
            loanDepositTable.loanId,
            sql`(SELECT ${loanTable.id} FROM ${loanTable} WHERE ${loanTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // loan borrow with debt position
      tx
        .delete(loanBorrowWithDebtPositionTable)
        .where(
          inArray(
            loanBorrowWithDebtPositionTable.loanId,
            sql`(SELECT ${loanTable.id} FROM ${loanTable} WHERE ${loanTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // loan borrow with debt
      tx
        .delete(loanBorrowWithDebtTable)
        .where(
          inArray(
            loanBorrowWithDebtTable.loanId,
            sql`(SELECT ${loanTable.id} FROM ${loanTable} WHERE ${loanTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // loan borrow
      tx
        .delete(loanBorrowTable)
        .where(
          inArray(
            loanBorrowTable.loanId,
            sql`(SELECT ${loanTable.id} FROM ${loanTable} WHERE ${loanTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // cross action bundle relation
      tx
        .delete(crossActionBundleRelationTable)
        .where(
          inArray(
            crossActionBundleRelationTable.crossActionBundleId,
            sql`(SELECT ${crossActionBundleTable.id} FROM ${crossActionBundleTable} WHERE ${crossActionBundleTable.accountingPeriodId} = ${accountingPeriodId})`,
          ),
        ),
      // asset balance
      tx
        .delete(assetBalanceTable)
        .where(eq(assetBalanceTable.accountingPeriodId, accountingPeriodId)),
      // asset borrow
      tx
        .delete(assetBorrowTable)
        .where(eq(assetBorrowTable.accountingPeriodId, accountingPeriodId)),
    ],
    (tx) => [
      // accounting transaction
      tx
        .delete(accountingTransactionTable)
        .where(
          eq(accountingTransactionTable.accountingPeriodId, accountingPeriodId),
        ),
      // loan
      tx
        .delete(loanTable)
        .where(eq(loanTable.accountingPeriodId, accountingPeriodId)),
      // cross action bundle
      tx
        .delete(crossActionBundleTable)
        .where(
          eq(crossActionBundleTable.accountingPeriodId, accountingPeriodId),
        ),
    ],
  ])
}
