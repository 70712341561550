import { toStringBigDecimal } from '@0xtorch/big-decimal'
import { divideArrayIntoChunks } from '@pkg/basic'
import { eq } from 'drizzle-orm'
import { assetValueColumnCount } from '../constants'
import { assetValueTable } from '../schema'
import type { AssetValue, DatabaseWithTransaction } from '../types'
import { conflictUpdateAllExcept, getMaxInsertRowCount } from '../utils'

type UpsertAssetValuesParameters = {
  database: DatabaseWithTransaction
  accountingPeriodId: number
  data: readonly AssetValue[]
}

export const upsertAssetValues = async ({
  database: { transaction },
  accountingPeriodId,
  data,
}: UpsertAssetValuesParameters) => {
  if (data.length === 0) {
    return
  }

  await transaction([
    (tx) => [
      tx
        .delete(assetValueTable)
        .where(eq(assetValueTable.accountingPeriodId, accountingPeriodId)),
    ],
    (tx) => [
      ...divideArrayIntoChunks(
        data,
        getMaxInsertRowCount(assetValueColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(assetValueTable)
          .values(
            chunk.map((v) => ({
              accountingPeriodId: v.accountingPeriodId,
              asset: v.asset,
              type: v.type,
              amount: toStringBigDecimal(v.amount),
              cost: toStringBigDecimal(v.cost),
              value: toStringBigDecimal(v.value),
              isKnownPeriodEndPrice: v.isKnownPeriodEndPrice,
            })),
          )
          .onConflictDoUpdate({
            target: [
              assetValueTable.accountingPeriodId,
              assetValueTable.asset,
              assetValueTable.type,
            ],
            set: conflictUpdateAllExcept(assetValueTable, [
              'accountingPeriodId',
              'asset',
              'type',
            ]),
          }),
      ),
    ],
  ])
}
