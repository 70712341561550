import { nftTable } from '../schema'
import type { DatabaseWithTransaction, InsertNftData } from '../types'
import { conflictUpdateAllExcept } from '../utils'

type UpsertNftsParameters = {
  readonly database: DatabaseWithTransaction
  readonly data: readonly InsertNftData[]
}

export const upsertNfts = async ({
  database: { database },
  data,
}: UpsertNftsParameters) => {
  if (data.length === 0) {
    return
  }

  await database
    .insert(nftTable)
    .values([...data])
    .onConflictDoUpdate({
      target: nftTable.id,
      set: conflictUpdateAllExcept(nftTable, ['id']),
    })
    .execute()
}
