import { eq } from 'drizzle-orm'
import { parseToAssetBorrowList } from '../parsers/assetBorrow'
import { assetBorrowTable } from '../schema'
import type { AssetBorrows, DatabaseWithTransaction } from '../types'

type SelectAssetBorrowsParameters = {
  readonly database: DatabaseWithTransaction
  readonly accountingPeriodId: number
}

export const selectAssetBorrows = async ({
  database: { database },
  accountingPeriodId,
}: SelectAssetBorrowsParameters): Promise<readonly AssetBorrows[]> => {
  const data = await database
    .select()
    .from(assetBorrowTable)
    .where(eq(assetBorrowTable.accountingPeriodId, accountingPeriodId))

  return parseToAssetBorrowList(data)
}
