export const divideArrayIntoChunks = <T>(
  items: readonly T[],
  chunkSize: number,
): readonly (readonly T[])[] => {
  if (items.length === 0) {
    return []
  }
  const mut_chunks: T[][] = []
  for (let index = 0; index < items.length; index += chunkSize) {
    mut_chunks.push(items.slice(index, index + chunkSize))
  }
  return mut_chunks
}
