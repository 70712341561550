import { Outlet, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_layoutPortfolio/dashboard/')({
  validateSearch: (
    search: Record<string, unknown>,
  ): {
    y?: number
  } => {
    const y = Number.parseInt(search.y as string)
    return {
      y: Number.isNaN(y) ? undefined : y,
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <Outlet />
}
