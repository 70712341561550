export const parseTimezoneToHour = (timezone: string): number => {
  // TODO timezone の有効リストを型等で渡して個別適用できるようにする
  switch (timezone) {
    case 'America/New_York': {
      return -5
    }
    case 'Asia/Tokyo':
    case 'Etc/GMT-9': {
      return 9
    }
    case 'UTC': {
      return 0
    }
    default: {
      throw new Error(`unsupported timezone: ${timezone}`)
    }
  }
}

export const timezoneToTimeLocale = (timezone: string): string | undefined => {
  // TODO timezone の有効リストを型等で渡して個別適用できるようにする
  switch (timezone) {
    case 'Asia/Tokyo':
    case 'Etc/GMT-9':
      return 'ja-JP'
    case 'America/New_York':
      return 'en-US'
    default:
      return undefined
  }
}
