import { max } from 'drizzle-orm'
import { cryptoCurrencyTable } from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

type GetCryptoCurrencyLastUpdatedAtParameters = {
  database: DatabaseWithTransaction
}

export const getCryptoCurrencyLastUpdatedAt = async ({
  database: { database },
}: GetCryptoCurrencyLastUpdatedAtParameters): Promise<number> => {
  const rows = await database
    .select({ updatedAt: max(cryptoCurrencyTable.updatedAt) })
    .from(cryptoCurrencyTable)
    .limit(1)
  if (rows.length === 0) {
    return 0
  }
  return rows[0].updatedAt ?? 0
}
