import type { Portfolio } from './types'

const portfolioKey = 'portfolio'
const portfolioUpdatedAtKey = 'portfolio-updated-at'
const portfolioUsedAtKey = 'portfolio-used-at'
const portfolioIsWritableKey = 'portfolio-is-writable'

export const getPortfolioFromLocalStorage = (): Portfolio | undefined => {
  const portfolio = localStorage.getItem(portfolioKey) ?? undefined
  if (portfolio === undefined) {
    return undefined
  }
  return JSON.parse(portfolio)
}

export const setPortfolioToLocalStorage = (
  portfolio: Portfolio | undefined,
) => {
  if (portfolio === undefined) {
    localStorage.removeItem(portfolioKey)
    return
  }
  const portfolioJson = JSON.stringify(portfolio)
  localStorage.setItem(portfolioKey, portfolioJson)
}

export const getPortfolioUpdatedAtFromLocalStorage = (): number | undefined => {
  const portfolioUpdatedAt =
    localStorage.getItem(portfolioUpdatedAtKey) ?? undefined
  if (portfolioUpdatedAt === undefined) {
    return undefined
  }
  return Number.parseInt(portfolioUpdatedAt)
}

export const setPortfolioUpdatedAtToLocalStorage = (
  portfolioUpdatedAt: number | undefined,
) => {
  if (portfolioUpdatedAt === undefined) {
    localStorage.removeItem(portfolioUpdatedAtKey)
    return
  }
  localStorage.setItem(portfolioUpdatedAtKey, portfolioUpdatedAt.toString())
}

export const getPortfolioUsedAtFromLocalStorage = (): number | undefined => {
  const portfolioUsedAt = localStorage.getItem(portfolioUsedAtKey) ?? undefined
  if (portfolioUsedAt === undefined) {
    return undefined
  }
  return Number.parseInt(portfolioUsedAt)
}

export const setPortfolioUsedAtToLocalStorage = (
  portfolioUsedAt: number | undefined,
  onlyIfNewer = false,
) => {
  if (onlyIfNewer) {
    const currentPortfolioUsedAt = getPortfolioUsedAtFromLocalStorage() ?? 0
    if (
      portfolioUsedAt === undefined ||
      portfolioUsedAt <= currentPortfolioUsedAt
    ) {
      return
    }
    localStorage.setItem(portfolioUsedAtKey, portfolioUsedAt.toString())
  } else {
    if (portfolioUsedAt === undefined) {
      localStorage.removeItem(portfolioUsedAtKey)
      return
    }
    localStorage.setItem(portfolioUsedAtKey, portfolioUsedAt.toString())
  }
}

export const getPortfolioIsWritableFromLocalStorage = (): boolean => {
  const portfolioIsWritable =
    localStorage.getItem(portfolioIsWritableKey) ?? 'false'
  return portfolioIsWritable === 'true'
}

export const setPortfolioIsWritableToLocalStorage = (
  portfolioIsWritable: boolean | undefined,
) => {
  if (portfolioIsWritable === undefined) {
    localStorage.removeItem(portfolioIsWritableKey)
    return
  }
  localStorage.setItem(portfolioIsWritableKey, portfolioIsWritable.toString())
}

export const clearPortfolioDataFromLocalStorage = () => {
  localStorage.removeItem(portfolioKey)
  localStorage.removeItem(portfolioUpdatedAtKey)
  localStorage.removeItem(portfolioUsedAtKey)
  localStorage.removeItem(portfolioIsWritableKey)
}
