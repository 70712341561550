import type { Transaction } from '@0xtorch/accounting'
import { toStringBigDecimal } from '@0xtorch/big-decimal'
import { divideArrayIntoChunks } from '@pkg/basic'
import { type SQL, and, eq, inArray } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../constants'
import {
  accountingTransactionTable,
  accountingTransactionTransferTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'

type UpdateAccountingTransactionCostAndPlsParameters = {
  database: DatabaseWithTransaction
  transactions: readonly Transaction[]
}

export const updateAccountingTransactionCostAndPls = async ({
  database: { transaction },
  transactions,
}: UpdateAccountingTransactionCostAndPlsParameters) => {
  const updatedAt = new Date()
  await transaction([
    (tx) => [
      ...divideArrayIntoChunks(
        transactions,
        sqliteMaxHostParameterCount - 1,
      ).map((chunk) =>
        tx
          .update(accountingTransactionTable)
          .set({ updatedAt })
          .where(
            inArray(
              accountingTransactionTable.id,
              chunk.map(
                (transaction) => `${transaction.actionId}_${transaction.order}`,
              ),
            ),
          ),
      ),
      ...transactionsToUpdateTransferSqls(transactions).map(({ set, where }) =>
        tx.update(accountingTransactionTransferTable).set(set).where(where),
      ),
    ],
  ])
}

const transactionsToUpdateTransferSqls = (
  transactions: readonly Transaction[],
): {
  set: {
    cost: string
    totalPl: string
    tradePl: string
    incomePl: string
    feePl: string
  }
  where: SQL<unknown> | undefined
}[] => {
  const sqls: {
    set: {
      cost: string
      totalPl: string
      tradePl: string
      incomePl: string
      feePl: string
    }
    where: SQL<unknown> | undefined
  }[] = []
  for (const tx of transactions) {
    const transactionId = `${tx.actionId}_${tx.order}`
    let order = -1
    for (const transfer of tx.ins) {
      order += 1
      sqls.push({
        set: {
          cost: toStringBigDecimal(transfer.cost),
          totalPl: toStringBigDecimal(transfer.totalPl),
          tradePl: toStringBigDecimal(transfer.tradePl),
          incomePl: toStringBigDecimal(transfer.incomePl),
          feePl: toStringBigDecimal(transfer.feePl),
        },
        where: and(
          eq(accountingTransactionTransferTable.transactionId, transactionId),
          eq(accountingTransactionTransferTable.order, order),
        ),
      })
    }
    for (const transfer of tx.outs) {
      order += 1
      sqls.push({
        set: {
          cost: toStringBigDecimal(transfer.cost),
          totalPl: toStringBigDecimal(transfer.totalPl),
          tradePl: toStringBigDecimal(transfer.tradePl),
          incomePl: toStringBigDecimal(transfer.incomePl),
          feePl: toStringBigDecimal(transfer.feePl),
        },
        where: and(
          eq(accountingTransactionTransferTable.transactionId, transactionId),
          eq(accountingTransactionTransferTable.order, order),
        ),
      })
    }
  }

  return sqls
}
