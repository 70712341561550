import { setPortfolioUsedAtToLocalStorage } from '@/localStorage'
import type { Portfolio } from '@/types'
import { apiClient } from '../constants'

export const updateLastUsedAt = async (params: {
  portfolio: Portfolio
  token: string
}) => {
  const { portfolio, token } = params

  const now = Date.now()
  setPortfolioUsedAtToLocalStorage(now, true)
  const response = await apiClient.v1.portfolio[':id'].use.$post(
    {
      json: {
        timestamp: now,
      },
      param: { id: portfolio.uuid },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (!response.ok) {
    throw new Error(
      `Failed to update lastUsed: ${response.status} ${response.statusText}`,
    )
  }
}
