import type { CryptoCurrency } from '@0xtorch/core'
import { parseToCryptoCurrency } from '../parsers/cryptoCurrency'
import { cryptoCurrencyTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type SelectCryptoCurrenciesParameters = {
  readonly database: DatabaseWithTransaction
}

export const selectCryptoCurrencies = async ({
  database: { database },
}: SelectCryptoCurrenciesParameters): Promise<readonly CryptoCurrency[]> => {
  const data = await database.select().from(cryptoCurrencyTable)
  return data.map((item) => parseToCryptoCurrency(item))
}
