import type { CryptoCurrency, Nft } from '@0xtorch/core'
import type { SplTokenDatasource, SplTokenInfo } from '@0xtorch/solana'
import { createSolanaTokenAssetId } from '@0xtorch/solana'
import { hc } from 'hono/client'
import type { AppType } from 'solana-token'

type CreateSplTokenDatasourceParameters = {
  readonly apiEndpoint: string
  readonly cryptoCurrencies: readonly CryptoCurrency[]
}

// cache
const mut_mintTokens: { [mint: string]: SplTokenInfo | undefined } = {}

export const createSplTokenDatasource = ({
  apiEndpoint,
  cryptoCurrencies,
}: CreateSplTokenDatasourceParameters): SplTokenDatasource => ({
  getTokenByMintAccount: async (address) =>
    await getTokenByMintAccount(address, apiEndpoint, cryptoCurrencies),
})

const getTokenByMintAccount = async (
  address: string,
  apiEndpoint: string,
  cryptoCurrencies: readonly CryptoCurrency[],
) => {
  if (mut_mintTokens[address] !== undefined) {
    return mut_mintTokens[address]
  }

  const apiClient = hc<AppType>(apiEndpoint)
  const response = await apiClient.v1.mint[':address'].$get({
    param: {
      address,
    },
  })
  if (!response.ok) {
    if (response.status === 404) {
      return undefined
    }
    throw new Error(`Failed to get token info: ${response.status}`)
  }

  const token = await response.json()
  const cryptoCurrency =
    token.assetId === undefined || token.assetId === null
      ? undefined
      : cryptoCurrencies.find(
          (cryptoCurrency) => cryptoCurrency.id === token.assetId,
        )
  const asset =
    cryptoCurrency === undefined
      ? createAsset({ address: token.address, assetType: token.assetType })
      : cryptoCurrency
  const tokenInfo: SplTokenInfo = {
    ...token,
    asset,
  }
  mut_mintTokens[address] = tokenInfo
  return tokenInfo
}

const createAsset = ({
  address,
  assetType,
}: {
  readonly address: string
  readonly assetType: 'crypto' | 'nft'
}): CryptoCurrency | Nft => {
  const assetId = createSolanaTokenAssetId({ address })
  switch (assetType) {
    case 'crypto': {
      return {
        type: 'CryptoCurrency',
        id: assetId,
        name: address,
        symbol: '',
        icon: undefined,
        market: {
          coingeckoId: undefined,
          marketCapUsd: undefined,
        },
        priceDatasourceId: undefined,
        updatedAt: 0,
      }
    }
    case 'nft': {
      return {
        type: 'Nft',
        id: assetId,
        name: undefined,
        image: undefined,
        metadata: undefined,
        updatedAt: 0,
      }
    }
  }
}
