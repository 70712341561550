import type {
  CryptoCurrency,
  CryptoCurrencyDataSource,
  FiatCurrency,
} from '@0xtorch/core'
import type {
  AccountExchange,
  AccountExchangeApi,
  InsertAccountExchangeApiData,
  InsertAccountExchangeData,
  InsertSingleActionRulesData,
} from '@pkg/database-portfolio'
import type {
  AccountExchangeWithApi,
  RunToDatabase,
  SaveExchangeApiDataParameters,
} from './types'

const mut_analyzingExchangeAccountListMap: {
  [category: string]: AccountExchangeWithApi[] | undefined
} = {}
const mut_waitingExchangeAccountListMap: {
  [category: string]: AccountExchangeWithApi[] | undefined
} = {}

type AnalyzeExchangeAccountsParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  startTime: number
  category: string
  apiIdList: readonly string[]
  accountList: readonly AccountExchangeWithApi[]
  cryptoCurrencyList: readonly CryptoCurrency[]
  fiatCurrency: FiatCurrency
  fiatCurrencyList: readonly FiatCurrency[]
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  proxyApiEndpoint: string
  saveApiData: (parameters: SaveExchangeApiDataParameters) => Promise<void>
  onStart: (
    accountApiIdSetList: {
      readonly accountId: number
      readonly apiIdSet: Set<string>
    }[],
  ) => void
  onEnd: () => void
  onFetchedCallSize: (values: {
    readonly accountId: number
    readonly apiId: string
    readonly size: number
  }) => void
  onCalled: (values: {
    readonly accountId: number
    readonly apiId: string
  }) => void
  onFetchedApi: (values: {
    readonly accountId: number
    readonly apiId: string
  }) => void
}

export const analyzeExchangeAccounts = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  startTime,
  category,
  apiIdList,
  accountList,
  cryptoCurrencyList,
  fiatCurrency,
  fiatCurrencyList,
  cryptoCurrencyDataSource,
  proxyApiEndpoint,
  saveApiData,
  onStart,
  onEnd,
  onFetchedCallSize,
  onCalled,
  onFetchedApi,
}: AnalyzeExchangeAccountsParameters): Promise<void> => {
  if (mut_analyzingExchangeAccountListMap[category] === undefined) {
    mut_analyzingExchangeAccountListMap[category] = []
  }
  if (mut_waitingExchangeAccountListMap[category] === undefined) {
    mut_waitingExchangeAccountListMap[category] = []
  }

  // 解析中 exchange account に含まれる account は除外する
  const filteredAccountList = accountList.filter(({ id }) =>
    mut_analyzingExchangeAccountListMap[category]?.every(
      (account) => account.id !== id,
    ),
  )

  // 解析中 exchange account が存在する場合、残った account を解析待ち exchange account に入れて終了
  if (mut_analyzingExchangeAccountListMap[category]?.length > 0) {
    for (const account of filteredAccountList) {
      if (
        mut_waitingExchangeAccountListMap[category]?.every(
          ({ id }) => id !== account.id,
        )
      ) {
        const mut_waitingExchangeAccountList =
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          mut_waitingExchangeAccountListMap[category]!
        mut_waitingExchangeAccountList.push(account)
      }
    }
    return
  }

  // 解析中 exchange account に入れて解析開始
  const mut_analyzingExchangeAccountList =
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    mut_analyzingExchangeAccountListMap[category]!
  mut_analyzingExchangeAccountList.push(...filteredAccountList)
  onStart(
    filteredAccountList.map((account) => ({
      accountId: account.id,
      apiIdSet: new Set(apiIdList),
    })),
  )

  try {
    for (const account of filteredAccountList) {
      for (const apiId of apiIdList) {
        // 最新取得済 timestamp から現在時刻までのデータ取得＆保存
        await saveApiData({
          readFromDatabase,
          writeToDatabase,
          getToken,
          startTime,
          account,
          apiId,
          accountApis: account.apiList,
          proxyApiEndpoint,
          cryptoCurrencyList,
          fiatCurrency,
          fiatCurrencyList,
          cryptoCurrencyDataSource,
          onFetchedCallSize: (size) => {
            onFetchedCallSize({ accountId: account.id, apiId, size })
          },
          onCalled: () => {
            onCalled({ accountId: account.id, apiId })
          },
        })

        onFetchedApi({ accountId: account.id, apiId })
      }
    }
  } finally {
    // 処理完了時に解析中 exchange account を空にする
    mut_analyzingExchangeAccountListMap[category] = []

    // 処理完了を callback
    onEnd()

    // 解析待ち exchange account が存在する場合は account list を解析待ち exchange account に変えて再度呼び出す
    // 解析待ち exchange account は空にする
    if (mut_waitingExchangeAccountListMap[category]?.length > 0) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const newAccountList = [...mut_waitingExchangeAccountListMap[category]!]
      mut_waitingExchangeAccountListMap[category] = []
      await analyzeExchangeAccounts({
        readFromDatabase,
        writeToDatabase,
        getToken,
        startTime,
        category,
        apiIdList,
        accountList: newAccountList,
        cryptoCurrencyList,
        fiatCurrency,
        fiatCurrencyList,
        cryptoCurrencyDataSource,
        proxyApiEndpoint,
        saveApiData,
        onStart,
        onEnd,
        onFetchedCallSize,
        onCalled,
        onFetchedApi,
      })
    }
  }
}

export const createRuleDataListBySourceList = (
  sourceList: readonly string[],
): readonly InsertSingleActionRulesData[] => {
  const mut_ruleDataList: InsertSingleActionRulesData[] = []
  for (const source of sourceList) {
    mut_ruleDataList.push(
      // `income` to `receive-from-cex`
      {
        source,
        targetType: 'income',
        newType: 'receive-from-cex',
      },
      // `fee` to `send-to-cex`
      {
        source,
        targetType: 'fee',
        newType: 'send-to-cex',
      },
      // `transfer` to `send-to-cex`
      {
        source,
        targetType: 'transfer',
        newType: 'send-to-cex',
      },
    )
  }
  return mut_ruleDataList
}

type CreateInsertAccountExchangeDataParameters = {
  readonly account: AccountExchange
}

const createInsertAccountExchangeData = ({
  account,
}: CreateInsertAccountExchangeDataParameters):
  | InsertAccountExchangeData
  | undefined => {
  return {
    ...account,
  }
}

type CreateInsertAccountExchangeApiDataParameters = {
  readonly accountExchangeApi: AccountExchangeApi | undefined
  readonly accountId: number
  readonly apiId: string
  readonly from: number
  readonly to: number
}

const createInsertAccountExchangeApiData = ({
  accountExchangeApi,
  accountId,
  apiId,
  from,
  to,
}: CreateInsertAccountExchangeApiDataParameters):
  | InsertAccountExchangeApiData
  | undefined => {
  if (
    accountExchangeApi !== undefined &&
    accountExchangeApi.from.getTime() !== undefined &&
    accountExchangeApi.from.getTime() <= from &&
    accountExchangeApi.to !== undefined &&
    accountExchangeApi.to.getTime() >= to
  ) {
    return undefined
  }

  const minFrom =
    accountExchangeApi === undefined || accountExchangeApi.from === undefined
      ? from
      : Math.min(from, accountExchangeApi.from.getTime())
  const maxTo =
    accountExchangeApi === undefined || accountExchangeApi.to === undefined
      ? to
      : Math.max(to, accountExchangeApi.to.getTime())

  return {
    accountId,
    apiId,
    from: new Date(minFrom),
    to: new Date(maxTo),
  }
}
