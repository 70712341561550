import {
  type LowerHex,
  createEvmActionSource,
  isEvmTransactionSource,
  isHex,
  toLowerHex,
} from '@0xtorch/evm'
import { divideArrayIntoChunks, hexToBlob } from '@pkg/basic'
import { and, eq, inArray, sql } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../constants'
import {
  accountEvmTxIndexRelationTable,
  accountTable,
  actionTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'

type IgnoreLockedActionEvmTxsParameters = {
  readonly database: DatabaseWithTransaction
  readonly chainId: number
  readonly hashes: readonly LowerHex[]
}

export const ignoreLockedActionEvmTxs = async ({
  database: { database, transaction },
  chainId,
  hashes,
}: IgnoreLockedActionEvmTxsParameters): Promise<Set<LowerHex>> => {
  if (hashes.length === 0) {
    return new Set()
  }

  const accountIdRows = await database
    .select({ id: accountTable.id })
    .from(accountTable)
    .where(eq(accountTable.evmChainId, chainId))

  // locked=true の action の source を取得
  const sourceResults = await Promise.all(
    divideArrayIntoChunks(
      hashes.map((hash) =>
        createEvmActionSource({ chainId, transactionHash: hash }),
      ),
      sqliteMaxHostParameterCount - 1,
    ).map((sources) =>
      database
        .selectDistinct({ source: actionTable.sourceId })
        .from(actionTable)
        .where(
          and(
            eq(actionTable.locked, true),
            inArray(actionTable.sourceId, [...sources]),
          ),
        ),
    ),
  )

  // action source を hash list に変換
  const lockedHashSet = new Set<LowerHex>()
  for (const result of sourceResults) {
    for (const { source } of result) {
      if (!isEvmTransactionSource(source)) {
        continue
      }
      const hashText = source.split('_')[2]
      if (!isHex(hashText)) {
        continue
      }
      const hash = toLowerHex(hashText)
      if (lockedHashSet.has(hash)) {
        continue
      }
      lockedHashSet.add(hash)
    }
  }

  // account evm tx index relation を analyzed = true に変更
  await transaction([
    (tx) => [
      ...divideArrayIntoChunks(
        [...lockedHashSet],
        sqliteMaxHostParameterCount - 2,
      ).map((hashes) =>
        tx
          .update(accountEvmTxIndexRelationTable)
          .set({ analyzed: true })
          .where(
            and(
              inArray(
                accountEvmTxIndexRelationTable.accountId,
                tx
                  .select({ id: accountTable.id })
                  .from(accountTable)
                  .where(eq(accountTable.evmChainId, chainId)),
              ),
              inArray(
                accountEvmTxIndexRelationTable.hash,
                hashes.map((hash) => hexToBlob(hash)),
              ),
            ),
          ),
      ),
    ],
    (tx) => [
      // account.syncing を更新
      ...accountIdRows.map(({ id }) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountEvmTxIndexRelationTable} WHERE ${accountEvmTxIndexRelationTable.accountId} = ${id} AND ${accountEvmTxIndexRelationTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, id)),
      ),
    ],
  ])

  // hash list を返す
  return lockedHashSet
}
