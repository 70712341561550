import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from './components/themeProvider'
import './i18n/config'
import './index.css'
import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/react'
import { AuthProvider } from './components/authProvider'
import SyncProvider from './components/syncProvider'
import { setPortfolioIsWritableToLocalStorage } from './localStorage'
import { routeTree } from './routeTree.gen'

init({
  dsn: 'https://2a42bf1b6e79b992ba849bab4a4ff930@o4506517295857664.ingest.us.sentry.io/4507546894401536',
  integrations: [browserTracingIntegration(), replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['app.cryptorch.dev'],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
if (PUBLISHABLE_KEY === undefined || PUBLISHABLE_KEY === '') {
  throw new Error('Missing Publishable Key')
}

const router = createRouter({
  routeTree,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const queryClient = new QueryClient()

setPortfolioIsWritableToLocalStorage(undefined)

createRoot(document.querySelector('#root') as HTMLElement).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider storageKey="vite-ui-theme">
        <AuthProvider publishableKey={PUBLISHABLE_KEY}>
          <SyncProvider>
            <RouterProvider router={router} />
          </SyncProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </StrictMode>,
)
