import {
  clearPortfolioDataFromLocalStorage,
  setPortfolioIsWritableToLocalStorage,
  setPortfolioToLocalStorage,
  setPortfolioUpdatedAtToLocalStorage,
  setPortfolioUsedAtToLocalStorage,
} from '@/localStorage'
import type { Portfolio } from '@/types'
import type { SetupPortfolioReturnTypes } from '.'
import { registerNewPortfolio } from '../../logic/registerNewPortfolio'
import { syncAfterSetup } from '../../logic/syncAfterSetup'

export const createPortfolio = async (params: {
  token: string
  portfolios: readonly Portfolio[]
}): Promise<SetupPortfolioReturnTypes> => {
  const { token, portfolios } = params

  // 新規 DB 作成・登録処理
  const portfolio = await registerNewPortfolio({
    portfolioCount: portfolios.length,
    token,
  })

  // local storage 更新
  clearPortfolioDataFromLocalStorage()
  setPortfolioToLocalStorage(portfolio)
  setPortfolioUpdatedAtToLocalStorage(portfolio.updatedAt)
  setPortfolioUsedAtToLocalStorage(portfolio.lastUsedAt)
  setPortfolioIsWritableToLocalStorage(true)

  // sync 処理（完了を待たない）
  syncAfterSetup({
    ...portfolio,
    token,
  })

  return {
    portfolios: [...portfolios, portfolio],
    portfolio,
  }
}
