import type { Transaction } from '@0xtorch/accounting'
import { toStringBigDecimal } from '@0xtorch/big-decimal'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import type {
  accountingTransactionTable,
  accountingTransactionTransferTable,
} from '../schema'

type TransactionsToInsertSqlsReturnTypes = {
  transactionSqls: readonly SQLiteInsertValue<
    typeof accountingTransactionTable
  >[]
  transferSqls: readonly SQLiteInsertValue<
    typeof accountingTransactionTransferTable
  >[]
  transactionIds: readonly string[]
}

export const transactionsToInsertSqls = (
  transactions: readonly Transaction[],
  accountingPeriodId: number,
): TransactionsToInsertSqlsReturnTypes => {
  const transactionIds = new Set<string>()
  const transactionSqls: SQLiteInsertValue<
    typeof accountingTransactionTable
  >[] = []
  const transferSqls: SQLiteInsertValue<
    typeof accountingTransactionTransferTable
  >[] = []

  const updatedAt = new Date()

  for (const transaction of transactions) {
    const txId = `${transaction.actionId}_${transaction.order}`
    transactionIds.add(txId)
    transactionSqls.push({
      id: txId,
      accountingPeriodId,
      actionId: transaction.actionId,
      order: transaction.order,
      category: transaction.category,
      subCategory: transaction.subCategory,
      targetId: transaction.target?.id,
      timestamp: new Date(transaction.timestamp),
      comment: transaction.comment,
      updatedAt,
    })

    let order = -1
    for (const transfer of transaction.ins) {
      order += 1
      transferSqls.push({
        transactionId: txId,
        order,
        isIn: true,
        cryptoId:
          transfer.asset.type === 'CryptoCurrency'
            ? transfer.asset.id
            : undefined,
        fiatId:
          transfer.asset.type === 'FiatCurrency'
            ? transfer.asset.id
            : undefined,
        nftId: transfer.asset.type === 'Nft' ? transfer.asset.id : undefined,
        amount: toStringBigDecimal(transfer.amount),
        price: toStringBigDecimal(transfer.price),
        zeroCompleted: transfer.zeroCompleted,
        cost: toStringBigDecimal(transfer.cost),
        balance: toStringBigDecimal(transfer.balance),
        borrowing: toStringBigDecimal(transfer.borrowing),
        totalPl: toStringBigDecimal(transfer.totalPl),
        tradePl: toStringBigDecimal(transfer.tradePl),
        incomePl: toStringBigDecimal(transfer.incomePl),
        feePl: toStringBigDecimal(transfer.feePl),
      })
    }
    for (const transfer of transaction.outs) {
      order += 1
      transferSqls.push({
        transactionId: txId,
        order,
        isIn: false,
        cryptoId:
          transfer.asset.type === 'CryptoCurrency'
            ? transfer.asset.id
            : undefined,
        fiatId:
          transfer.asset.type === 'FiatCurrency'
            ? transfer.asset.id
            : undefined,
        nftId: transfer.asset.type === 'Nft' ? transfer.asset.id : undefined,
        amount: toStringBigDecimal(transfer.amount),
        price: toStringBigDecimal(transfer.price),
        zeroCompleted: transfer.zeroCompleted,
        cost: toStringBigDecimal(transfer.cost),
        balance: toStringBigDecimal(transfer.balance),
        borrowing: toStringBigDecimal(transfer.borrowing),
        totalPl: toStringBigDecimal(transfer.totalPl),
        tradePl: toStringBigDecimal(transfer.tradePl),
        incomePl: toStringBigDecimal(transfer.incomePl),
        feePl: toStringBigDecimal(transfer.feePl),
      })
    }
  }

  return {
    transactionSqls,
    transferSqls,
    transactionIds: [...transactionIds],
  }
}
