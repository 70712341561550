import { evmChains } from '@/constants/evmChain'
import {
  evmTokenApiEndpoint,
  priceDataSourceApiEndpoint,
  splTokenApiEndpoint,
} from '@/environment'
import type { RunToDatabase } from '@/types'
import { type LowerHex, isErc721NftId, isErc1155NftId } from '@0xtorch/evm'
import { isSolanaNftId } from '@0xtorch/solana'
import { divideArrayIntoChunks, hexToBlob } from '@pkg/basic'
import {
  type AccountEvm,
  type InsertAccountEvmData,
  getCryptoCurrencyLastUpdatedAt,
  selectAccounts,
  selectNfts,
  upsertAccountsEvm,
  upsertCryptoCurrencies,
  upsertNfts,
} from '@pkg/database-portfolio'
import type { AppType } from 'crypto-currency-worker'
import type { AppType as EvmAppType } from 'evm-token-worker'
import { hc } from 'hono/client'
import type { AppType as SolanaAppType } from 'solana-token'

export const syncDatabase = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  // Sync remote data to local DB
  await Promise.all([
    createNewEvmChainAccounts({ readFromDatabase, writeToDatabase }),
    syncCryptoCurrencies({ readFromDatabase, writeToDatabase }),
    syncNfts({ readFromDatabase, writeToDatabase }),
  ])
}

const createNewEvmChainAccounts = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  const accounts = await readFromDatabase((database) =>
    selectAccounts({
      database,
    }),
  )
  const evmAddresses = new Set<LowerHex>()
  const evmAccountMap = new Map<LowerHex, AccountEvm[]>()
  for (const account of accounts) {
    if (account.type === 'evm') {
      evmAddresses.add(account.evmAddress)
      evmAccountMap.set(account.evmAddress, [
        ...(evmAccountMap.get(account.evmAddress) ?? []),
        account,
      ])
    }
  }
  const newAccounts: InsertAccountEvmData[] = []
  for (const [address, evmAccounts] of evmAccountMap.entries()) {
    if (evmAccounts.length === 0) {
      continue
    }
    const account = evmAccounts[0]
    for (const chain of evmChains) {
      if (evmAccounts.some((account) => account.evmChainId === chain.id)) {
        continue
      }
      newAccounts.push({
        group: address,
        name: account.name,
        type: 'evm',
        category: account.category,
        evmChainId: chain.id,
        evmAddress: hexToBlob(address),
      })
    }
  }
  if (newAccounts.length === 0) {
    return
  }
  await writeToDatabase((database) =>
    upsertAccountsEvm({
      database,
      data: newAccounts,
    }),
  )
}

const syncCryptoCurrencies = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  // 全 currency の最終更新時間を取得
  const lastUpdatedAt = await readFromDatabase((database) =>
    getCryptoCurrencyLastUpdatedAt({ database }),
  )
  // 最終更新から 24 時間以上経過していない場合は更新しない
  const now = Date.now()
  if (now - lastUpdatedAt < 24 * 60 * 60 * 1000) {
    return
  }
  const client = hc<AppType>(priceDataSourceApiEndpoint)
  const response = await client.v1.list.$get({
    query: {
      after: (lastUpdatedAt + 1).toString(),
    },
  })
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`)
  }
  const cryptoCurrencies = await response.json()
  if (cryptoCurrencies.length === 0) {
    return
  }
  await writeToDatabase((database) =>
    upsertCryptoCurrencies({
      database,
      data: cryptoCurrencies.map((currency) => ({
        id: currency.id,
        name: currency.name,
        symbol: currency.symbol,
        icon: currency.icon ?? null,
        coingeckoId: currency.market?.coingeckoId ?? null,
        marketCapUsd: currency.market?.marketCapUsd ?? null,
        priceDatasourceId: currency.priceDatasourceId ?? null,
        updatedAt: currency.updatedAt,
      })),
    }),
  )
}

const syncNfts = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params

  // DB から updatedAt=0 の NFT 取得
  const zeroTimeNftList = await readFromDatabase((database) =>
    selectNfts({
      database,
      before: 0,
    }),
  )
  if (zeroTimeNftList.length === 0) {
    return
  }
  const nftIdList = zeroTimeNftList.map((nft) => nft.id)

  // EVM 系 NFT ID を抽出
  const evmNftIdList = nftIdList.filter(
    (id) => isErc721NftId(id) || isErc1155NftId(id),
  )

  // Solana 系 NFT ID を抽出
  const solanaNftIdList = nftIdList.filter((id) => isSolanaNftId(id))

  if (evmNftIdList.length === 0 && solanaNftIdList.length === 0) {
    return
  }

  // API から NFT データ取得
  const [evmNfts, solanaNfts] = await Promise.all([
    fetchEvmNonZeroTimeNfts(evmNftIdList),
    fetchSolanaNonZeroTimeNfts(solanaNftIdList),
  ])

  if (evmNfts.length === 0 && solanaNfts.length === 0) {
    return
  }

  await writeToDatabase((database) =>
    upsertNfts({
      database,
      data: [...evmNfts, ...solanaNfts],
    }),
  )
}

const fetchEvmNonZeroTimeNfts = async (idList: readonly string[]) => {
  if (idList.length === 0) {
    return []
  }

  const client = hc<EvmAppType>(evmTokenApiEndpoint)
  const idListChunkList = divideArrayIntoChunks(idList, 40)
  const results = await Promise.all(
    idListChunkList.map((idListChunk) =>
      (async () => {
        const response = await client.v1.nft.$get({
          query: {
            after: '1',
            id: [...idListChunk],
          },
        })
        if (!response.ok) {
          throw new Error(
            `Failed to fetch NFT data | ${response.status}: ${response.statusText}`,
          )
        }
        return await response.json()
      })(),
    ),
  )
  return results.flat()
}

const fetchSolanaNonZeroTimeNfts = async (idList: readonly string[]) => {
  if (idList.length === 0) {
    return []
  }

  const client = hc<SolanaAppType>(splTokenApiEndpoint)
  const idListChunkList = divideArrayIntoChunks(idList, 40)
  const results = await Promise.all(
    idListChunkList.map((idListChunk) =>
      (async () => {
        const response = await client.v1.nft.$get({
          query: {
            after: '1',
            id: [...idListChunk],
          },
        })
        if (!response.ok) {
          throw new Error(
            `Failed to fetch NFT data | ${response.status}: ${response.statusText}`,
          )
        }
        return await response.json()
      })(),
    ),
  )
  return results.flat()
}
