import type {
  AssetBalance,
  AssetBorrow,
  CrossActionBundle,
  Loan,
  Transaction,
  calculateTransactionPlByWacb,
  createAssetBalancesForWacb,
  createTransactions,
} from '@0xtorch/accounting'
import { type BigDecimal, createBigDecimal, plus } from '@0xtorch/big-decimal'
import type { FiatCurrency } from '@0xtorch/core'
import type {
  AccountingPeriod,
  DatabaseWithTransaction,
  Portfolio,
} from '@pkg/database-portfolio'
import {
  existAction,
  insertAccountingTransactions,
  resetAccountingPeriodData,
  saveAccountingPeriodData,
  selectActions,
  selectAssetBalances,
  selectAssetBorrows,
  selectCrossActionBundles,
  selectLoans,
  selectTransactions,
  updateAccountingTransactionCostAndPls,
} from '@pkg/database-portfolio'

const maxOnceItemCount = 10_000

type GenerateAccountingTransactionListOfAccountingPeriodParameters = {
  readonly database: DatabaseWithTransaction
  readonly portfolio: Portfolio
  readonly accountingPeriod: AccountingPeriod
  readonly fiatCurrency: FiatCurrency
  readonly fiatCurrencyList: readonly FiatCurrency[]
  readonly forceUpdate: boolean
  readonly createTransactions: (
    parameters: Parameters<typeof createTransactions>['0'],
  ) => Promise<ReturnType<typeof createTransactions>>
  readonly createAssetBalancesForWacb: (
    parameters: Parameters<typeof createAssetBalancesForWacb>['0'],
  ) => Promise<ReturnType<typeof createAssetBalancesForWacb>>
  readonly calculateTransactionPlByWacb: (
    parameters: Parameters<typeof calculateTransactionPlByWacb>['0'],
  ) => Promise<ReturnType<typeof calculateTransactionPlByWacb>>
}

export const generateAccountingTransactionListOfAccountingPeriod = async ({
  database,
  portfolio,
  accountingPeriod,
  fiatCurrencyList,
  fiatCurrency,
  forceUpdate,
  createTransactions,
  createAssetBalancesForWacb,
  calculateTransactionPlByWacb,
}: GenerateAccountingTransactionListOfAccountingPeriodParameters): Promise<boolean> => {
  // forceUpdate = false かつ、会計期間内に transaction 未生成の action が存在しない場合は対象会計期間の Transaction は更新せず、処理を終了する
  if (!forceUpdate) {
    const exist = await existAction({
      database,
      from: accountingPeriod.start,
      to: accountingPeriod.end,
      generatedTx: false,
    })
    if (!exist) {
      return false
    }
  }

  // 会計期間内の会計関連データを削除
  await resetAccountingPeriodData({
    database,
    accountingPeriodId: accountingPeriod.id,
  })

  // DB から前会計期末の Loan , Cross Action Bundle, Asset Balance List , Asset Borrow List data 取得
  const [
    previousLoans,
    previousCrossActionBundles,
    previousAssetBalances,
    previousAssetBorrows,
  ] = await Promise.all([
    selectLoans({
      database,
      accountingPeriodId: accountingPeriod.id - 1,
    }),
    selectCrossActionBundles({
      database,
      accountingPeriodId: accountingPeriod.id - 1,
      fiatCurrency,
      fiatCurrencyList,
    }),
    selectAssetBalances({
      database,
      accountingPeriodId: accountingPeriod.id - 1,
    }),
    selectAssetBorrows({
      database,
      accountingPeriodId: accountingPeriod.id - 1,
    }),
  ])

  // Transaction 生成処理
  let loans = new Map<string, Loan>(
    previousLoans.map((loan) => [loan.id, loan]),
  )
  let crossActionBundles = new Map<string, CrossActionBundle>(
    previousCrossActionBundles.map((crossActionBundle) => [
      crossActionBundle.id,
      crossActionBundle,
    ]),
  )
  let assetBalances = new Map<string, readonly AssetBalance[]>(
    previousAssetBalances.map((assetBalance) => [
      assetBalance.asset,
      assetBalance.balances,
    ]),
  )
  let assetBorrows = new Map<string, readonly AssetBorrow[]>(
    previousAssetBorrows.map((assetBorrow) => [
      assetBorrow.asset,
      assetBorrow.borrows,
    ]),
  )
  let usedBalances = new Map<string, AssetBalance>()

  // 最大件数毎に Action 取得して Transaction 生成→保存処理を繰り返す
  const actionIds = new Set<string>()
  let from = accountingPeriod.start
  while (true) {
    const transactions: Transaction[] = []
    const actions = await selectActions({
      database,
      from,
      to: accountingPeriod.end,
      limit: maxOnceItemCount,
      fiatCurrency,
      fiatCurrencyList,
    })
    for (const action of actions) {
      const actionId = `${action.source}_${action.order}`
      if (actionIds.has(actionId)) {
        continue
      }
      actionIds.add(actionId)
      const result = await createTransactions({
        actionId,
        action,
        loans,
        crossActionBundles,
        assetBalances,
        assetBorrows,
        usedBalances,
        costBasis: portfolio.costBasis,
        isLocked: false,
        handleLoanAsTrade: portfolio.handleLoanAsTrade,
        handleReplaceAsTrade: portfolio.handleReplaceAsTrade,
        // 期末時価評価を行う場合、（無償）譲渡を損失 (feePL) として処理する
        handleTransferAsLoss: portfolio.needPeriodEndEvaluation,
        use5PercentRule: portfolio.use5PercentRule,
      })
      transactions.push(...result.transactions)
      loans = result.loans
      crossActionBundles = result.crossActionBundles
      assetBalances = result.assetBalances
      assetBorrows = result.assetBorrows
      usedBalances = result.usedBalances
      // TODO 進捗状況の更新
    }
    // transaction 保存
    await insertAccountingTransactions({
      database,
      accountingPeriodId: accountingPeriod.id,
      transactions,
    })
    if (actions.length < maxOnceItemCount) {
      break
    }
    from = actions[actions.length - 1].timestamp
  }

  // 総平均法の場合、transactions の Cost, PL 更新
  let assetBalanceList: {
    readonly asset: string
    readonly balances: readonly AssetBalance[]
  }[]
  if (portfolio.costBasis === 'WACB') {
    const [prevAssetBalancesForWacb, prevAssetBorrowsForWacb] =
      await Promise.all([
        selectAssetBalances({
          database,
          accountingPeriodId: accountingPeriod.id - 1,
        }),
        selectAssetBorrows({
          database,
          accountingPeriodId: accountingPeriod.id - 1,
        }),
      ])
    let assetBalancesForWacb = new Map<string, BigDecimal>()
    for (const assetBalance of prevAssetBalancesForWacb) {
      const totalAmount = assetBalance.balances.reduce(
        (total, balance) => plus(total, balance.amount),
        createBigDecimal(0n),
      )
      assetBalancesForWacb.set(assetBalance.asset, totalAmount)
    }
    let assetBorrowsForWacb = new Map<string, readonly AssetBorrow[]>(
      prevAssetBorrowsForWacb.map((assetBorrow) => [
        assetBorrow.asset,
        assetBorrow.borrows,
      ]),
    )
    let totalBalances = await createAssetBalancesForWacb({
      assetBalances,
      usedBalances,
    })

    // 最大件数毎に transaction 取得して Cost, PL 更新処理を繰り返す
    let txFrom = accountingPeriod.start
    const transactionIds = new Set<string>()
    while (true) {
      const transactions = await selectTransactions({
        database,
        from: txFrom,
        to: accountingPeriod.end,
        limit: maxOnceItemCount,
        fiats: fiatCurrencyList,
      })
      const updatedTransactions: Transaction[] = []
      for (const transaction of transactions) {
        const transactionId = `${transaction.actionId}_${transaction.order}`
        if (transactionIds.has(transactionId)) {
          continue
        }
        transactionIds.add(transactionId)
        const result = await calculateTransactionPlByWacb({
          transaction,
          assetBalances: assetBalancesForWacb,
          assetBorrows: assetBorrowsForWacb,
          totalBalances,
        })
        updatedTransactions.push(result.transaction)
        assetBalancesForWacb = result.assetBalances
        assetBorrowsForWacb = result.assetBorrows
        totalBalances = result.totalBalances
      }
      // transaction 更新
      await updateAccountingTransactionCostAndPls({
        database,
        transactions: updatedTransactions,
      })
      if (transactions.length < maxOnceItemCount) {
        break
      }
      // transaction from 更新
      txFrom = transactions[transactions.length - 1].timestamp
    }

    assetBalanceList = [...totalBalances.entries()].map(([asset, balance]) => ({
      asset,
      balances: [balance],
    }))
  } else {
    assetBalanceList = [...assetBalances.entries()].map(
      ([asset, balances]) => ({
        asset,
        balances,
      }),
    )
  }

  // 会計期間内の Transaction と一時保存 Transaction を一度全削除して、新しく生成した Transaction List を保存
  // 会計期間内の Action の Transaction 作成済フラグを全てオンにする
  // Loan , Cross Action Bundle, Asset Balanace , Asset Borrow データの保存
  // 会計期間内の action.generaetedTx = true に更新 & hasInsufficientAmountTx, hasUnknownPriceTx を更新
  await saveAccountingPeriodData({
    database,
    accountingPeriod,
    loans: [...loans.values()],
    crossActionBundles: [...crossActionBundles.values()],
    assetBalances: assetBalanceList,
    assetBorrows: [...assetBorrows.entries()].map(([asset, borrows]) => ({
      asset,
      borrows,
    })),
  })

  return true
}
