import { proxyApiEndpoint } from '@/environment'
import type { EvmCsvFormat } from '@/types'
import { createEvmChainList } from '@pkg/basic'

export const evmChains = createEvmChainList({
  proxyApiEndpoint,
  arbitrumOneApiKey: '',
  avalancheCChainApiKey: '',
  baseApiKey: '',
  bscApiKey: '',
  blastApiKey: '',
  ethereumApiKey: '',
  fantomApiKey: '',
  lineaApiKey: '',
  mantleApiKey: '',
  moonbeamApiKey: '',
  moonriverApiKey: '',
  oasysProxyUrl: proxyApiEndpoint,
  opBnbApiKey: '',
  optimismApiKey: '',
  polygonPosApiKey: '',
  polygonZkEvmApiKey: '',
  roninApiKey: '',
  scrollApiKey: '',
  zksyncApiKey: '',
  zkfairProxyUrl: proxyApiEndpoint,
})

export const cannotUseFetchAddressDataChainIdSet = new Set<number>([])

export const evmChainCsvFormatList: readonly EvmCsvFormat[] = [
  {
    chainId: 2020,
    csvId: 'ronin-explorer',
    name: 'CSV from The Ronin Block Explorer',
    hashColumn: 'Txhash',
    blockNumberColumn: 'Blockno',
    timestampColumn: 'UnixTimestamp',
    timestampType: 'unixtime',
  },
]
