import type { Nft } from '@0xtorch/core'
import type { SQL } from 'drizzle-orm'
import { and, gte, inArray, lte } from 'drizzle-orm'
import { parseToNft } from '../parsers/nft'
import { nftTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type SelectNftsParameters = {
  readonly database: DatabaseWithTransaction
  readonly idList?: readonly string[]
  readonly before?: number
  readonly after?: number
}

export const selectNfts = async ({
  database: { database },
  idList,
  before,
  after,
}: SelectNftsParameters): Promise<readonly Nft[]> => {
  const baseQuery = database.select().from(nftTable)
  const mut_whereConditions: SQL<unknown>[] = []
  if (idList !== undefined && idList.length > 0) {
    mut_whereConditions.push(inArray(nftTable.id, [...idList]))
  }
  if (before !== undefined) {
    mut_whereConditions.push(lte(nftTable.updatedAt, before))
  }
  if (after !== undefined) {
    mut_whereConditions.push(gte(nftTable.updatedAt, after))
  }
  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let mut_query
  if (mut_whereConditions.length === 0) {
    mut_query = baseQuery
  } else if (mut_whereConditions.length === 1) {
    mut_query = baseQuery.where(mut_whereConditions[0])
  } else {
    mut_query = baseQuery.where(and(...mut_whereConditions))
  }
  const query = mut_query

  const data = await query
  return data.map((item) => parseToNft(item))
}
