import type { Loan } from '@0xtorch/accounting'
import { parseToLoans } from '../parsers/loan'
import type { DatabaseWithTransaction } from '../types'

type SelectLoansParameters = {
  readonly database: DatabaseWithTransaction
  readonly accountingPeriodId: number
}

export const selectLoans = async ({
  database: { database },
  accountingPeriodId,
}: SelectLoansParameters): Promise<readonly Loan[]> => {
  const data = await database.query.loanTable.findMany({
    where: (loanTable, { eq }) =>
      eq(loanTable.accountingPeriodId, accountingPeriodId),
    with: {
      loanBorrows: true,
      loanBorrowWithDebts: true,
      loanBorrowWithDebtPositions: true,
      loanDeposits: true,
      loanDepositWithBonds: true,
      loanDepositWithBondPositions: true,
    },
  })

  return parseToLoans(data)
}
