import { UserButton } from '@clerk/clerk-react'
import { useRouterState } from '@tanstack/react-router'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { ModeToggle } from './modeToggle'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from './ui/breadcrumb'
import { SidebarTrigger } from './ui/sidebar'

const PortfolioHeader = () => {
  const { t } = useTranslation()
  const { location } = useRouterState()

  return (
    <div className="flex justify-between border-b px-4 py-2">
      <div className="-ml-2 flex items-center">
        <SidebarTrigger />
        <div className="mx-2 mr-4 h-6 border-r" />
        <Breadcrumb>
          <PortfolioBreadcrumbList pathname={location.pathname} t={t} />
        </Breadcrumb>
      </div>
      <div className="flex items-center space-x-4">
        <ModeToggle />
        <UserButton />
      </div>
    </div>
  )
}

const PortfolioBreadcrumbList = (props: {
  pathname: string
  t: TFunction
}) => {
  const { pathname, t } = props
  switch (pathname) {
    case '/': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>{t('Account')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/dashboard/export': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage className="text-muted-foreground">
              {t('Portfolio')}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('Data Export')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/dashboard': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage className="text-muted-foreground">
              {t('Portfolio')}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('Dashboard')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/history': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>{t('History')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/setting': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage className="text-muted-foreground">
              {t('Setting')}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('Basic Setting')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/setting/plan': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage className="text-muted-foreground">
              {t('Setting')}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('Plan & Payment')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/setting/share': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage className="text-muted-foreground">
              {t('Setting')}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('Manage Member')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    case '/setting/journal': {
      return (
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage className="text-muted-foreground">
              {t('Setting')}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('sidebar/menu/setting-journal')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      )
    }
    default: {
      return <></>
    }
  }
}

export default PortfolioHeader
