import { createSelectSchema } from 'drizzle-zod'
import {
  accountCsvTable,
  accountErc20TokenRelationTable,
  accountEvmTxIndexRelationTable,
  accountSolanaSignatureTable,
  accountTable,
  accountingTransactionTable,
  accountingTransactionTransferTable,
  actionAccountRelationTable,
  actionCsvRelationTable,
  actionSourceTable,
  actionTable,
  actionTransferTable,
  assetBalanceTable,
  assetBorrowTable,
  assetValueTable,
  crossActionBundleRelationTable,
  crossActionBundleTable,
  cryptoCurrencyTable,
  erc20TokenTable,
  evmInternalTransactionTable,
  evmTxIndexTable,
  loanBorrowTable,
  loanBorrowWithDebtPositionTable,
  loanBorrowWithDebtTable,
  loanDepositTable,
  loanDepositWithBondPositionTable,
  loanDepositWithBondTable,
  loanTable,
  nftTable,
  singleActionRuleTable,
  solanaSignatureTable,
  solanaTokenAccountTable,
} from './schema'

/** {@link https://www.sqlite.org/limits.html} */
export const sqliteMaxHostParameterCount = 32_766

/** {@link https://www.sqlite.org/limits.html} */
export const sqliteMaxCompoundSelectCount = 500

export const accountColumnCount = Object.keys(
  createSelectSchema(accountTable).shape,
).length

export const accountCsvColumnCount = Object.keys(
  createSelectSchema(accountCsvTable).shape,
).length

export const accountErc20TokenRelationColumnCount = Object.keys(
  createSelectSchema(accountErc20TokenRelationTable).shape,
).length

export const accountEvmTxIndexRelationColumnCount = Object.keys(
  createSelectSchema(accountEvmTxIndexRelationTable).shape,
).length

export const accountingTransactionColumnCount = Object.keys(
  createSelectSchema(accountingTransactionTable).shape,
).length

export const accountingTransactionTransferColumnCount = Object.keys(
  createSelectSchema(accountingTransactionTransferTable).shape,
).length

export const accountSolanaSignatureColumnCount = Object.keys(
  createSelectSchema(accountSolanaSignatureTable).shape,
).length

export const actionColumnCount = Object.keys(
  createSelectSchema(actionTable).shape,
).length

export const actionAccountRelationColumnCount = Object.keys(
  createSelectSchema(actionAccountRelationTable).shape,
).length

export const actionCsvRelationColumnCount = Object.keys(
  createSelectSchema(actionCsvRelationTable).shape,
).length

export const actionSourceColumnCount = Object.keys(
  createSelectSchema(actionSourceTable).shape,
).length

export const actionTransferColumnCount = Object.keys(
  createSelectSchema(actionTransferTable).shape,
).length

export const assetBalanceColumnCount = Object.keys(
  createSelectSchema(assetBalanceTable).shape,
).length

export const assetBorrowColumnCount = Object.keys(
  createSelectSchema(assetBorrowTable).shape,
).length

export const assetValueColumnCount = Object.keys(
  createSelectSchema(assetValueTable).shape,
).length

export const crossActionBundleColumnCount = Object.keys(
  createSelectSchema(crossActionBundleTable).shape,
).length

export const crossActionBundleRelationColumnCount = Object.keys(
  createSelectSchema(crossActionBundleRelationTable).shape,
).length

export const cryptoCurrencyColumnCount = Object.keys(
  createSelectSchema(cryptoCurrencyTable).shape,
).length

export const erc20TokenColumnCount = Object.keys(
  createSelectSchema(erc20TokenTable).shape,
).length

export const evmInternalTransactionColumnCount = Object.keys(
  createSelectSchema(evmInternalTransactionTable).shape,
).length

export const evmTxIndexColumnCount = Object.keys(
  createSelectSchema(evmTxIndexTable).shape,
).length

export const loanColumnCount = Object.keys(
  createSelectSchema(loanTable).shape,
).length

export const loanBorrowColumnCount = Object.keys(
  createSelectSchema(loanBorrowTable).shape,
).length

export const loanBorrowWithDebtColumnCount = Object.keys(
  createSelectSchema(loanBorrowWithDebtTable).shape,
).length

export const loanBorrowWithDebtPositionColumnCount = Object.keys(
  createSelectSchema(loanBorrowWithDebtPositionTable).shape,
).length

export const loanDepositColumnCount = Object.keys(
  createSelectSchema(loanDepositTable).shape,
).length

export const loanDepositWithBondColumnCount = Object.keys(
  createSelectSchema(loanDepositWithBondTable).shape,
).length

export const loanDepositWithBondPositionColumnCount = Object.keys(
  createSelectSchema(loanDepositWithBondPositionTable).shape,
).length

export const nftColumnCount = Object.keys(
  createSelectSchema(nftTable).shape,
).length

export const singleActionRuleColumnCount = Object.keys(
  createSelectSchema(singleActionRuleTable).shape,
).length

export const solanaSignatureColumnCount = Object.keys(
  createSelectSchema(solanaSignatureTable).shape,
).length

export const solanaTokenAccountColumnCount = Object.keys(
  createSelectSchema(solanaTokenAccountTable).shape,
).length
