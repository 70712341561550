import type {
  BorrowWithDebt,
  DepositWithBond,
  Loan,
  LoanPosition,
} from '@0xtorch/accounting'
import { createBigDecimal, toStringBigDecimal } from '@0xtorch/big-decimal'
import { sql } from 'drizzle-orm'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import {
  type loanBorrowTable,
  type loanBorrowWithDebtPositionTable,
  type loanBorrowWithDebtTable,
  type loanDepositTable,
  type loanDepositWithBondPositionTable,
  type loanDepositWithBondTable,
  loanTable,
} from '../schema'

type LoanData = typeof loanTable.$inferSelect & {
  loanBorrows: (typeof loanBorrowTable.$inferSelect)[]
  loanBorrowWithDebts: (typeof loanBorrowWithDebtTable.$inferSelect)[]
  loanBorrowWithDebtPositions: (typeof loanBorrowWithDebtPositionTable.$inferSelect)[]
  loanDeposits: (typeof loanDepositTable.$inferSelect)[]
  loanDepositWithBonds: (typeof loanDepositWithBondTable.$inferSelect)[]
  loanDepositWithBondPositions: (typeof loanDepositWithBondPositionTable.$inferSelect)[]
}

export const parseToLoans = (data: readonly LoanData[]): readonly Loan[] =>
  data.map((loanRow) => {
    const borrows = new Map<string, LoanPosition[]>()
    const borrowsWithDebt = new Map<string, BorrowWithDebt[]>()
    const deposits = new Map<string, LoanPosition[]>()
    const depositsWithBond = new Map<string, DepositWithBond[]>()

    for (const borrow of loanRow.loanBorrows) {
      const targetBorrows = borrows.get(borrow.key) ?? []
      const diff = borrow.order - (targetBorrows.length - 1)
      for (let i = 0; i < diff; i++) {
        targetBorrows.push({
          asset: borrow.key,
          amount: createBigDecimal('0'),
        })
      }
      targetBorrows[borrow.order].amount = createBigDecimal(borrow.amount)
      borrows.set(borrow.key, targetBorrows)
    }
    for (const borrowWithDebt of loanRow.loanBorrowWithDebts) {
      const targetBorrowsWithDebt =
        borrowsWithDebt.get(borrowWithDebt.key) ?? []
      const diff = borrowWithDebt.order - (targetBorrowsWithDebt.length - 1)
      for (let i = 0; i < diff; i++) {
        targetBorrowsWithDebt.push({
          debt: {
            asset: borrowWithDebt.key,
            amount: createBigDecimal('0'),
          },
          positions: [],
        })
      }
      targetBorrowsWithDebt[borrowWithDebt.order].debt.amount =
        createBigDecimal(borrowWithDebt.amount)
      borrowsWithDebt.set(borrowWithDebt.key, targetBorrowsWithDebt)
    }
    for (const borrowWithDebtPosition of loanRow.loanBorrowWithDebtPositions) {
      const targetBorrowsWithDebt = borrowsWithDebt.get(
        borrowWithDebtPosition.debtKey,
      )
      if (targetBorrowsWithDebt === undefined) {
        throw new Error('targetBorrowsWithDebt is undefined')
      }
      const targetBorrowWithDebt =
        targetBorrowsWithDebt[borrowWithDebtPosition.debtOrder]
      const targetPositions = targetBorrowWithDebt.positions
      const diff =
        borrowWithDebtPosition.positionOrder - (targetPositions.length - 1)
      const key = createAssetKey(
        borrowWithDebtPosition.cryptoId,
        borrowWithDebtPosition.nftId,
      )
      for (let i = 0; i < diff; i++) {
        targetPositions.push({
          asset: key,
          amount: createBigDecimal('0'),
        })
      }
      targetPositions[borrowWithDebtPosition.positionOrder].amount =
        createBigDecimal(borrowWithDebtPosition.amount)
      targetBorrowsWithDebt[borrowWithDebtPosition.debtOrder].positions =
        targetPositions
      borrowsWithDebt.set(borrowWithDebtPosition.debtKey, targetBorrowsWithDebt)
    }
    for (const deposit of loanRow.loanDeposits) {
      const targetDeposits = deposits.get(deposit.key) ?? []
      const diff = deposit.order - (targetDeposits.length - 1)
      for (let i = 0; i < diff; i++) {
        targetDeposits.push({
          asset: deposit.key,
          amount: createBigDecimal('0'),
        })
      }
      targetDeposits[deposit.order].amount = createBigDecimal(deposit.amount)
      deposits.set(deposit.key, targetDeposits)
    }
    for (const depositWithBond of loanRow.loanDepositWithBonds) {
      const targetDepositsWithBond =
        depositsWithBond.get(depositWithBond.key) ?? []
      const diff = depositWithBond.order - (targetDepositsWithBond.length - 1)
      for (let i = 0; i < diff; i++) {
        targetDepositsWithBond.push({
          bond: {
            asset: depositWithBond.key,
            amount: createBigDecimal('0'),
          },
          positions: [],
        })
      }
      targetDepositsWithBond[depositWithBond.order].bond.amount =
        createBigDecimal(depositWithBond.amount)
      depositsWithBond.set(depositWithBond.key, targetDepositsWithBond)
    }
    for (const depositWithBondPosition of loanRow.loanDepositWithBondPositions) {
      const targetDepositsWithBond = depositsWithBond.get(
        depositWithBondPosition.bondKey,
      )
      if (targetDepositsWithBond === undefined) {
        throw new Error('targetDepositsWithBond is undefined')
      }
      const targetDepositWithBond =
        targetDepositsWithBond[depositWithBondPosition.bondOrder]
      const targetPositions = targetDepositWithBond.positions
      const diff =
        depositWithBondPosition.positionOrder - (targetPositions.length - 1)
      const key = createAssetKey(
        depositWithBondPosition.cryptoId,
        depositWithBondPosition.nftId,
      )
      for (let i = 0; i < diff; i++) {
        targetPositions.push({
          asset: key,
          amount: createBigDecimal('0'),
        })
      }
      targetPositions[depositWithBondPosition.positionOrder].amount =
        createBigDecimal(depositWithBondPosition.amount)
      targetDepositsWithBond[depositWithBondPosition.bondOrder].positions =
        targetPositions
      depositsWithBond.set(
        depositWithBondPosition.bondKey,
        targetDepositsWithBond,
      )
    }

    return {
      id: loanRow.loanId,
      borrows,
      borrowsWithDebt,
      deposits,
      depositsWithBond,
    }
  })

const createAssetKey = (cryptoId: string | null, nftId: string | null) => {
  if (cryptoId !== null) {
    return `CryptoCurrency/${cryptoId}`
  }
  if (nftId !== null) {
    return `Nft/${nftId}`
  }
  throw new Error('cryptoId and nftId are null')
}

export const parseLoansToInsertData = (
  periodId: number,
  loans: readonly Loan[],
): {
  readonly loan: readonly SQLiteInsertValue<typeof loanTable>[]
  readonly loanBorrow: readonly SQLiteInsertValue<typeof loanBorrowTable>[]
  readonly loanBorrowWithDebt: readonly SQLiteInsertValue<
    typeof loanBorrowWithDebtTable
  >[]
  readonly loanBorrowWithDebtPosition: readonly SQLiteInsertValue<
    typeof loanBorrowWithDebtPositionTable
  >[]
  readonly loanDeposit: readonly SQLiteInsertValue<typeof loanDepositTable>[]
  readonly loanDepositWithBond: readonly SQLiteInsertValue<
    typeof loanDepositWithBondTable
  >[]
  readonly loanDepositWithBondPosition: readonly SQLiteInsertValue<
    typeof loanDepositWithBondPositionTable
  >[]
} => {
  const loanSqls: SQLiteInsertValue<typeof loanTable>[] = []
  const loanBorrowSqls: SQLiteInsertValue<typeof loanBorrowTable>[] = []
  const loanBorrowWithDebtSqls: SQLiteInsertValue<
    typeof loanBorrowWithDebtTable
  >[] = []
  const loanBorrowWithDebtPositionSqls: SQLiteInsertValue<
    typeof loanBorrowWithDebtPositionTable
  >[] = []
  const loanDepositSqls: SQLiteInsertValue<typeof loanDepositTable>[] = []
  const loanDepositWithBondSqls: SQLiteInsertValue<
    typeof loanDepositWithBondTable
  >[] = []
  const loanDepositWithBondPositionSqls: SQLiteInsertValue<
    typeof loanDepositWithBondPositionTable
  >[] = []

  for (const loan of loans) {
    // loan 追加
    loanSqls.push({
      accountingPeriodId: periodId,
      loanId: loan.id,
    })

    const loanIdSql = sql`(select ${loanTable.id} from ${loanTable} where (${loanTable.accountingPeriodId} = ${periodId} and ${loanTable.loanId} = ${loan.id}))`

    for (const [key, borrows] of loan.borrows.entries()) {
      for (const [order, borrow] of borrows.entries()) {
        // loan borrow 追加
        const [assetType, assetId] = borrow.asset.split('/')
        loanBorrowSqls.push({
          loanId: loanIdSql,
          key,
          order,
          cryptoId: assetType === 'CryptoCurrency' ? assetId : undefined,
          nftId: assetType === 'Nft' ? assetId : undefined,
          amount: toStringBigDecimal(borrow.amount),
        })
      }
    }

    for (const [key, borrowsWithDebt] of loan.borrowsWithDebt.entries()) {
      for (const [order, borrowWithDebt] of borrowsWithDebt.entries()) {
        // loan borrow with debt 追加
        const [debtAssetType, debtAssetId] =
          borrowWithDebt.debt.asset.split('/')
        loanBorrowWithDebtSqls.push({
          loanId: loanIdSql,
          key,
          order,
          cryptoId:
            debtAssetType === 'CryptoCurrency' ? debtAssetId : undefined,
          nftId: debtAssetType === 'Nft' ? debtAssetId : undefined,
          amount: toStringBigDecimal(borrowWithDebt.debt.amount),
        })

        for (const [
          positionOrder,
          position,
        ] of borrowWithDebt.positions.entries()) {
          // loan borrow with debt position 追加
          const [assetType, assetId] = position.asset.split('/')
          loanBorrowWithDebtPositionSqls.push({
            loanId: loanIdSql,
            debtKey: key,
            debtOrder: order,
            positionOrder,
            cryptoId: assetType === 'CryptoCurrency' ? assetId : undefined,
            nftId: assetType === 'Nft' ? assetId : undefined,
            amount: toStringBigDecimal(position.amount),
          })
        }
      }
    }

    for (const [key, deposits] of loan.deposits.entries()) {
      for (const [order, deposit] of deposits.entries()) {
        // loan deposit 追加
        const [assetType, assetId] = deposit.asset.split('/')
        loanDepositSqls.push({
          loanId: loanIdSql,
          key,
          order,
          cryptoId: assetType === 'CryptoCurrency' ? assetId : undefined,
          nftId: assetType === 'Nft' ? assetId : undefined,
          amount: toStringBigDecimal(deposit.amount),
        })
      }
    }

    for (const [key, depositsWithBond] of loan.depositsWithBond.entries()) {
      for (const [order, depositWithBond] of depositsWithBond.entries()) {
        // loan deposit with bond 追加
        const [bondAssetType, bondAssetId] =
          depositWithBond.bond.asset.split('/')
        loanDepositWithBondSqls.push({
          loanId: loanIdSql,
          key,
          order,
          cryptoId:
            bondAssetType === 'CryptoCurrency' ? bondAssetId : undefined,
          nftId: bondAssetType === 'Nft' ? bondAssetId : undefined,
          amount: toStringBigDecimal(depositWithBond.bond.amount),
        })

        for (const [
          positionOrder,
          position,
        ] of depositWithBond.positions.entries()) {
          // loan deposit with bond position 追加
          const [assetType, assetId] = position.asset.split('/')
          loanDepositWithBondPositionSqls.push({
            loanId: loanIdSql,
            bondKey: key,
            bondOrder: order,
            positionOrder,
            cryptoId: assetType === 'CryptoCurrency' ? assetId : undefined,
            nftId: assetType === 'Nft' ? assetId : undefined,
            amount: toStringBigDecimal(position.amount),
          })
        }
      }
    }
  }

  return {
    loan: loanSqls,
    loanBorrow: loanBorrowSqls,
    loanBorrowWithDebt: loanBorrowWithDebtSqls,
    loanBorrowWithDebtPosition: loanBorrowWithDebtPositionSqls,
    loanDeposit: loanDepositSqls,
    loanDepositWithBond: loanDepositWithBondSqls,
    loanDepositWithBondPosition: loanDepositWithBondPositionSqls,
  }
}
