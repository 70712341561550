import { divideArrayIntoChunks } from '@pkg/basic'
import { and, eq, inArray, sql } from 'drizzle-orm'
import {
  accountColumnCount,
  accountSolanaSignatureColumnCount,
  solanaSignatureColumnCount,
  solanaTokenAccountColumnCount,
  sqliteMaxHostParameterCount,
} from '../../constants'
import {
  accountSolanaSignatureTable,
  accountTable,
  solanaSignatureTable,
  solanaTokenAccountTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'
import { getMaxInsertRowCount } from '../../utils'

type SaveSolanaAccountIndexesParameters = {
  database: DatabaseWithTransaction
  accountId: number
  signatures: readonly { signature: string; timestamp: number }[]
  tokenAccountAddresses: readonly string[]
}

/** solana account index (signature & token account) data を保存
 * - solana signature を重複しない場合のみ保存
 * - account solana signature を重複しない場合のみ analyzed = false で保存
 * - 既存の solana token account の内、新しい tokenAccountAddresses に存在しないものを削除
 * - tokenAccountAddresses を重複しない場合のみ保存
 * - account の syncing , lastSyncedAt を更新
 */
export const saveSolanaAccountIndexes = async ({
  database: { database, transaction },
  accountId,
  signatures,
  tokenAccountAddresses,
}: SaveSolanaAccountIndexesParameters) => {
  if (signatures.length === 0 && tokenAccountAddresses.length === 0) {
    return
  }

  const tokenAccountAddressSet = new Set(tokenAccountAddresses)

  const existTokenAccountRows = await database
    .select({ address: solanaTokenAccountTable.address })
    .from(solanaTokenAccountTable)
    .where(eq(solanaTokenAccountTable.accountId, accountId))
  const deleteTokenAccountAddresses: string[] = []
  for (const { address } of existTokenAccountRows) {
    if (!tokenAccountAddressSet.has(address)) {
      deleteTokenAccountAddresses.push(address)
    }
  }

  await transaction([
    (tx) => [
      // solana signature を重複しない場合のみ保存
      ...divideArrayIntoChunks(
        signatures,
        getMaxInsertRowCount(solanaSignatureColumnCount),
      ).map((chunk) =>
        tx
          .insert(solanaSignatureTable)
          .values(
            chunk.map((item) => ({
              signature: item.signature,
              timestamp: new Date(item.timestamp),
            })),
          )
          .onConflictDoNothing(),
      ),
      // account solana signature を重複しない場合のみ analyzed = false で保存
      ...divideArrayIntoChunks(
        signatures,
        getMaxInsertRowCount(accountSolanaSignatureColumnCount),
      ).map((chunk) =>
        tx
          .insert(accountSolanaSignatureTable)
          .values(
            chunk.map((item) => ({
              accountId,
              signature: item.signature,
              analyzed: false,
            })),
          )
          .onConflictDoNothing(),
      ),
      // tokenAccountAddresses を重複しない場合のみ保存
      ...divideArrayIntoChunks(
        tokenAccountAddresses,
        getMaxInsertRowCount(solanaTokenAccountColumnCount),
      ).map((chunk) =>
        tx
          .insert(solanaTokenAccountTable)
          .values(
            chunk.map((address) => ({
              accountId,
              address,
            })),
          )
          .onConflictDoNothing(),
      ),
      // 既存の solana token account の内、新しい tokenAccountAddresses に存在しないものを削除
      ...divideArrayIntoChunks(
        deleteTokenAccountAddresses,
        sqliteMaxHostParameterCount - 1,
      ).map((chunk) =>
        tx
          .delete(solanaTokenAccountTable)
          .where(
            and(
              eq(solanaTokenAccountTable.accountId, accountId),
              inArray(solanaTokenAccountTable.address, [...chunk]),
            ),
          ),
      ),
    ],
    (tx) => [
      // account の syncing , lastSyncedAt を更新
      tx
        .update(accountTable)
        .set({
          syncing: sql`(EXISTS (SELECT 1 FROM ${accountSolanaSignatureTable} WHERE ${accountSolanaSignatureTable.accountId} = ${accountId} AND ${accountSolanaSignatureTable.analyzed} = ${false}))`,
          lastSyncedAt: new Date(),
        })
        .where(eq(accountTable.id, accountId)),
    ],
  ])
}
