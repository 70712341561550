import { eq } from 'drizzle-orm'
import { parseToAssetBalances } from '../parsers/assetBalance'
import { assetBalanceTable } from '../schema'
import type { AssetBalances, DatabaseWithTransaction } from '../types'

type SelectAssetBalancesListParameters = {
  readonly database: DatabaseWithTransaction
  readonly accountingPeriodId: number
}

export const selectAssetBalances = async ({
  database: { database },
  accountingPeriodId,
}: SelectAssetBalancesListParameters): Promise<readonly AssetBalances[]> => {
  const data = await database
    .select()
    .from(assetBalanceTable)
    .where(eq(assetBalanceTable.accountingPeriodId, accountingPeriodId))

  return parseToAssetBalances(data)
}
