import { LoaderCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const LoadingOverlay = (props: { description: string }) => {
  const { description } = props
  const { i18n } = useTranslation()
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-secondary">
      <div className="flex items-center border-background border-b px-8 pb-4">
        <img
          src={i18n.language === 'ja' ? '/logo_ja.svg' : 'logo.svg'}
          alt="Cryptorch"
          className="h-12"
        />
      </div>
      <div className="mt-4 flex items-center space-x-2 text-sm">
        <LoaderCircle className="h-5 w-5 animate-spin" />
        <p>{description}</p>
      </div>
    </div>
  )
}

export default LoadingOverlay
