import type { Transaction } from '@0xtorch/accounting'
import type { FiatCurrency } from '@0xtorch/core'
import { type SQL, and, asc, between, gte, lte } from 'drizzle-orm'
import { parseToTransaction } from '../parsers/transaction'
import { accountingTransactionTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type SelectTransactionsParameters = {
  database: DatabaseWithTransaction
  from?: number
  to?: number
  limit?: number
  fiats: readonly FiatCurrency[]
}

export const selectTransactions = async ({
  database: { database },
  from,
  to,
  limit,
  fiats,
}: SelectTransactionsParameters): Promise<Transaction[]> => {
  const whereConditions: SQL<unknown>[] = []
  if (from !== undefined && to !== undefined) {
    whereConditions.push(
      between(
        accountingTransactionTable.timestamp,
        new Date(from),
        new Date(to),
      ),
    )
  } else if (from !== undefined) {
    whereConditions.push(
      gte(accountingTransactionTable.timestamp, new Date(from)),
    )
  } else if (to !== undefined && to > 0) {
    whereConditions.push(
      lte(accountingTransactionTable.timestamp, new Date(to)),
    )
  }
  let where: SQL<unknown> | undefined
  if (whereConditions.length === 0) {
    where = undefined
  } else if (whereConditions.length === 1) {
    where = whereConditions[0]
  } else {
    where = and(...whereConditions)
  }
  const result = await database.query.accountingTransactionTable.findMany({
    where,
    with: {
      transferList: {
        with: {
          cryptoCurrency: true,
          nft: true,
        },
      },
      nft: true,
    },
    limit,
    orderBy: [asc(accountingTransactionTable.timestamp)],
  })

  return result.map((data) => ({
    ...parseToTransaction(data, fiats),
  }))
}
