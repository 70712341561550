import { apiEndpoint } from '@/environment'
import {
  getPortfolioUpdatedAtFromLocalStorage,
  setPortfolioToLocalStorage,
} from '@/localStorage'
import type { Portfolio, RunToDatabase } from '@/types'
import { compress } from '@/utils'
import { sql } from 'drizzle-orm'
import { SQLocal } from 'sqlocal'
import { createPortfolioDbFileName } from '../utils'

export const syncDbFileToRemote = async (params: {
  portfolio: Portfolio
  token: string
  writeToDatabase?: RunToDatabase
}) => {
  const { portfolio, token, writeToDatabase } = params

  const localUpdatedAt = getPortfolioUpdatedAtFromLocalStorage() ?? 0
  if (localUpdatedAt <= portfolio.updatedAt) {
    return
  }

  if (writeToDatabase !== undefined) {
    // vacuum 処理
    await writeToDatabase((database) =>
      (async () => {
        await database.database.run(sql`VACUUM`)
      })(),
    )
  }

  const afterVacuumLocalUpdatedAt = getPortfolioUpdatedAtFromLocalStorage() ?? 0

  const { getDatabaseFile, destroy } = new SQLocal(
    createPortfolioDbFileName(portfolio.uuid),
  )
  try {
    const file = await getDatabaseFile()
    // 圧縮 (gzip)
    const gzipFile = await compress(file)
    const body = new FormData()
    body.append('file', gzipFile)
    const url = new URL(
      `/v1/portfolio/${portfolio.uuid}/db?timestamp=${afterVacuumLocalUpdatedAt}`,
      apiEndpoint,
    )
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
    if (!response.ok) {
      throw new Error(
        `Failed to upload portfolio DB file: ${response.status} ${response.statusText}`,
      )
    }
    setPortfolioToLocalStorage({
      ...portfolio,
      updatedAt: afterVacuumLocalUpdatedAt,
    })
  } finally {
    await destroy()
  }
}
