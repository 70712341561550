import { portfolioTable } from '../schema'
import type { DatabaseWithTransaction, Portfolio } from '../types'

type SelectPortfolioParameters = {
  readonly database: DatabaseWithTransaction
}

export const selectPortfolio = async ({
  database: { database },
}: SelectPortfolioParameters): Promise<Portfolio | undefined> => {
  const portfolios = await database.select().from(portfolioTable).limit(1)
  if (portfolios.length !== 1) {
    return undefined
  }
  const portfolio = portfolios[0]
  return {
    ...portfolio,
    handleLoanAsTrade: portfolio.handleLoanAsTrade ?? false,
    handleReplaceAsTrade: portfolio.handleReplaceAsTrade ?? false,
    use5PercentRule: portfolio.use5PercentRule ?? false,
  }
}
