import { syncDbFileToRemote } from '@/features/portfolio/logic/syncDbFileToRemote'
import { updateLastUsedAt } from '@/features/portfolio/logic/updateLastUsedAt'
import {
  getPortfolioFromLocalStorage,
  getPortfolioIsWritableFromLocalStorage,
} from '@/localStorage'
import { useAuth } from '@clerk/clerk-react'
import { portfolioSyncInterval } from '@pkg/basic'
import { type ReactNode, useEffect } from 'react'

const SyncProvider = (props: {
  children: ReactNode
}) => {
  const { children } = props
  const { getToken } = useAuth()

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // portfolio が writable で無ければ処理なし
      if (!getPortfolioIsWritableFromLocalStorage()) {
        return
      }
      const portfolio = getPortfolioFromLocalStorage()
      if (portfolio === undefined) {
        return
      }
      // portfolio plan が free, mini であれば処理なし
      if (portfolio.plan === 'free' || portfolio.plan === 'mini') {
        return
      }
      const token = await getToken()
      if (token === null) {
        return
      }

      await Promise.all([
        // lastUsed 更新
        updateLastUsedAt({ portfolio, token }),
        // updated at > saved at の場合 portfolio 更新
        syncDbFileToRemote({ portfolio, token }),
      ])
    }, portfolioSyncInterval)

    return () => {
      clearInterval(intervalId)
    }
  }, [getToken])

  return children
}

export default SyncProvider
