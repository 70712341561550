import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_layoutPortfolio/')({
  loader: () => {
    redirect({
      to: '/dashboard',
      search: (prev) => ({
        ...prev,
      }),
      throw: true,
    })
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <Outlet />
}
